import React, { useContext, useRef, useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';
import { toast } from 'react-toastify';
import { getError } from '../LoadingError/Utils';
import { Store } from '../Store';
import axios from 'axios';

const UserVerification = () => {
  const loc = useLocation();
  const { requestId } = loc.state;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  }, [webcamRef]);

  console.log('LOC', loc.state);

  const submitImage = async () => {
    console.log('requestID', requestId);
    console.log('GAMBAR', capturedImage);
    const blob = await fetch(capturedImage).then((res) => res.blob());

    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

    // Create a FormData object and append the file

    const formData = new FormData();
    formData.append('file', file);
    formData.append('request_id', requestId);

    console.log('FORM DATA', formData);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/uploadImageVerification`, formData, {
        headers: {
          Authorization: `Bearer ${userInfo.data.token}`,
        },
      });
      toast.success('Verification ID picture added successfully!');
    } catch (err) {
      toast.error(getError(err));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <p>Take a selfie picture while holding your ID CARD:</p>
      <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className="mb-4" />
      <button onClick={capture} className="bg-blue-500 text-white px-4 py-2 rounded">
        Capture Photo
      </button>
      {capturedImage && (
        <div className="flex flex-col mt-4">
          <p>Captured Image:</p>
          <img src={capturedImage} alt="Captured" className="rounded" />
          <button onClick={submitImage} className="bg-blue-500 text-white px-4 py-2 rounded">
            Submit Image
          </button>
        </div>
      )}
      <Link to="/" className="mt-4 text-blue-500 underline">
        Back to Home
      </Link>
    </div>
  );
};

export default UserVerification;
