/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Store } from "../Store";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Maps from "../Components/Maps";
import Box from "@mui/material/Box";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import LoadingBox from "../LoadingError/LoadingBox";
import { format, setMinutes, toDate } from "date-fns";
import setHours from "date-fns/setHours";
import { alpha, styled } from "@mui/material/styles";

const customStylesSelect = {
  option: (provided) => ({
    ...provided,
    ":active": {
      ...provided[":active"],
      backgroundColor: "#FFE4CC",
    },
  }),
};

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#F95C3D",
    "&:hover": {
      backgroundColor: alpha("#F95C3D", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#F95C3D",
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_REQUEST":
      return { ...state, loadingAdd: true };
    case "ADD_SUCCESS":
      return { ...state, loadingAdd: false };
    case "ADD_FAIL":
      return { ...state, loadingAdd: false };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCategory: true };
    case "FETCHED":
      return {
        ...state,
        optionsCategory: action.payload,
        loadingCategory: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCategory: false,
        errorCategory: action.payload,
      };
    default:
      return state;
  }
};

const reducer4 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingProDetails: true };
    case "FETCHED":
      return { ...state, proDetails: action.payload, loadingProDetails: false };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingProDetails: false,
        errorProDetails: action.payload,
      };
    default:
      return state;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: "90%",
  bgcolor: "background.paper",
  border: "0.1px solid gainsboro",
  borderRadius: "1rem",
  boxShadow: "1px 2px 1px gainsboro",
  p: 4,
};

function EditProfessionalAccountScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [category, setCategory] = useState("");

  const [openingHours, setOpeningHours] = useState({
    Monday: [{ open: null, close: null, isClosed: false }],
    Tuesday: [{ open: null, close: null, isClosed: false }],
    Wednesday: [{ open: null, close: null, isClosed: false }],
    Thursday: [{ open: null, close: null, isClosed: false }],
    Friday: [{ open: null, close: null, isClosed: false }],
    Saturday: [{ open: null, close: null, isClosed: false }],
    Sunday: [{ open: null, close: null, isClosed: false }],
  });

  const [defaultOpeningHours, setDefaultOpeningHours] = useState({
    Monday: [{ open: null, close: null, isClosed: false }],
    Tuesday: [{ open: null, close: null, isClosed: false }],
    Wednesday: [{ open: null, close: null, isClosed: false }],
    Thursday: [{ open: null, close: null, isClosed: false }],
    Friday: [{ open: null, close: null, isClosed: false }],
    Saturday: [{ open: null, close: null, isClosed: false }],
    Sunday: [{ open: null, close: null, isClosed: false }],
  });

  const navigate = useNavigate();

  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);

  const [isLoading] = useState(false);

  //category
  const [isDisabledCategory, setIsDisabledCategory] = useState(true);

  // coordinate and location
  const [location, setLocation] = useState("");
  const [coordinateLocation, setCoordinateLocation] = useState(null);
  const [isShowErrorCoordinateLocation, setIsShowErrorCoordinateLocation] =
    useState(false);
  const [address, setAddress] = useState("");

  const [showMapsModal, setShowMapsModal] = useState(false);
  const targetRef = useRef(null);

  const [{ loadingAdd }, dispatch] = useReducer(reducer, {
    loadingAdd: false,
  });

  const [{ dataUser }, dispatch2] = useReducer(reducer2, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const [{ optionsCategory }, dispatch3] = useReducer(reducer3, {
    optionsCategory: [],
    loadingCategory: true,
    errorCategory: "",
  });

  const [{ proDetails, loadingProDetails }, dispatch4] = useReducer(reducer4, {
    proDetails: [],
    loadingProDetails: true,
    errorProDetails: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo === null) {
        navigate("/");
        return;
      }

      dispatch2({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch2({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch2({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };

    fetchData();
  }, [navigate, userInfo, dispatch2]);

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo === null) {
        navigate("/");
        return;
      }

      dispatch3({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getAllProList`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch3({
          type: "FETCHED",
          payload: data.data,
        });

        setIsDisabledCategory(false);
      } catch (error) {
        dispatch3({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };

    fetchData();
  }, [dataUser]);

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo === null) {
        navigate("/");
        return;
      }

      dispatch4({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getProDetails`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch4({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch4({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };

    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (proDetails.length == 0 || optionsCategory.length == 0) {
      return;
    }
    const checkCategory = optionsCategory.find(
      (category) => (category.value = proDetails[0].category_id)
    );
    setCategory(checkCategory);
    setAddress(proDetails[0].address);
    setLocation(proDetails[0].name);
    setCoordinateLocation({ lng: proDetails[0].long, lat: proDetails[0].lat });

    const formattedDefaultOpeningHours = {};
    Object.keys(proDetails[0].openhours).forEach((day) => {
      formattedDefaultOpeningHours[day] = proDetails[0].openhours[day].map(
        (timeRange) => {
          let open = null;
          let close = null;

          if (timeRange.open_time) {
            open = format(
              new Date(`2000-01-01T${timeRange.open_time}`),
              "HH:mm"
            );
          }

          if (timeRange.close_time) {
            close = format(
              new Date(`2000-01-01T${timeRange.close_time}`),
              "HH:mm"
            );
          }

          return {
            open,
            close,
            isClosed: timeRange.is_closed,
          };
        }
      );
    });

    // Set the default opening hours
    setDefaultOpeningHours(formattedDefaultOpeningHours);
  }, [proDetails, optionsCategory]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const isEmpty = Object.keys(openingHours).some((day) =>
      openingHours[day].some(
        (timeRange) =>
          !timeRange.isClosed && (!timeRange.open || !timeRange.close)
      )
    );

    if (isEmpty) {
      toast.error("Please fill in all required fields");
      return;
    }

    if (coordinateLocation === null) {
      setIsShowErrorCoordinateLocation(true);
      const element = targetRef.current;
      const topOffset = element.offsetTop;
      const elementHeight = element.offsetHeight;
      const windowHeight = window.innerHeight;

      const scrollTo = topOffset - windowHeight / 2 + elementHeight / 2;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
      return;
    } else {
      setIsShowErrorCoordinateLocation(false);
    }

    let categoryValue = category.value;

    try {
      dispatch({
        type: "ADD_REQUEST",
      });
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/editProAccount`,
        {
          category: categoryValue,
          location: location,
          address: address,
          long: coordinateLocation.lng,
          lat: coordinateLocation.lat,
          openingHours,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      dispatch({
        type: "ADD_SUCCESS",
      });
      toast.success("Edit Success!");
      navigate("/");
    } catch (err) {
      dispatch({
        type: "ADD_FAIL",
      });
      toast.error(getError(err));
    }
  };

  function SetUsername() {
    if (dataUser.username === null) {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {dataUser.name}
        </p>
      );
    } else {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {dataUser.username}
        </p>
      );
    }
  }

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="mb-3 mt-3"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }

  function ProfilePicture() {
    return (
      <img className="mb-3 mt-3" src={dataUser.picture} alt="profilepicture" />
    );
  }

  function handleOpenMapsModal() {
    setShowMapsModal(true);
  }

  function handleCloseMapsModal() {
    setShowMapsModal(false);
  }

  const handlePositionFromMaps = (data, province) => {
    setLocation(province);
    setCoordinateLocation(data);
  };

  const closeMapsModal = () => {
    setShowMapsModal(false);
  };

  const toggleDayClosed = (day) => {
    setOpeningHours((prevOpeningHours) => ({
      ...prevOpeningHours,
      [day]: [
        {
          open: null,
          close: null,
          isClosed: !prevOpeningHours[day][0].isClosed,
        },
      ],
    }));
  };

  const handleTimeChange = (day, index, field) => (newValue) => {
    let updatedTime = null;
    try {
      updatedTime = newValue ? newValue.format("HH:mm") : null;
    } catch (error) {
      console.error("Error formatting time:", error);
      // updatedTime = newValue ? console.log("HIII",newValue) : null;
    }
    setOpeningHours((prevOpeningHours) => ({
      ...prevOpeningHours,
      [day]: prevOpeningHours[day].map((timeRange, i) =>
        i === index ? { ...timeRange, [field]: updatedTime } : timeRange
      ),
    }));
  };

  const handleAddTimeRange = (day) => {
    setOpeningHours((prevOpeningHours) => ({
      ...prevOpeningHours,
      [day]: [
        ...prevOpeningHours[day],
        { open: null, close: null, isClosed: false },
      ],
    }));
  };

  const handleRemoveTimeRange = (day, index) => {
    if (index == 0) return;
    setOpeningHours((prevOpeningHours) => ({
      ...prevOpeningHours,
      [day]: prevOpeningHours[day].filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <div className="w-full flex items-center justify-center">
        <Helmet>
          <title>Professional Account</title>
        </Helmet>

        <div className="cardFlex xs:w-screen-90 ">
          <div className="flex justify-center ">
            <h1 className="text-center" style={{ fontSize: "2em" }}>
              Edit Professional Account
            </h1>
          </div>
          {!loadingProDetails ? (
            <form className="w-full rounded-lg" onSubmit={submitHandler}>
              <div className="profileContainer flex">
                <div
                  className="flex mr-8"
                  style={{
                    width: "1.5em",
                    marginRight: "2%",
                  }}
                >
                  <SetProfilePicture />
                </div>
                <div className="flex items-center">
                  <SetUsername />
                </div>
              </div>

              {/* Category */}

              <div className="flex" style={{ marginTop: "5%" }}>
                <label style={{ width: "40%" }}>Business Category</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={isDisabledCategory}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFE4CC",
                      primary: "#F95C3D",
                    },
                  })}
                  styles={customStylesSelect}
                  name="category"
                  options={optionsCategory}
                  value={category}
                  onChange={setCategory}
                  required
                />
              </div>

              {/* Coordinate */}
              <div
                className="flex"
                style={{ marginTop: "5%", justifyContent: "space-between" }}
                ref={targetRef}
              >
                <label style={{ width: "40%" }}> Coordinate Location</label>
                <div onClick={handleOpenMapsModal}>
                  <div
                    className="
                      border
                      rounded
                      px-3
                      text-gray-700
                      focus:outline-none
                    "
                    style={{
                      paddingBottom: "0.3em",
                      paddingTop: "0.3em",
                      cursor: "pointer",
                    }}
                  >
                    {coordinateLocation === null ? (
                      <LocationOnOutlinedIcon />
                    ) : (
                      <LocationOnOutlinedIcon style={{ color: "#00FF00" }} />
                    )}
                  </div>
                </div>

                <Modal
                  open={showMapsModal}
                  onClose={handleCloseMapsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Maps
                      sendData={handlePositionFromMaps}
                      closeModal={closeMapsModal}
                      position={{
                        lat: proDetails[0] ? proDetails[0].lat : null,
                        lng: proDetails[0] ? proDetails[0].long : null,
                      }}
                    />
                  </Box>
                </Modal>
              </div>

              {isShowErrorCoordinateLocation && coordinateLocation === null ? (
                <div
                  className="
                      text-gray-700
                      focus:outline-none
                    "
                  style={{ fontSize: "0.8rem", color: "red" }}
                >
                  *This field is required!
                </div>
              ) : (
                ""
              )}

              {/* Province Location */}
              <div
                className="flex flex-row justify-between"
                style={{ marginTop: "5%" }}
              >
                <label style={{ width: "40%" }}>Business Location</label>
                {location ? (
                  <div className="flex-end">{location}</div>
                ) : (
                  <div className="flex-end">Pick a coordinate</div>
                )}
              </div>

              <div className="flex" style={{ marginTop: "5%" }}>
                <label style={{ width: "40%" }}>Address Details</label>
                <textarea
                  className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                  style={{ fontSize: "0.8rem" }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>

              {/* Open Hour */}
              <div className="mt-10 font-semibold">
                {" "}
                Set New Operational Hours{" "}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-5">
                {Object.entries(defaultOpeningHours).map(([day, hours]) => (
                  <div key={day} className="flex flex-col">
                    <div>{day}</div>
                    {hours.map((hour, index) => (
                      <div key={index} className="flex justify-between text-sm">
                        <span>
                          {hour.open} - {hour.close}
                        </span>
                        <span>{hour.isClosed ? "Open" : "Close"}</span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div
                className="flex justify-between items-center"
                style={{ marginTop: "5%" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div>
                    {openingHours &&
                      Object.keys(openingHours).map((day) => (
                        <div key={day}>
                          <p>{day}</p>
                          <FormControlLabel
                            control={
                              <OrangeSwitch
                                checked={openingHours[day][0].isClosed}
                                onChange={() => toggleDayClosed(day)}
                              />
                            }
                            label="Closed"
                          />
                          {!openingHours[day][0].isClosed && (
                            <div>
                              {openingHours[day].map((timeRange, index) => (
                                <div key={index}>
                                  <TimePicker
                                    label="Open Hour"
                                    value={timeRange.open}
                                    onChange={handleTimeChange(
                                      day,
                                      index,
                                      "open"
                                    )}
                                    renderInput={(params) => (
                                      <input
                                        {...params}
                                        required={!timeRange.isClosed}
                                      />
                                    )}
                                  />
                                  <TimePicker
                                    label="Close Hour"
                                    value={timeRange.close}
                                    onChange={handleTimeChange(
                                      day,
                                      index,
                                      "close"
                                    )}
                                    renderInput={(params) => (
                                      <input
                                        {...params}
                                        required={!timeRange.isClosed}
                                      />
                                    )}
                                  />
                                  {index !== 0 ? (
                                    <Button
                                      onClick={() =>
                                        handleRemoveTimeRange(day, index)
                                      }
                                    >
                                      Remove
                                    </Button>
                                  ) : (
                                    " "
                                  )}
                                </div>
                              ))}
                              <Button onClick={() => handleAddTimeRange(day)}>
                                Add Time Range
                              </Button>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </LocalizationProvider>
              </div>
              {/* Submit */}
              <div className="flex justify-center" style={{ marginTop: "5%" }}>
                <button
                  type="submit"
                  className="
                    w-full
                    py-2 px-4
                    rounded-lg
                    focus:outline-none
                  "
                  style={{
                    background: "#F95C3D",
                    color: "white",
                  }}
                >
                  Update
                </button>
              </div>
            </form>
          ) : (
            <div className="flex justify-center mt-5">
              <LoadingBox></LoadingBox>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EditProfessionalAccountScreen;
