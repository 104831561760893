/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useReducer, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Store } from "../Store";
import Select from "react-select";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import LoadingBox from "../LoadingError/LoadingBox";
import MessageBox from "../LoadingError/MessageBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingListSm: true };
    case "FETCHED":
      return { ...state, listContactSm: action.payload, loadingListSm: false };
    case "FETCH_FAILED":
      return { ...state, loadingListSm: false, errorListSm: action.payload };
    default:
      return state;
  }
};

const CreateProfileScreen = () => {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [username, setUsername] = useState("");
  const [prevContact, setPrevContact] = useState("");
  const [contact, setContact] = useState("");
  const [fileSizeWarning, setFileSizeWarning] = useState("");

  const [isCheckedUsername, setIsCheckedUsername] = useState(false);
  const [isShowErrorUsername, setIsShowErrorUsername] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);

  const [smSelected, setSmSelected] = useState([]);
  const [, setNewProfilePicture] = useState(null);

  const [{}, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
  });

  const [{ loading, error, dataUser }, dispatch2] = useReducer(reducer2, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const [profilePicture, setProfilePicture] = useState(dataUser.picture);

  useEffect(() => {
    setProfilePicture(dataUser.picture);
  }, [dataUser.picture]);

  const [{ listContactSm }, dispatch3] = useReducer(reducer3, {
    listContactSm: [],
    loadingListSm: true,
    errorListSm: "",
  });

  const socialMediaOptions = listContactSm.map((contact) => ({
    value: contact.id,
    label: contact.name,
    url: contact.url,
    // Include image if available, or set a default image URL
    // Replace 'defaultImageURL' with your actual default image URL
    icon: contact.image ? contact.image : "defaultImageURL",
  }));

  useEffect(() => {
    const fetchData = async () => {
      dispatch2({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch2({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch2({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getAllContactList`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch3({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch3({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };
    fetchData();
  }, [dispatch2, userInfo]);

  useEffect(() => {
    setName(dataUser.name);
    setEmail(dataUser.email);
    setUsername(dataUser.username);
    setBio(dataUser.bio);
  }, [dataUser]);

  const submitHandler = async (e) => {
    e.preventDefault();

    dispatch({
      type: "UPDATE_REQUEST",
    });
    if (isCheckedUsername === false && isShowErrorUsername === true) {
      toast.error("Username is already taken! Please insert another username.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/updateProfile`,
        {
          name: name,
          bio: bio,
          username: username,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      dispatch({
        type: "UPDATE_SUCCESS",
      });
      // ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      // localStorage.setItem('userInfo', JSON.stringify(data));
      toast.success("User updated successfully");
      navigate("/welcome", { state: { string: "signup" } });
    } catch (err) {
      dispatch({
        type: "UPDATE_FAILED",
      });
      toast.error(getError(err));
    }
  };

  const updateContact = async (existingContact) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/updateUserContact`,
        {
          contact_id: existingContact.contact_id,
          listcontact_id: existingContact.listcontact_id,
          description: existingContact.description,
          link: existingContact.value,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      // Show success message to the user
      toast.success("Contact Updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Handle error
      toast.error(getError(error));
    }
  };

  const deleteContact = async (contactId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/delUserContact?contact_id=${contactId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      // Show success message to the user
      toast.success("Contact deleted successfully testing");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Handle error
      toast.error(getError(error));
    }
  };

  const saveNewContact = async (contact) => {
    console.log(contact);
    if (Object.values(contact).some((c) => !c.link)) {
      toast.error("Please enter a link for all selected social media options.");
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/addUserContact`,
        {
          contact,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      console.log();
      // Show success message to the user
      toast.success("Contact Saved Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Handle error
      toast.error(getError(error));
    }
  };

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  useEffect(() => {
    setName(dataUser.name);
    setEmail(dataUser.email);
    setUsername(dataUser.username);
    setBio(dataUser.bio);
  }, [dataUser]);

  function DefaultPP() {
    return (
      <img
        className="h-20 w-20 mb-3 mt-3"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSizeMB = 3;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      if (file.size > maxSizeBytes) {
        setFileSizeWarning(
          `File size exceeds ${maxSizeMB} MB. Please upload a smaller file.`
        );
        return;
      }
      setFileSizeWarning(""); // Clear warning if file size is acceptable
      setNewProfilePicture(file);
    }
  };

  const handleEditImage = () => {
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setNewProfilePicture(null);
  };

  const handleUsername = (e) => {
    let dataUsername = "";
    try {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/usernameCheck?username=${e}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        )
        .then((getResponse) => {
          dataUsername = getResponse.data.data.status;
          if (dataUsername === 1) {
            setIsCheckedUsername(false);
            setIsShowErrorUsername(true);
            setUsername(e);
          } else {
            setIsCheckedUsername(true);
            setIsShowErrorUsername(false);
            setUsername(e);
          }
        });
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const handleSocialMediaChange = (selectedOptions, actionMeta) => {
    // Remove the value from the state when it's deselected
    if (actionMeta.action === "remove-value") {
      const newValue = smSelected.filter(
        (option) => option.value !== actionMeta.removedValue.value
      );
      setSmSelected(newValue);
      setContact((prevContact) => {
        const newContact = { ...prevContact };
        delete newContact[actionMeta.removedValue.value];
        return { ...newContact };
      });
    } else {
      setSmSelected(selectedOptions);
      selectedOptions.forEach((selectedOption) => {
        if (!contact[selectedOption.value]) {
          setContact((prevContact) => ({
            ...prevContact,
            [selectedOption.value]: {
              listcontact_id: selectedOption.value,
              description: selectedOption.label,
              link: "",
            },
          }));
        }
      });
    }
  };

  function ProfilePicture() {
    return (
      <img
        className="h-20 w-20 mb-3 mt-3"
        src={dataUser.picture}
        alt="profilepicture"
      />
    );
  }

  return (
    <div className="">
      {loading ? (
        <div className="flex justify-center pt-6 ">
          <LoadingBox />
        </div>
      ) : error ? (
        <MessageBox severity="error">{error}</MessageBox>
      ) : (
        <div className="w-full h-[100vh] lg:h-full overflow-x-clip pb-32  pt-16 flex items-center justify-center bg-[rgb(255,228,204)]">
          <Helmet>
            <title>Create User Profile</title>
          </Helmet>
          <div
            className="rounded-lg border border-transparent border-solid border-gainsboro 
          shadow-md shadow-gainsboro  w-11/12 lg:w-[600px] h-full p-5 bg-white overflow-auto"
          >
            <div className="flex justify-center">
              <h1 className="text-center mb-8" style={{ fontSize: "2em" }}>
                Create Profile
              </h1>
            </div>
            <form className="w-full rounded-lg" onSubmit={submitHandler}>
              <div className="flex flex-col items-center relative">
                <SetProfilePicture />

                {isEditMode && (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                      id="imageInput"
                    />
                    {fileSizeWarning && (
                      <div
                        className="text-red-500 mt-1 mb-2"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {fileSizeWarning}
                      </div>
                    )}
                    <label
                      htmlFor="imageInput"
                      className="cursor-pointer bg-blue-500 text-white px-2 py-1 rounded mb-2"
                    >
                      Upload Image
                    </label>
                    <button
                      onClick={handleCancelEdit}
                      className="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Cancel
                    </button>
                  </>
                )}
                {!isEditMode && (
                  <button
                    onClick={handleEditImage}
                    className="bg-blue-500 text-white px-2 py-1 rounded mb-1"
                  >
                    Edit Image
                  </button>
                )}
              </div>

              <div className="">
                <div className="w-full mb-2">
                  <div className="flex items-center ">
                    <label style={{ width: "45%" }}>E-mail :</label>
                    <div
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-700
                        focus:outline-none
                      "
                      style={{ fontSize: "0.8rem" }}
                    >
                      {dataUser.email}
                    </div>
                  </div>
                </div>
                <div className="w-full mb-2">
                  <div className="flex items-center ">
                    <label style={{ width: "45%" }}>Name :</label>
                    <input
                      type="text"
                      placeholder={name || "Enter your name"}
                      className="
                        
                        w-full
                        border
                        rounded
                        px-3
                        py-2
                        text-gray-700
                        focus:outline-none
                      "
                      style={{ fontSize: "0.8rem" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="w-full mb-2">
                  <div className="flex items-center ">
                    <label style={{ width: "45%" }}>Username :</label>
                    <input
                      type="text"
                      placeholder={username || "Enter your username"}
                      className="
                        
                        w-[86%]
                        border
                        rounded
                        px-3
                        py-2
                        text-gray-700
                        focus:outline-none
                      "
                      style={{ fontSize: "0.8rem" }}
                      value={username}
                      onChange={(e) => handleUsername(e.target.value)}
                      required
                    />
                    <div
                      className="
                        border
                        rounded
                        px-3
                        text-gray-700
                        focus:outline-none
                      "
                      style={{ paddingBottom: "0.3em", paddingTop: "0.3em" }}
                    >
                      {isCheckedUsername ? (
                        <CheckCircleSharpIcon style={{ fill: "green" }} />
                      ) : (
                        <CheckCircleSharpIcon style={{ fill: "grey" }} />
                      )}
                    </div>
                  </div>
                  {isShowErrorUsername ? (
                    <div
                      className="
                        text-gray-700
                        focus:outline-none
                      "
                      style={{ fontSize: "0.8rem", color: "red" }}
                    >
                      *Username is already taken!
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-full mb-2">
                  <div className="flex items-center">
                    <label style={{ width: "45%" }}>Bio :</label>
                    <textarea
                      placeholder={bio || "Enter your bio"}
                      className="
                      w-full
                      border
                      rounded
                      px-3
                      py-2
                      text-gray-700
                      focus:outline-none
                    "
                      style={{ fontSize: "0.8rem" }}
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full mb-2 mt-10 pb-10">
                <div className="flex flex-row items-center">
                  <label style={{ width: "45%" }}>Contact:</label>
                  <div className="w-[86%] border rounded px-3 py-2 text-gray-700 focus:outline-none">
                    {dataUser.contact !== null ? (
                      <>
                        {dataUser.contact.map((existingContact, index) => (
                          <div key={existingContact.contact_id}>
                            <div className="flex flex-col lg:flex-row items-center mb-2">
                              <div className="mr-3 w-20">
                                {existingContact.contact_name}
                              </div>
                              <input
                                type="text"
                                value={
                                  prevContact[existingContact.contact_id]
                                    ?.value || existingContact.link
                                }
                                onChange={(e) => {
                                  setPrevContact((prevContact) => ({
                                    ...prevContact,
                                    [existingContact.contact_id]: {
                                      ...existingContact,
                                      value: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder="Enter link"
                                className="border rounded px-2 py-1 text-gray-700 focus:outline-none"
                              />
                              <div className="flex flex-row">
                                <button
                                  onClick={() =>
                                    updateContact(
                                      prevContact[existingContact.contact_id]
                                    )
                                  }
                                  className="bg-blue-500 text-white px-2 py-1 rounded ml-2"
                                  style={{
                                    background: "#F95C3D",
                                    color: "white",
                                    opacity:
                                      prevContact[existingContact.contact_id] &&
                                      prevContact[existingContact.contact_id]
                                        .value !== existingContact.link
                                        ? 1
                                        : 0.5,
                                  }}
                                  disabled={
                                    !prevContact[existingContact.contact_id] ||
                                    prevContact[existingContact.contact_id]
                                      .value === existingContact.link
                                  }
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() =>
                                    deleteContact(existingContact.contact_id)
                                  }
                                  className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                                >
                                  Delete
                                </button>
                              </div>
                              {/* Add update button here */}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      ""
                    )}

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      name="smSelected"
                      options={socialMediaOptions}
                      value={smSelected}
                      onChange={handleSocialMediaChange}
                      isMulti={true}
                    />
                    <div className="w-full mb-2">
                      <div className="flex flex-col items-center">
                        {smSelected.length > 0 &&
                          smSelected.map((selectedOption) => (
                            <div
                              className="flex flex-row mt-2"
                              key={selectedOption.value}
                            >
                              <div className="mr-3 w-20">
                                {selectedOption.label}
                              </div>
                              <input
                                type="text"
                                required
                                placeholder={selectedOption.url}
                                value={
                                  contact[selectedOption.value]?.link || ""
                                }
                                onChange={(e) => {
                                  setContact((prevContact) => ({
                                    ...prevContact,
                                    [selectedOption.value]: {
                                      listcontact_id: selectedOption.value,
                                      description: selectedOption.label,
                                      link: e.target.value,
                                    },
                                  }));
                                }}

                                className="border rounded px-2 py-1 text-gray-700 focus:outline-none"
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                    {/* Add save button here */}

                    {smSelected.length === 0 ? (
                      ""
                    ) : (
                      <div className="flex justify-center">
                        <button
                          onClick={() => saveNewContact(contact)}
                          className="p-3 m-3"
                          style={{
                            background: "#F95C3D",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="
                    pt-2
                    pb-2
                    pl-8
                    pr-8
                    rounded-lg
                    focus:outline-none
                    font-bold
                  "
                  style={{
                    fontSize: "0.8rem",
                    background: "#F95C3D",
                    color: "white",
                  }}
                >
                  Create Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProfileScreen;
