import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Helmet } from "react-helmet-async";

function ResetPassword() {
    const navigate = useNavigate();
    const { search } = useLocation();
    const redirectInUrl = new URLSearchParams(search).get("redirect");
    const token = new URLSearchParams(search).get("token");
    const redirect = redirectInUrl ? redirectInUrl : `/signin`;

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [, setOpenVerifModal] = useState(false);
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { userInfo } = state;

    const [showModal, setShowModal] = useState(false);

    const handleOpenVerifModal = () => {
        setOpenVerifModal(true);
    }

    const closeModal = () => {
        navigate(redirect);
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (password === newPassword) {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/updateResetPass`,
                    {
                        newPassword,
                    },
                    {
                        headers: { "Authorization": `Bearer ${token}` },
                    }
                );
                console.log(data);
                ctxDispatch({ type: "USER_SIGNIN", payload: data });
                localStorage.setItem("userInfo", JSON.stringify(data));
                setShowModal(true);
            } catch (err) {
                if (err.response.data.message === "Verify your account" || err.response === 411) {
                    handleOpenVerifModal();
                } else {
                    toast.error(getError(err));
                }
            }
        } else {
            toast.error("Password and Confirm New Password must match");
        }
    }

    useEffect(() => {
        if (userInfo) {
            setShowModal(true);
        }
    }, [setShowModal, navigate, userInfo, redirect]);

    return (
        <div className="min-h-screen bg-[rgb(255,228,204)] text-gray-900 flex justify-center">
            <Helmet>
                <title>Reset Password | SiapHub</title>
            </Helmet>
            <div className="max-w-screen-xl m-0 sm:my-auto sm:mb-auto lg:mx-96 xl:mx-96  sm:rounded-lg flex justify-center flex-1">
                <div className="lg:w-1/2 xl:w-1/2 p-8 sm:p-8">
                    <div>
                        <div className="flex font-bold justify-center">
                            <img
                                className="h-28 w-28 mb-3"
                                src="../../images/siaphub_logo.png"
                                alt="siaphub logo"
                            />
                        </div>
                        <p className="text-xl text-gray-600 text-center">Reset Password</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="w-full flex-1">

                            <div className="mx-auto max-w-xs">
                                <form onSubmit={submitHandler}>
                                    <div className="mt-4">
                                        <input
                                            type="password"
                                            placeholder="New Password"
                                            name="password"
                                            className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                                            style={{ fontSize: "0.8rem" }}
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <input
                                            type="password"
                                            placeholder="Confirm New Password"
                                            name="newPassword"
                                            className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                                            style={{ fontSize: "0.8rem" }}
                                            value={newPassword}
                                            onChange={(e) => {
                                                setNewPassword(e.target.value);
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <button type="submit" className="bg-[#FF8D52] text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">Change Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex font-bold justify-center mt-5">
                                <img
                                    className="h-28 w-28 mb-3"
                                    src="../../images/icon_done.png"
                                    alt="siaphub logo"
                                />
                            </div>
                            <div className=" justify-between h-3 text-center text-3xl">Success</div>
                            <div className="relative p-5 flex-auto">
                                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                    Your password has been changed successfully.
                                </p>
                            </div>

                            <div className="flex items-center justify-end p-5">
                                <button
                                    className="bg-green-500 text-white font-bold py-2 px-4 w-full rounded"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ResetPassword;