import { useLocation } from 'react-router-dom';
import Header from "./HeaderAndFooter/Header";
import "react-toastify/dist/ReactToastify.css";

const Headers = (props) => {
    const location = useLocation();

    return (
        <div>
            {!(location.pathname === '/signin' || location.pathname === '/register' || location.pathname === '/forget_password' || location.pathname === '/reset_password' || location.pathname === '/forgot_password' || location.pathname === '/welcome' ) &&
                <header className="">
                    <Header onChildStateChange={props.onChildStateChange} onChildStateChangePu={props.onChildStateChangePu} 
                    onChildStateChangeSearch={props.onChildStateChangeSearch} onSearchIconClick={props.onSearchIconClick}
                    onChildStateChangeSearchFlag={props.onChildStateChangeSearchFlag}
                    ></Header>
                </header>
            }
        </div>
    );
};

export default Headers;
