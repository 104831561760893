import React, { useEffect } from "react";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const AboutUsScreen = () => {
  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaGFmaXpld3BiaW51cyIsImEiOiJjbHR6amlqb2owMG80MmprNHQwdGtpZnFjIn0.78wSTsUUXOjCAcAIwLecCA';
    const map = new mapboxgl.Map({
      container: 'map', // container ID
      // style: 'mapbox://styles/hafizewpbinus/clu0qw6jj00n701qwcgtsbi5m', // Gray
      style: 'mapbox://styles/mapbox/streets-v11', // Gray
      zoom: 16.5, // starting zoom
      center: [106.701351, -6.193884],
      attributionControl: false,
    });

    map.on('load', () => {
      // Load an image from an external URL.
      map.loadImage(
        '../images/marker_new.png',
        (error, image) => {
          if (error) throw error;

          map.addImage('point_purwanto', image);

          map.addSource('point', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [{
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates': [106.701034, -6.193704]
                }
              }]
            }
          });

          map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'point',
            'layout': {
              'icon-image': 'point_purwanto',
              'icon-size': 0.25
            }
          });
        }
      );
    });

    map.on('click', 'points', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var link = 'https://maps.app.goo.gl/XykXsMuHDVuy9gzm6';

      if (typeof link === 'string' && link.length > 0) {
        window.open(link, '_blank').focus();
      }
    });

    map.on('mouseenter', 'points', function () {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'points', function () {
      map.getCanvas().style.cursor = '';
    });

    return () => map.remove();
  }, []);

  return (
    <>
    <Helmet>
        <title>About Us | SiapHub</title>
      </Helmet>
      <div className="mx-auto max-w-7xl px-4 py-10 sm:px-4 lg:px-5 lg:py-10">
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Profile
            </h2>
            <p className="text-base tracking-wide">
              Welcome to Siaphub (PT. Swijaya Fokus Multisukses), a cutting-edge tech company based in the
              vibrant city of Tangerang, Indonesia.
            </p>
            <p className="text-base tracking-wide mt-4">
              Established in 2023 by a team of four passionate tech
              professionals, Siaphub has been dedicated to develop innovative
              solutions since 2022.
            </p>
            <p className="text-base tracking-wide mt-4">
              At Siaphub, we believe in the power of technology to transform
              lives. Our platform is designed to empower individuals, whether
              they're students, professionals, or business owners, to achieve
              their goals and fulfill their purposes.
            </p>
            <p className="text-base tracking-wide mt-4">
              Driven by a commitment to excellence, we strive to provide our
              users with the tools and resources they need to succeed in today's
              fast-paced world. Whether you're looking to enhance your skills
              with world-known institutions, grow your business and services, or
              simply explore new opportunities, Siaphub is here to support you
              every step of the way.
            </p>
            <p className="text-base tracking-wide mt-4">
              Harnessing the latest advancements in Artificial Intelligence,
              Siaphub offers cutting-edge solutions to help users unlock their
              full potential. Our AI-driven tools provide personalized
              recommendations and insights, ensuring that every user receives
              the support they need to succeed.
            </p>
            <p className="text-base tracking-wide mt-4">
              Join us on our journey to make a difference, one user at a time.
              Together, let's build a brighter future, powered by technology and
              driven by innovation.
            </p>
          </div>

          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Vision & Mission
            </h2>

            <p className="text-2xl font-semibold tracking-wide mb-4">
              Vision
            </p>

            <p className="text-base tracking-wide mb-4">
              At Siaphub, we envision a future where technology empowers
              individuals and transforms communities. Our vision is to be an
              AI-enabled education, business, and social media platform that
              simplifies collaboration and communication for everyone.
            </p>
            <p className="text-base tracking-wide mb-4">
              We believe that by harnessing the power of Artificial
              Intelligence, we can create a platform that not only connects
              people but also enhances their abilities and enriches their lives.
              Whether you're a student seeking knowledge, a professional
              striving for success, or a business owner looking to expand your
              reach, Siaphub is here to support you on your journey.
            </p>
            <p className="text-base tracking-wide mb-4">
              Our vision is to build a platform that breaks down barriers,
              fosters creativity, and inspires innovation. Together, let's
              redefine collaboration and communication in the digital age,
              making it more accessible, efficient, and meaningful for all.
            </p>
            <p className="text-base tracking-wide mb-4">
              Join us as we work towards a future where technology serves as a
              catalyst for positive change, empowering individuals and
              communities to achieve their full potential.
            </p>

            <p className="text-2xl font-semibold tracking-wide mb-4">
              Mission
            </p>

            <p className="text-base tracking-wide mb-4">
              Siaphub is on a mission to revolutionize the way people
              collaborate, learn, work, and connect. Our platform aims to
              provide easy access for businesses to collaborate seamlessly,
              students to explore study opportunities overseas, and individuals
              to find jobs effortlessly.
            </p>
            <p className="text-base tracking-wide mb-4">
              We strive to create a digital ecosystem where everything is easily
              located and accessible. Whether you're looking for a new business
              partner, a study program abroad, a job opportunity, or simply
              seeking information, Siaphub is your go-to platform.
            </p>
            <p className="text-base tracking-wide mb-4">
              Our mission is to break down barriers and create a world where
              collaboration, education, and opportunities are within reach for
              everyone. Join us as we simplify the way people connect and
              discover, making the world a smaller, more connected place.
            </p>
          </div>

          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Profile
            </h2>
            <p className="text-base tracking-wide">
              <div className="container my-10 mx-auto md:px-6">
                <section className="text-center">
                  <div className="grid gap-6 lg:grid-cols-4 xl:gap-x-12">
                    <div className="mb-6 lg:mb-0">
                      <div
                        className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat dark:shadow-black/20"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                      >
                        <img
                          src="../images/profile/Jonathan_edit.jpeg"
                          className="object-cover lg:w-40 lg:h-40 sm:w-64 sm:h-64 mx-auto border-2"
                          alt="Jonathan"
                        />
                      </div>

                      <a href="https://www.linkedin.com/in/jonathanwidarta67/" target="_blank" rel="noopener noreferrer">
                        <img src="../images/icons/linkedin.svg" className="mx-auto rounded text-neutral-500 h-5 w-5 mb-2" alt="LinkedIn Icon" />
                      </a>
                      <h5 className="text-lg font-bold">Jonathan Widarta</h5>
                      <p className="text-neutral-500">
                        <small>President Director</small>
                      </p>
                    </div>

                    <div className="mb-6 lg:mb-0">
                      <div
                        className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat dark:shadow-black/20"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                      >
                        <img
                          src="../images/profile/Melvin_edit.jpeg"
                          className="object-cover lg:w-40 lg:h-40 sm:w-64 sm:h-64 mx-auto border-2"
                          alt="Melvin"
                        />
                      </div>

                      <a href="https://www.linkedin.com/in/melvin-tan-78a7571a2/" target="_blank" rel="noopener noreferrer">
                        <img src="../images/icons/linkedin.svg" className="mx-auto rounded text-neutral-500 h-5 w-5 mb-2" alt="LinkedIn Icon" />
                      </a>
                      <h5 className="text-lg font-bold">Melvin Tan</h5>
                      <p className="text-neutral-500">
                        <small>Deputy Director</small>
                      </p>
                    </div>

                    <div className="mb-0">
                      <div>
                        <div
                          className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat dark:shadow-black/20"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                        >
                          <img
                            src="../images/profile/Hans_edit.jpeg"
                            className="object-cover lg:w-40 lg:h-40 sm:w-64 sm:h-64 mx-auto border-2"
                            alt="Hans"
                          />
                        </div>

                        <a href="https://www.linkedin.com/in/yohanes-septianto" target="_blank" rel="noopener noreferrer">
                          <img src="../images/icons/linkedin.svg" className="mx-auto rounded text-neutral-500 h-5 w-5 mb-2" alt="LinkedIn Icon" />
                        </a>
                        <h5 className="text-lg font-bold">Yohanes Septianto</h5>
                        <p className="text-neutral-500">
                          <small>Main Commissioner</small>
                        </p>
                      </div>
                    </div>

                    <div className="mb-0">
                      <div>
                        <div
                          className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat dark:shadow-black/20"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                        >
                          <img
                            src="../images/profile/Hafiz_edit.jpeg"
                            className="object-cover lg:w-40 lg:h-40 sm:w-64 sm:h-64 mx-auto border-2"
                            alt="Hafiz"
                          />
                        </div>

                        <a href="https://www.linkedin.com/in/hafizewp/" target="_blank" rel="noopener noreferrer">
                          <img src="../images/icons/linkedin.svg" className="mx-auto rounded text-neutral-500 h-5 w-5 mb-2" alt="LinkedIn Icon" />
                        </a>
                        <h5 className="text-lg font-bold">
                          Hafiz Elfia Wedo Putra
                        </h5>
                        <p className="text-neutral-500">
                          <small>Commissioner</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </p>
          </div>

          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Location
            </h2>
            <p className="text-base tracking-wide">
              <div id="map" style={{ minHeight: '500px', width: '100%' }}></div>
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-t-lg flex flex-col lg:flex-row justify-between items-center p-4 lm:pb-8">
        <div>
          <p>&copy; 2024 Siaphub (PT. Swijaya Fokus Multisukses). All Rights Reserved.</p>
        </div>
        <div className="mt-4 lg:mt-0">
          <Link to="/privacy_policy" className="mr-4">Privacy Policy</Link>
          <span>|</span>
          <Link to="/terms_of_service" className="ml-4">Terms Of Service</Link>
        </div>
      </div>
    </>
  );
};

export default AboutUsScreen;
