import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Helmet } from "react-helmet-async";

function PrivacyPolicyScreen() {
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 py-10 sm:px-4 lg:px-5 lg:py-10">
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h1 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Privacy Policy for SiapHub
            </h1>
            <p className="text-base tracking-wide">
              At SiapHub, accessible from https://siaphub.com/, one of our main
              priorities is the privacy of our visitors. This Privacy Policy
              document contains types of information that is collected and
              recorded by SiapHub and how we use it.
            </p>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to{" "}
              <Link to="/contact_us">contact us</Link>.
            </p>
          </div>

          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Log Files
            </h2>
            <p className="text-base tracking-wide">
              SiapHub follows a standard procedure of using log files. These
              files log visitors when they visit websites. All hosting companies
              do this and a part of hosting services' analytics. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>
          </div>

          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Privacy Policy
            </h2>
            <p className="text-base tracking-wide">
              Welcome to our social media web app. Your privacy is important to
              us. This Privacy Policy explains how we collect, use, and protect
              your information.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              Information We Collect
            </h3>
            <p className="text-base tracking-wide">
              We collect information that you provide to us directly, such as
              when you create an account, update your profile, post content, or
              communicate with us. This information may include your name, email
              address, profile picture, and any other details you choose to
              provide.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              Use of Google OAuth 2.0
            </h3>
            <p className="text-base tracking-wide">
              SiapHub uses OAuth 2.0 for user permissions and consent. This
              allows us to specify the type and level of access required for our
              app to function, such as accessing basic profile information,
              profile pictures, and email addresses.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              How We Use Your Information
            </h3>
            <p className="text-base tracking-wide">
              We use the information we collect to operate, maintain, and
              improve our services. This includes personalizing your experience,
              providing customer support, and communicating with you about
              updates and promotions.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              Sharing Your Information
            </h3>
            <p className="text-base tracking-wide">
              SiapHub does not share your personal information with third
              parties except as necessary to provide our services, comply with
              the law, or protect our rights.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              Security
            </h3>
            <p className="text-base tracking-wide">
              We take reasonable measures to protect your information from
              unauthorized access, use, or disclosure. However, no internet or
              email transmission is ever fully secure or error-free.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              Your Choices
            </h3>
            <p className="text-base tracking-wide">
              You may update or correct your account information at any time by
              accessing your account settings. You may also delete your account,
              but please note that we may retain certain information as required
              by law or for legitimate business purposes.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              Changes to This Policy
            </h3>
            <p className="text-base tracking-wide">
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new policy on our
              website. You are advised to review this Privacy Policy
              periodically for any changes.
            </p>
            <h3 className="font-heading mt-4 mb-2 font-bold text-2xl">
              Contact Us
            </h3>
            <p className="text-base tracking-wide">
              If you have any questions about this Privacy Policy, please
              contact us at siaphubcompany@gmail.com.
            </p>
          </div>

          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Children's Information
            </h2>
            <p className="text-base tracking-wide">
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>
            <p className="text-base tracking-wide">
              SiapHub does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our website, we
              strongly encourage you to contact us immediately and we will do
              our best efforts to promptly remove such information from our
              records.
            </p>
          </div>

          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Consent
            </h2>
            <p className="text-base tracking-wide">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its Terms and Conditions.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-t-lg flex flex-col lg:flex-row justify-between items-center p-4 lm:pb-8">
        <div>
          <p>
            &copy; 2024 Siaphub (PT. Swijaya Fokus Multisukses). All Rights
            Reserved.
          </p>
        </div>
        <div className="mt-4 lg:mt-0">
          <Link to="/privacy_policy" className="mr-4">
            Privacy Policy
          </Link>
          <span>|</span>
          <Link to="/terms_of_service" className="ml-4">
            Terms Of Service
          </Link>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyScreen;
