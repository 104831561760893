/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function ForgotPassword() {
    const location = useLocation();
    const inputEmail = new URLSearchParams(location.search).get('email');

    // const hideEmail = (email) => {
    //     const [username, domain] = email.split('@');
    //     const hiddenUsername = username.slice(0, 3) + '*'.repeat(username.length - 3);
    //     const hiddenEmail = `${hiddenUsername}@${domain}`;
    //     return hiddenEmail;

    //     // input: indahanis418@gmail.com
    //     // output: ind*********@gmail.com
    // };

    // const hiddenEmail = hideEmail(inputEmail);

    return (
        <div className="min-h-screen bg-[rgb(255,228,204)] text-gray-900 flex justify-center">
            <div className="max-w-screen-xl m-0 sm:my-auto sm:mb-auto lg:mx-96 xl:mx-96  sm:rounded-lg flex justify-center flex-1">
                <div className="p-8 sm:p-8">
                    <div className="p-8 sm:p-8">
                        <div>
                            <div className="flex font-bold justify-center">
                                <img
                                    className="h-56 w-56 mb-3"
                                    src="../../images/logo.png"
                                    alt="siaphub logo"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-full flex-1">
                                <div className="mx-auto max-w-xs mt-4 text-center text-lg text-[#CC4D28]">
                                    <h1>The verification link has been sent to <a className='font-bold'>{inputEmail}</a>, check email inbox to change/recover the old password</h1>
                                </div>

                                <div className="mt-10 flex font-bold justify-center">
                                    <Link to={`/signin`} className="bg-[#CC4D28] text-white font-bold py-2 px-4 rounded">
                                        Done
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ForgotPassword;