/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

function UpgradeAccountScreen() {
  const navigate = useNavigate();

  const submitHandler = () => {
    navigate("/register_professional_account");
  };
  return (
    <>
      <div className="w-full flex items-center justify-center">
        <Helmet>
          <title>Professional Account</title>
        </Helmet>
        <div className="cardProfessional xs:w-screen-90 border-t-8 border-[#F95C3D]">
          <div className="flex justify-center">
            <h1 className="text-center" style={{ fontSize: "2em" }}>
              Upgrade to Professional Account
            </h1>
          </div>
          <div className="flex justify-center mt-8">
            <button
              className="py-2 px-4 rounded-lg bg-[#F95C3D] text-white hover:bg-orange-600"
              onClick={submitHandler}
            >
              Get Started
            </button>
          </div>
          <div className="flex justify-left mt-8 ml-8">
            <div className="flex-col">
              <div className="flex my-4">
                <div className="w-6">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="#F95C3D" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <p className="flex items-center ml-2">
                    Get access to choose category account.
                  </p>
                </div>
              </div>
              <div className="flex my-4">
                <div className="w-6">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="#F95C3D" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <p className="flex items-center ml-2">
                    Get access to set business location with detail address.
                  </p>
                </div>
              </div>
              <div className="flex my-4">
                <div className="w-6">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="#F95C3D" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <p className="flex items-center ml-2">
                    Get access to set business operating day and hour
                  </p>
                </div>
              </div>
              <div className="flex my-4">
                <div className="w-6">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="#F95C3D" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.8688 6.95179C15.1617 7.24468 15.1617 7.71955 14.8688 8.01245L9.52556 13.3557C9.3849 13.4963 9.19414 13.5753 8.99522 13.5753C8.79631 13.5753 8.60554 13.4963 8.46489 13.3557L5.80811 10.6988C5.51522 10.4059 5.51523 9.93105 5.80813 9.63816C6.10102 9.34527 6.5759 9.34528 6.86879 9.63818L8.99523 11.7647L13.8081 6.95179C14.101 6.65889 14.5759 6.65889 14.8688 6.95179Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <p className="flex items-center ml-2">More coming soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpgradeAccountScreen;
