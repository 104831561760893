/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Modal } from "@mui/material";
import { getTokenFCM } from "../firebase";

const SignInScreen = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const [openVerifModal, setOpenVerifModal] = useState(false);
  // const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  const [activeSlide, setActiveSlide] = useState(1);

  useEffect(() => {
    if (localStorage.checkbox && localStorage.email !== "") {
      setIsChecked(true);
      setEmail(localStorage.email);
      setPassword(localStorage.password);
    }
  }, []);

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };

  const loginSubmit = () => {
    if (isChecked && email !== "") {
      localStorage.email = email;
      localStorage.password = password;
      localStorage.checkbox = isChecked;
    }
  };

  const slide = [
    {
      slides: 1,
      photo: "../../images/groupinformation.png",
      title: "Connecting Opportunities All Around You!",
      detail:
        "Find products,services, events, jobs, and insights at ease with the map feature only on SiapHub.",
    },
    {
      slides: 2,
      photo: "../../images/siaphub_logo_clean.png",
      title: "Be A Contributor In Your Environment",
      detail: "Share any information and insights you have on SiapHub.",
    },
  ];

  // const handlePrevClick = () => {
  //   setActiveSlide(activeSlide === 1 ? slide.slides.length : activeSlide - 1);
  // };

  // const handleNextClick = () => {
  //   setActiveSlide(activeSlide === slide.slides.length ? 1 : activeSlide + 1);
  // };

  async function handleCallbackResponse(response) {
    let googleToken = response.credential;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/signingoogle`,
        {
          googleToken,
        },
        {
          auth: {
            username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
            password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
          },
        }
      );
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      // navigate(redirect || "/");
      const fcmToken = await getTokenFCM(data.data.token);
      navigate("/welcome", { state: { string: "signin" } });
    } catch (err) {
      toast.error(getError(err));
    }
  }

  useEffect(() => {
    window.google &&
      window.google.accounts.id.initialize({
        client_id: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
        callback: handleCallbackResponse,
      });
  }, []);

  useEffect(() => {
    if (window.google) {
      const renderButton = (elementId) => {
        window.google.accounts.id.renderButton(
          document.getElementById(elementId),
          {
            theme: "outline",
            size: "large",
            type: "standard",
            shape: "rectangular",
            logo_alignment: "left",
            width: 240,
          }
        );
      };

      renderButton("signInDiv");
      renderButton("signInDiv2");
    }
  }, [window.google]);

  const handleOpenOtpModal = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/generateOtp`,
        {
          email: email,
        },
        {
          auth: {
            username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
            password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
          },
        }
      );
      navigate("/otp_user", { state: { email: email } });
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const handleCloseOtpModal = () => {
    setOpenOtpModal(false);
  };
  const handleOpenVerifModal = () => {
    setOpenVerifModal(true);
  };

  const handleCloseVerifModal = () => {
    setOpenVerifModal(false);
  };

  // const handleOpenProfileModal = () => {
  //   setOpenProfileModal(true);
  // };

  // const handleCloseProfileModal = () => {
  //   setOpenProfileModal(false);
  // };

  const handleToCreateProfilePage = () => {
    navigate("/create_profile");
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpSend = otpValue[0] + otpValue[1] + otpValue[2] + otpValue[3];
    if (otpSend.length === 4) {
      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/verifyotp`,
          {
            otp: otpSend,
            email: email,
          },
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );
        alert("e-mail Verified, Welcome to SiapHub");
        setOpenOtpModal(false);
        // navigate(redirect || "/");
        navigate("/welcome", { state: { string: "signin" } });
      } catch (err) {
        toast.error(getError(err));
      }
    } else {
      alert("Please enter a 4-digit OTP");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/signin`,
        {
          email,
          password,
        },
        {
          auth: {
            username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
            password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
          },
        }
      );
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));

      const fcmToken = await getTokenFCM(data.data.token);

      // navigate(redirect || "/");
      navigate("/welcome", { state: { string: "signin" } });
    } catch (err) {
      console.log(err);
      if (
        err.response.data.message === "Verify your account" ||
        err.response === 411
      ) {
        handleOpenVerifModal();
      }
      // else if (err.response.data.message === "Incomplete profile") {
      //   console.log("MASUK");
      //   handleOpenProfileModal();
      // }
      console.log(getError(err));
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev === slide.length ? 1 : prev + 1));
    }, 4000);

    return () => clearInterval(interval);
  }, [slide.length, activeSlide]);

  // useEffect(() => {
  //   if (userInfo) {
  //     navigate('/welcome', { state: { string: 'signin' } });
  //   }
  // }, [navigate, userInfo, redirect]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {window.google && (
        <div>
          <Helmet>
            <title>Sign In | SiapHub</title>
          </Helmet>
          {/* Mobile Layout */}
          <div className="h-full my-auto flex flex-col justify-center items-center bg-gray lg:hidden">
            <div className="w-full p-4 text-center bg-[#F95C3D]">
              <img
                src="../../images/siaphub_logo.png"
                alt="Logo"
                className="w-16 h-16 mx-auto"
              />
            </div>

            {slide.map((slide) => (
              <div
                key={slide.slides}
                className={`w-full p-4 bg-[#F95C3D] text-white ${
                  activeSlide === slide.slides ? "" : "hidden"
                }`}
              >
                <div className="mb-4">
                  <div className="w-32 h-32 mx-auto overflow-hidden">
                    <img
                      src={slide.photo}
                      alt="siaphub logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
                <h1 className="text-center text-xl text-white">
                  {slide.title}
                </h1>
                <p className="text-center">{slide.detail}</p>
              </div>
            ))}

            <div className="py-4 bg-[#F95C3D] w-full flex items-center justify-center">
              {slide.map((slide) => (
                <button
                  key={slide}
                  className={`flex-1 w-4 h-2 mt-4 mx-2 mb-0 rounded-full overflow-hidden transition-colors duration-200 ease-out ${
                    activeSlide === slide.slides
                      ? "bg-orange-600 hover:bg-orange-600 hover:shadow-lg"
                      : "bg-zinc-600"
                  }`}
                  onClick={() => setActiveSlide(slide.slides)}
                ></button>
              ))}
            </div>

            <div className="w-full px-10 py-10">
              <div
                id="signInDiv"
                className="flex items-center justify-center mt-5"
              ></div>
              <form onSubmit={submitHandler}>
                <div className="mt-4">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                    style={{ fontSize: "0.8rem" }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      onChangeValue(e);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                      style={{ fontSize: "0.8rem" }}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        onChangeValue(e);
                      }}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#F95C3D]"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <svg
                          className="shrink-0 size-3.5"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      ) : (
                        <svg
                          className="shrink-0 size-3.5"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                          <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                          <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                          <line x1="2" x2="22" y1="2" y2="22"></line>
                        </svg>
                      )}
                    </button>
                  </div>
                  <div className="flex mt-4 items-center">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={onChangeCheckbox}
                      value="lsRememberMe"
                      id="rememberMe"
                      className="text-xs text-gray-500 accent-[#F95C3D]"
                    />{" "}
                    <label for="rememberMe" className="text-xs ml-1">
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded-lg hover:bg-gray-600"
                    onClick={loginSubmit}
                  >
                    Login
                  </button>
                </div>

                <Link
                  to={`/forget_password`}
                  className="mt-4 text-xs text-gray-500 flex items-end  justify-end"
                >
                  Forgot Password?
                </Link>
                <div className="mt-4 flex items-center justify-center">
                  <a className="text-xs text-gray-500 text-center">
                    New User ?{" "}
                    <Link to={`/register`} className="text-[#F95C3D]">
                      {" "}
                      Create new account
                    </Link>
                  </a>
                </div>
                <div className="mt-4 flex items-center justify-center">
                  <a className="text-xs text-gray-500 text-center">
                    <Link to={`/`} className="text-[#F95C3D]">
                      {" "}
                      Back Home
                    </Link>
                  </a>
                </div>
              </form>
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="min-h-screen bg-[rgb(255,228,204)] text-gray-900 flex justify-center hidden lg:flex">
            <div className="max-w-screen-xl m-auto lg:m-auto xl:m-auto bg-white shadow sm:rounded-lg flex justify-center flex-1">
              <div className="lg:w-1/2 xl:w-1/2 p-6 sm:p-12">
                <div>
                  <div className="flex font-bold justify-center">
                    <img
                      className="h-24 w-24 mb-3"
                      src="../../images/siaphub_logo.png"
                      alt="siaphub logo"
                    />
                  </div>
                  <p className="text-xl text-gray-600 text-center">
                    Login to Your Account
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <div
                    id="signInDiv2"
                    className="flex items-center justify-center mt-5"
                  ></div>
                  <div className="w-full flex-1">
                    <div className="my-5 border-b text-center">
                      <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                        Or sign up with e-mail
                      </div>
                    </div>

                    <div className="mx-auto max-w-xs">
                      <form onSubmit={submitHandler}>
                        <div className="mt-4">
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                            style={{ fontSize: "0.8rem" }}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              onChangeValue(e);
                            }}
                          />
                        </div>
                        <div className="mt-4">
                          <div className="relative">
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                              style={{ fontSize: "0.8rem" }}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                onChangeValue(e);
                              }}
                            />
                            <button
                              type="button"
                              className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#F95C3D]"
                              onClick={handleTogglePassword}
                            >
                              {showPassword ? (
                                <svg
                                  className="shrink-0 size-3.5"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              ) : (
                                <svg
                                  className="shrink-0 size-3.5"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                  <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                  <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                  <line x1="2" x2="22" y1="2" y2="22"></line>
                                </svg>
                              )}
                            </button>
                          </div>
                          <div className="flex mt-4 items-center">
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={onChangeCheckbox}
                              value="lsRememberMe"
                              id="rememberMe"
                              className="text-xs text-gray-500 accent-[#F95C3D]"
                            />{" "}
                            <label for="rememberMe" className="text-xs ml-1">
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded-lg hover:bg-gray-600"
                            onClick={loginSubmit}
                          >
                            Login
                          </button>
                        </div>

                        <Link
                          to={`/forget_password`}
                          className="mt-4 text-xs text-[#F95C3D] flex items-end justify-end"
                        >
                          Forgot Password?
                        </Link>
                        <div className="mt-4 flex items-center justify-center">
                          <a className="text-xs text-gray-500 text-center">
                            New User ?{" "}
                            <Link to={`/register`} className="text-[#F95C3D]">
                              {" "}
                              Create new account
                            </Link>
                          </a>
                        </div>
                        <div className="mt-4 flex items-center justify-center">
                          <a className="text-xs text-gray-500 text-center">
                            <Link to={`/`} className="text-[#F95C3D]">
                              {" "}
                              Back Home
                            </Link>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:w-1/2 xl:w-1/2  flex-1 bg-[#F95C3D] text-center sm:rounded-r-lg hidden lg:flex">
                <div className="m-12 xl:m-16 w-full  bg-center">
                  <div className="mt-20">
                    <div className="max-w-4xl mx-auto relative">
                      {slide.map((slide) => (
                        <div
                          key={slide.slides}
                          className={`p-2 h-64 flex flex-col justify-center items-center text-white rounded-lg ${
                            activeSlide === slide.slides ? "" : "hidden"
                          }`}
                        >
                          <div className="flex font-bold justify-center">
                            <img
                              className="h-64 w-64 mb-3"
                              src={slide.photo}
                              alt="siaphub logo"
                            />
                          </div>
                          <br />
                          <div className="font-bold">
                            <h1>{slide.title}</h1>
                          </div>
                          <br />
                          <div className="capitalize">{slide.detail}</div>
                        </div>
                      ))}

                      <div className="absolute w-full flex items-center justify-center px-4 mt-24">
                        {slide.map((slide) => (
                          <button
                            key={slide}
                            className={`flex-1 w-4 h-2 mt-4 mx-2 mb-0 rounded-full overflow-hidden transition-colors duration-200 ease-out ${
                              activeSlide === slide.slides
                                ? "bg-orange-600 hover:bg-orange-600 hover:shadow-lg"
                                : "bg-zinc-600"
                            }`}
                            onClick={() => setActiveSlide(slide.slides)}
                          ></button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={openVerifModal}
            onClose={handleCloseVerifModal}
            contentLabel="Verification Modal"
            className="verification-modal"
            overlayClassName="verification-modal-overlay bg-gray-700 bg-opacity-75 fixed inset-0"
          >
            <div className="modal-content bg-white rounded-lg p-4">
              <h1 className="text-center">
                You need to verify your email to login
              </h1>
              <h1 className="text-center">
                To verify your email, you need to input OTP that we send to your
                email
              </h1>
              <h1 className="text-center">Do you want to verify your email?</h1>
              <div className="modal-buttons flex justify-center items-center space-x-4 mt-6">
                <button
                  type="submit"
                  onClick={handleCloseVerifModal}
                  className="verification-submit-btn bg-red-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                >
                  NO
                </button>
                <button
                  type="submit"
                  onClick={handleOpenOtpModal}
                  className="verification-submit-btn bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                >
                  Yes, I want to verify my email.
                </button>
              </div>
            </div>
          </Modal>

          {/* <Modal open={openProfileModal} onClose={handleCloseProfileModal} contentLabel="Verification Modal" className="verification-modal" overlayClassName="verification-modal-overlay bg-gray-700 bg-opacity-75 fixed inset-0">
        <div className="modal-content bg-white rounded-lg p-4">
          <h1 className="text-center">You need to complete your profile to login</h1>
          <div className="modal-buttons flex justify-center items-center space-x-4 mt-6">
            <button type="submit" onClick={handleCloseProfileModal} className="verification-submit-btn bg-red-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
              NO
            </button>
            <button type="submit" onClick={handleToCreateProfilePage} className="verification-submit-btn bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
              Yes, I want to complete my profile.
            </button>
          </div>
        </div>
      </Modal> */}

          <Modal
            open={openOtpModal}
            onClose={handleCloseOtpModal}
            contentLabel="OTP Modal"
            className="otp-modal"
            overlayClassName="otp-modal-overlay bg-gray-400 bg-opacity-75 fixed inset-0"
          >
            <form onSubmit={handleOtpSubmit}>
              <label
                className="modal-title text-white text-center"
                htmlFor="otp=input"
              >
                Enter OTP:
              </label>
              <br />
              <input
                type="text"
                className="text-center"
                maxLength="1"
                value={otpValue[0] || ""}
                onChange={(event) => {
                  setOtpValue([
                    event.target.value,
                    otpValue[1],
                    otpValue[2],
                    otpValue[3],
                  ]);
                  if (event.target.value) {
                    document.getElementById("otp-field-2").focus();
                  }
                }}
                id="otp-field-1"
              />
              <input
                type="text"
                className="text-center"
                maxLength="1"
                value={otpValue[1] || ""}
                onChange={(event) => {
                  setOtpValue([
                    otpValue[0],
                    event.target.value,
                    otpValue[2],
                    otpValue[3],
                  ]);
                  if (event.target.value) {
                    document.getElementById("otp-field-3").focus();
                  } else {
                    document.getElementById("otp-field-1").focus();
                  }
                }}
                id="otp-field-2"
              />
              <input
                type="text"
                className="text-center"
                maxLength="1"
                value={otpValue[2] || ""}
                onChange={(event) => {
                  setOtpValue([
                    otpValue[0],
                    otpValue[1],
                    event.target.value,
                    otpValue[3],
                  ]);
                  if (event.target.value) {
                    document.getElementById("otp-field-4").focus();
                  } else {
                    document.getElementById("otp-field-2").focus();
                  }
                }}
                id="otp-field-3"
              />
              <input
                type="text"
                className="text-center"
                maxLength="1"
                value={otpValue[3] || ""}
                onChange={(event) => {
                  setOtpValue([
                    otpValue[0],
                    otpValue[1],
                    otpValue[2],
                    event.target.value,
                  ]);
                  if (!event.target.value) {
                    document.getElementById("otp-field-3").focus();
                  }
                }}
                id="otp-field-4"
              />
              <br />
              <button
                type="submit"
                className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
              >
                Submit OTP
              </button>
            </form>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SignInScreen;
