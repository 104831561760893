/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useReducer, useState, useEffect } from 'react';
import '../index.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../LoadingError/Utils';
import { Store } from '../Store';
import { Modal, Box, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCHING':
      return { ...state, loading: true };
    case 'FETCHED':
      return { ...state, verifiedList: action.payload, loading: false };
    case 'FETCH_FAILED':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


const AdminVerifiedScreen = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [showImgModal, setShowImgModal] = useState(false);
  const [userImgId, setUserImgId] = useState(null);
  const [userImgUrl, setUserImgUrl] = useState(null);
  const navigate = useNavigate();

  const [{ verifiedList }, dispatch] = useReducer(reducer, {
    verifiedList: [],
    loading: true,
    error: '',
  });

  

  const handleAllow = async (userId) => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/verifyUser`,
          {
            request_id: userId,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        )
        .then((getResponse) => {
          toast.success('User Verified Request Allowed!');
        });
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const handleDisallow = (userId) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/rejectUserRequest`,
          {
            request_id: userId,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        )
        .then((getResponse) => {
          toast.success('User Verified Request Rejected!');
        });
    } catch (error) {
      toast.error(getError(error));
    }
  };

  useEffect(async () => {
    if (userInfo === null) {
      navigate("/");
      return;
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getUserRequest`, {
        headers: { Authorization: `Bearer ${userInfo.data.token}` },
      });
      dispatch({
        type: 'FETCHED',
        payload: data.data,
      });
    } catch (error) {
      toast.error(getError(error));
      navigate("/")
    }
  }, []);

  const handleImageClick = (imageUrl, userId) => {
    setShowImgModal(true);
    setUserImgUrl(imageUrl);
    setUserImgId(userId);
  };

  const closeImgModal = () => {
    setShowImgModal(false);
  };

  return (
    <>
      <div className="mt-14">
        <Modal
          open={showImgModal}
          onClose={closeImgModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: '80%',
              maxHeight: '80%',
              textAlign: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={closeImgModal}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              Close
            </Button>
            <img
              src={userImgUrl}
              alt={`User ${userImgId} picture`}
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Modal>
        <div className="card">
          <p className="admin-page-title text-center">Member's Verified List</p>
          <div className="admin-table-container">
            <table className="user-table">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>User ID</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Follower Number</th>
                  <th>Verified Status</th>
                  <th>Image</th>
                  <th colSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {verifiedList.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.user_id}</td>
                    <td>{user.name}</td>
                    <td>{user.username ? '@' + user.username : 'No Username'}</td>
                    <td>{user.follower_count}</td>
                    <td>{user.verified ? 'Verified' : 'Not Verified'}</td>
                    <td>
                      <img
                        src={user.image}
                        alt={`User ${user.imagewIDCard}`}
                        style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                        onClick={() => handleImageClick(user.image, user.user_id)}
                      />
                    </td>
                    <td>
                      <button onClick={() => handleAllow(user.id)} disabled={user.verified}>
                        Allow
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => handleDisallow(user.id)}
                        disabled={!user.verified}
                        style={{
                          backgroundColor: user.verified ? 'red' : 'grey',
                          color: 'white',
                          border: 'none',
                          padding: '10px 20px',
                          borderRadius: '4px',
                          cursor: user.verified ? 'pointer' : 'default',
                        }}
                      >
                        Disallow
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminVerifiedScreen;
