/* eslint-disable no-self-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Store } from "../Store";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Maps from "../Components/Maps";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import LoadingBox from "../LoadingError/LoadingBox";
import MessageBox from "../LoadingError/MessageBox";
import { FormControlLabel, Switch } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { PhotoIcon } from "@heroicons/react/24/solid";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { alpha, styled } from "@mui/material/styles";

const customStylesSelect = {
  option: (provided) => ({
    ...provided,
    ":active": {
      ...provided[":active"],
      backgroundColor: "#FFE4CC",
    },
  }),
};

const mutedInputStyle = {
  backgroundColor: "#e5e7eb",
  color: "#6b7280",
  borderColor: "#d1d5db",
  width: "100%",
  padding: "8px 12px",
  borderRadius: "0.375rem",
  outline: "none",
};

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#F95C3D",
    "&:hover": {
      backgroundColor: alpha("#F95C3D", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#F95C3D",
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCategory: true };
    case "FETCHED":
      return { ...state, optionsCategory: action.payload, loading: false };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCategory: false,
        errorCategory: action.payload,
      };
    default:
      return state;
  }
};

const reducer4 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCurrency: true };
    case "FETCHED":
      return {
        ...state,
        currencyList: action.payload,
        loadingCurrency: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCurrency: false,
        errorCurrency: action.payload,
      };
    default:
      return state;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90%",
  bgcolor: "background.paper",
  border: "0.1px solid gainsboro",
  borderRadius: "1rem",
  boxShadow: "1px 2px 1px gainsboro",
  p: 4,
};

function UpdatePostScreen() {
  const loc = useLocation();
  const post = loc && loc.state ? loc.state.post : null;
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState(post && post.posttitle);
  const [price, setPrice] = useState(post && post.price);
  const [currency, setCurrency] = useState(null);

  const [category, setCategory] = useState(post && post.category);
  const [sdType, setSdType] = useState(post && post.sd_post_type);
  const [psType, setPsType] = useState(post && post.post_type);

  const [isShowNameError, setIsShowNameError] = useState(false);

  const options = [
    { value: "1", label: "I wanna share something (Share)" },
    { value: "2", label: "I need something (Seek)" },
  ];

  const options2 = [
    {
      value: "1",
      label: sdType && sdType.value === "1"
        ? <>Product <span className="text-gray-400">- I want to offer product</span></>
        : <>Product <span className="text-gray-400">- I want to seek a product</span></>
    },
    {
      value: "2",
      label: sdType && sdType.value === "1"
        ? <>Service <span className="text-gray-400">- I want to offer service</span></>
        : <>Service <span className="text-gray-400">- I want to seek a service</span></>
    },
    {
      value: "3",
      label: sdType && sdType.value === "1"
        ? <>Event <span className="text-gray-400">- I want to share event</span></>
        : <>Event <span className="text-gray-400">- I want to seek an event</span></>
    },
    {
      value: "4",
      label: sdType && sdType.value === "1"
        ? <>Job <span className="text-gray-400">- I want to offer a job</span></>
        : <>Job <span className="text-gray-400">- I need to find a job</span></>
    },
    {
      value: "5",
      label: sdType && sdType.value === "1"
        ? <>Insight <span className="text-gray-400">- I want to share some insight/review</span></>
        : <>Insight <span className="text-gray-400">- I need some insight/review</span></>
    }

  ];

  //category
  const [isDisabledCategory, setIsDisabledCategory] = useState(false);

  // tags
  const inputRef = useRef(null);
  const [tags, setTags] = useState(post && post.tag);
  const [tagInput, setTagInput] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  // description
  const [description, setDescription] = useState(post && post.description);

  //contact
  const [mySocialMedia, setMySocialMedia] = useState([]);
  const [buttonMySocialMedia, setButtonMySocialMedia] = useState(
    post && post.contact_ref
  );

  //image files
  const [selectedFiles, setSelectedFiles] = useState(
    [].concat(post && post.images)
  );
  const maxImageSize = 10 * 1024 * 1024;
  const [errorMessage, setErrorMessage] = useState("");

  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);

  const [isLoading] = useState(false);

  const [isShowErrorSocialMedia, setIsShowErrorSocialMedia] = useState(false);

  const [socialMediaOptions, setSocialMediaOptions] = useState(true);

  // coordinate and location
  const [location, setLocation] = useState(post && post.location);
  const [coordinateLocation, setCoordinateLocation] = useState({
    lat: post && post.lat,
    lng: post && post.long,
  });

  const [isShowErrorCoordinateLocation, setIsShowErrorCoordinateLocation] =
    useState(false);

  const [showMapsModal, setShowMapsModal] = useState(false);
  const targetRef = useRef(null);

  const [{ loadingUpdate }, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
  });

  // ps
  if (post && post.sd_post_type === 1) {
    var defaultValueSdType = { value: 1, label: "Share" };
  } else {
    defaultValueSdType = { value: 2, label: "Seek" };
  }

  // type
  if (post && post.post_type === 1) {
    var defaultValuePsType = { value: 1, label: "Product" };
  } else if (post && post.post_type === 2) {
    defaultValuePsType = { value: 2, label: "Service" };
  } else if (post && post.post_type === 3) {
    defaultValuePsType = { value: 3, label: "Event" };
  } else if (post && post.post_type === 4) {
    defaultValuePsType = { value: 4, label: "Job" };
  } else {
    defaultValuePsType = { value: 5, label: "Insight" };
  }

  const [{ loading, error, dataUser }, dispatch2] = useReducer(reducer2, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const [{ loadingCategory, optionsCategory }, dispatch3] = useReducer(
    reducer3,
    {
      optionsCategory: [],
      loadingCategory: true,
      errorCategory: "",
    }
  );

  const [{ currencyList, loadingCurrency }, dispatch4] = useReducer(reducer4, {
    currencyList: [],
    loadingCurrency: true,
    errorCurrency: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo === null) {
        navigate("/");
        return;
      }

      dispatch2({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch2({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch2({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };

    fetchData();
  }, [navigate, userInfo, dispatch2]);

  useEffect(() => {
    if (post === null) {
      navigate("/");
      return;
    }

    let psTypeWord = psType && psType.value ? psType.value : psType;

    const fetchData = async () => {
      dispatch3({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getCategoryOptions?psType=${psTypeWord}`,

          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch3({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch3({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };
    fetchData();
  }, [psType]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch4({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getCurrencyOptions`,

          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch4({
          type: "FETCHED",
          payload: data.data,
        });

      } catch (error) {
        dispatch4({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };
    fetchData();
  }, []);

  const toggleMySocialMedia = () => {
    if (dataUser.length != 0) {
      const userSocialMedia = dataUser.contact.map((item) => ({
        value: item.contact_id.toString(),
        label: item.contact_name,
        image: item.contact_image,
        link: item.link,
      }));

      setMySocialMedia(userSocialMedia);
      setButtonMySocialMedia(!buttonMySocialMedia);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (coordinateLocation === null) {
      setIsShowErrorCoordinateLocation(true);
      const element = targetRef.current;
      const topOffset = element.offsetTop;
      const elementHeight = element.offsetHeight;
      const windowHeight = window.innerHeight;

      const scrollTo = topOffset - windowHeight / 2 + elementHeight / 2;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
      return;
    } else {
      setIsShowErrorCoordinateLocation(false);
    }

    // if (sdType.value === 1 || sdType === 1) {
    //   if (selectedFiles.length === 0) {
    //     setErrorMessage("Supplier needs to have at least 1 image!");
    //     return;
    //   }
    // }

    let sdTypeValue = sdType.value || sdType;
    let psTypeValue = psType.value || psType;
    let categoryValue = category.value || category;
    let currencyValue = currency && currency.value ? currency.value : null;
    let tagsNew = Object.assign({}, ...tags);
    tagsNew = JSON.stringify(tagsNew);

    try {
      dispatch({
        type: "UPDATE_REQUEST",
      });

      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/updatePost/${post.id}`,
        {
          name: name,
          category: categoryValue,
          sd_type_post: sdTypeValue,
          type_post: psTypeValue,
          location: location,
          description: description,
          contactRef: buttonMySocialMedia,
          lat:
            coordinateLocation.lat !== undefined
              ? coordinateLocation.lat
              : post.lat,
          long:
            coordinateLocation.lng !== undefined
              ? coordinateLocation.lng
              : post.long,
          price: price,
          currency: currencyValue,
          tag: tagsNew,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      dispatch({
        type: "FETCHED",
        payload: data.data,
      });

      let postId = post.id;

      dispatch({
        type: "UPDATE_SUCCESS",
      });

      // if (selectedFiles.length > 0) {
        const formData = new FormData();

        //cleansing oldData
        const remainingFiles = selectedFiles.filter(
          (file) => typeof file === "string"
        );
        const newFiles = selectedFiles.filter(
          (file) => typeof file !== "string"
        );

        for (let i = 0; i < newFiles.length; i++) {
          formData.append("files", newFiles[i]);
        }

        formData.append("postId", postId);

        if (remainingFiles) {
          formData.append("remainingFiles", remainingFiles);
        }
        
        formData.append("status", "update");

        await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/uploadPostImage`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userInfo.data.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      // }
      toast.success("Post updated successfully!");
      navigate("/");
    } catch (err) {
      dispatch({
        type: "UPDATE_FAIL",
      });
      toast.error(getError(err));
    }
  };

  function SetUsername() {
    if (dataUser.username === null) {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {dataUser.name}
        </p>
      );
    } else {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {dataUser.username}
        </p>
      );
    }
  }

  const imageChange = (e) => {
    const files = e.target.files;
    const newSelectedFiles = [];
    let newErrorMessage = "";

    if (files.length <= 10) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (
          file &&
          file.type.startsWith("image/") &&
          file.size <= maxImageSize
        ) {
          newSelectedFiles.push(file);
        } else if (!file.type.startsWith("image/")) {
          newErrorMessage = `Please select an image file`;
        } else if (file.size > maxImageSize) {
          newErrorMessage = `Image ${file.name} exceeds the maximum size limit of 10MB. Please select smaller images`;
        }
      }
    } else {
      newErrorMessage = `The number of images exceeds the maximum limit of 10`;
    }

    if (newErrorMessage) {
      // If there is an error, prevent further processing and display the error message.
      setErrorMessage(newErrorMessage);
    } else {
      // If no errors, proceed to set the selected files.
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
      setErrorMessage(""); // Clear any previous error messages
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const newSelectedFiles = [];
    let newErrorMessage = "";

    if (files.length <= 10) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (
          file &&
          file.type.startsWith("image/") &&
          file.size <= maxImageSize
        ) {
          newSelectedFiles.push(file);
        } else if (!file.type.startsWith("image/")) {
          newErrorMessage = `Please select an image file`;
        } else if (file.size > maxImageSize) {
          newErrorMessage = `Image ${file.name} exceeds the maximum size limit of 10MB. Please select smaller images`;
        }
      }
    } else {
      newErrorMessage = `The number of images exceeds the maximum limit of 10`;
    }

    if (newErrorMessage) {
      // If there is an error, prevent further processing and display the error message.
      setErrorMessage(newErrorMessage);
    } else {
      // If no errors, proceed to set the selected files.
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
      setErrorMessage(""); // Clear any previous error messages
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeSelectedImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="mb-3 mt-3"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }

  function ProfilePicture() {
    return (
      <img className="mb-3 mt-3" src={dataUser.picture} alt="profilepicture" />
    );
  }

   // Title

   const handleNameChange = (e) => {
    const input = e.target.value;
    const wordCount = input.trim().split(/\s+/).length;

    if (wordCount <= 6) {
      setIsShowNameError(false);
      setName(input);
    } else {
      setIsShowNameError(true);
    }
  };

  function handleOpenMapsModal() {
    setShowMapsModal(true);
  }

  function handleCloseMapsModal() {
    setShowMapsModal(false);
  }

  const handlePositionFromMaps = (data, province) => {
    setLocation(province);
    setCoordinateLocation({ lat: data.lat, lng: data.lng });
  };

  const closeMapsModal = () => {
    setShowMapsModal(false);
  };

  // Tags

  const addTag = () => {
    if (tagInput.trim() !== "" && (tags.length < 3 || showWarning)) {
      setTags([...tags, { [tags.length]: tagInput }]);
      setTagInput("");
      if (tags.length >= 3) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    } else if (tags.length === 3) {
      setShowWarning(true);
    }
  };

  const removeTag = (key) => {
    setShowWarning(false);
    setTags(tags.filter((tag) => Object.keys(tag)[0] !== key));
  };

  useEffect(() => {
    if (!post) {
      return;
    }

    const newArray = Object.entries(post.tag).map(([key, value]) => ({
      [key]: value,
    }));

    setTags(newArray);
  }, [post && post.tag]);

  useEffect(() => {
    if (!post) {
      return;
    }
    console.log("PAMOR",post.images)
    setSelectedFiles([].concat(post.images));
  }, [post && post.images]);

  useEffect(() => {
    if (!currencyList && !post) {
      return;
    }

    // Find the currency object t hat matches post.currency value
    const defaultCurrency = currencyList.find(
      (cur) => cur.label === post.abbreviation
    );
    setCurrency(defaultCurrency);
  }, [post && post.currency, currencyList]);

  useEffect(() => {
    if (post === null) {
      navigate("/");
    }
  }, [post]);

  return (
    <>
      {loading && loadingCategory ? (
        <div className="flex justify-center pt-16 ">
          <LoadingBox />
        </div>
      ) : error ? (
        <MessageBox severity="error">{error}</MessageBox>
      ) : (
        <div className="w-full flex items-center justify-center">
          <Helmet>
            <title>Update Post | SiapHub</title>
          </Helmet>

          <div className="cardFlex xs:w-screen-90">
            <div className="flex justify-center">
              <h1 className="text-center" style={{ fontSize: "2em" }}>
                Update Post
              </h1>
            </div>
            <form className="w-full rounded-lg" onSubmit={submitHandler}>
              {/* <div className="profileContainer flex">
                <div
                  className="flex mr-8"
                  style={{
                    width: "1.5em",
                    marginRight: "2%",
                  }}
                >
                  <SetProfilePicture />
                </div>
                <div className="flex items-center">
                  <SetUsername />
                </div>
              </div> */}

              {/* Title */}
              <p className="text-gray-400 text-sm" style={{ marginTop: "5%" }}>
                All fields with asterisk are mandatory
              </p>
              <div className="flex items-center">
                <Tooltip title="Title of the post" className="cursor-pointer">
                  <label className="font-bold" style={{ width: "40%" }}>
                    Title <span style={{ color: "red" }}>*</span>
                  </label>
                </Tooltip>
                <input
                  type="text"
                  className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                  value={name}
                  placeholder="Title"
                  onChange={handleNameChange}
                  required
                />
              </div>
              {isShowNameError && (
                <div className="text-red-500 text-sm mt-1">
                  *Title cannot exceed 6 words!
                </div>
              )}
              {/* Share Seek */}
              <div className="flex items-center" style={{ marginTop: "5%" }}><Tooltip
                title="The purpose of the post"
                className="cursor-pointer"
              >
                <label className="font-bold" style={{ width: "40%" }}>
                  Share or Seek <span style={{ color: "red" }}>*</span>
                </label>
              </Tooltip>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={defaultValueSdType}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFE4CC",
                      primary: "#F95C3D",
                    },
                  })}
                  styles={customStylesSelect}
                  name="sdType"
                  options={options}
                  onChange={(selectedOption) => {
                    setSdType(selectedOption);
                  }}
                  required
                />
              </div>

              {/* Product Service */}
              <div className="flex items-center" style={{ marginTop: "5%" }}>
                <Tooltip title="Type of the post" className="cursor-pointer">
                  <label className="font-bold" style={{ width: "40%" }}>
                    Post Type <span style={{ color: "red" }}>*</span>
                  </label>
                </Tooltip>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={defaultValuePsType}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFE4CC",
                      primary: "#F95C3D",
                    },
                  })}
                  styles={customStylesSelect}
                  name="psType"
                  options={options2}
                  onChange={(selectedOption) => {
                    if (selectedOption === null) {
                      setIsDisabledCategory(true);
                      setPsType(1);
                    } else {
                      setPsType(selectedOption);
                      setIsDisabledCategory(false);
                    }
                  }}
                  required
                />
              </div>

              {/* Category */}
              <div className="flex items-center" style={{ marginTop: "5%" }}>
                <Tooltip
                  title="Category of the post"
                  className="cursor-pointer"
                >
                  <label className="font-bold" style={{ width: "40%" }}>
                    Category <span style={{ color: "red" }}>*</span>
                  </label>
                </Tooltip>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={isDisabledCategory}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFE4CC",
                      primary: "#F95C3D",
                    },
                  })}
                  styles={customStylesSelect}
                  name="category"
                  options={optionsCategory}
                  defaultValue={category}
                  onChange={(selectedOption) => {
                    setCategory(selectedOption);
                  }}
                  required
                />
              </div>

              {/* Coordinate */}
              <div
                className="flex items-center"
                style={{ marginTop: "5%", justifyContent: "space-between" }}
                ref={targetRef}
              >
                <Tooltip
                  title="The location of the post (optional)"
                  className="cursor-pointer"
                >
                  <label className="font-bold" style={{ width: "40%" }}>
                    Coordinate Location
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                </Tooltip>
                <div onClick={handleOpenMapsModal}>
                  <Tooltip title="Choose Location">
                    <div
                      className="
                    border
                    rounded
                    px-3
                    text-gray-700
                    focus:outline-none
                  "
                      style={{
                        paddingBottom: "0.3em",
                        paddingTop: "0.3em",
                        cursor: "pointer",
                      }}
                    >
                      {coordinateLocation === null ? (
                        <LocationOnOutlinedIcon />
                      ) : (
                        <LocationOnOutlinedIcon style={{ color: "#00FF00" }} />
                      )}
                    </div>
                  </Tooltip>
                </div>

                <Modal
                  open={showMapsModal}
                  onClose={handleCloseMapsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Maps
                      sendData={handlePositionFromMaps}
                      closeModal={closeMapsModal}
                    />
                  </Box>
                </Modal>
              </div>

              {isShowErrorCoordinateLocation && coordinateLocation === null ? (
                <div
                  className="
                    text-red-500
                    text-sm
                  "
                >
                  *This field is required!
                </div>
              ) : (
                ""
              )}

              {/* Province Location */}
              <div
                className="flex flex-row justify-between items-center"
                style={{ marginTop: "5%" }}
              >
                <Tooltip
                  title="The location of the post (optional)"
                  className="cursor-pointer"
                >
                  <label className="font-bold" style={{ width: "40%" }}>Location</label>
                </Tooltip>
                {location ? (
                  <div className="flex-end">{location}</div>
                ) : (
                  <div className="flex-end">Pick a coordinate</div>
                )}
              </div>

              {/* Price */}
              {psType && psType.value === 5 ? (
                ""
              ) : (
                <div className="flex items-center" style={{ marginTop: "5%" }}>
                  <Tooltip
                    title="The price of the post (product/event tickets/etc) (optional)"
                    className="cursor-pointer"
                  >
                    <label className="font-bold" style={{ width: "40%" }}>Price</label>
                  </Tooltip>

                  <div className="flex w-full">
                    <div className="w-2/5">
                      <Select
                        className="basic-single "
                        classNamePrefix="select"
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#FFE4CC",
                            primary: "#F95C3D",
                          },
                        })}
                        styles={customStylesSelect}
                        name="currency"
                        options={currencyList}
                        value={currency}
                        onChange={setCurrency}
                      />
                    </div>

                    <div className="flex-grow pl-3">
                      <input
                        type="number"
                        className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                        placeholder="Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Description */}
              <div className="flex" style={{ marginTop: "5%" }}>
                <Tooltip
                  title="The description of the post"
                  className="cursor-pointer"
                >

                  <label className="py-2 font-bold" style={{ width: "40%" }}>
                    Description <span style={{ color: "red" }}>*</span>
                  </label>
                </Tooltip>
                <textarea
                  className="w-full border rounded px-3 py-2 focus:outline-none"
                  style={{ resize: "none", height: "120px" }}
                  value={description}
                  placeholder="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>

              {/* Social Media */}
              <hr className="mt-5" />

              <div className="flex items-center justify-between mt-5">
                <Tooltip
                  title="The contacts to show for the post (optional)"
                  className="cursor-pointer"
                >
                  <label className="font-bold" style={{ width: "50%" }}>Social Media</label>
                </Tooltip>

                <FormControlLabel
                  control={
                    <OrangeSwitch
                      checked={buttonMySocialMedia}
                      onChange={() => toggleMySocialMedia()}
                      disabled={mySocialMedia.length === 0}
                      className={`${mySocialMedia.length === 0
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                        }`}
                    />
                  }
                />
              </div>
              <div className="text-gray-400 text-sm">
                Show my social media contacts on this post
              </div>

              {mySocialMedia.length === 0 ? (
                <div>
                  <p className="text-gray-400 text-sm">
                    You don't have a contact, please add contacts in update
                    profile page to show your social media in this post
                  </p>
                  <div
                    onClick={() => navigate("/update_profile")}
                    className="flex justify-end text-[#F95C3D] text-sm cursor-pointer"
                  >
                    Go to Update Profile Page
                    <ArrowOutwardIcon style={{ fontSize: "medium" }} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="my-5">
                {buttonMySocialMedia
                  ? mySocialMedia.map((selectedOption) => (
                    <div
                      className="flex flex-row mt-2 items-center"
                      key={selectedOption.value}
                    >
                      <label style={{ width: "40%" }}>
                        {selectedOption.label}
                      </label>
                      <input
                        type="text"
                        required
                        value={selectedOption.link}
                        className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                      />
                    </div>
                  ))
                  : ""}
              </div>

              {isShowErrorSocialMedia ? (
                <div
                  className="
                    text-red-500
                    text-sm
                  "
                >
                  *This field is required!
                </div>
              ) : (
                ""
              )}

              {/* Tags */}

              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <Tooltip
                    title="The hastags for the post (optional)"
                    className="cursor-pointer"
                  >
                    <label className="font-bold" style={{ width: "50%" }}>
                      Tags{" "}
                    </label>
                  </Tooltip>
                  <div className="border-l border-t border-b rounded-l-lg px-3 py-2 text-gray-700 focus:outline-none">
                    #
                  </div>
                  <input
                    className="w-full border rounded-r-lg px-3 py-2 focus:outline-none"
                    type="text"
                    placeholder="Tags"
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    ref={inputRef}
                  />
                  <Tooltip title="Add Tag">
                    <button
                      type="button"
                      className="rounded-lg ml-2 hover:bg-[#F5F5F5]"
                      onClick={addTag}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="scale-75"
                      >
                        <path
                          d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                          fill="black"
                        />
                        <path
                          d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                          fill="black"
                        />
                        <path
                          d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </div>
                {showWarning && (
                  <p style={{ color: "red" }}>
                    You can add a maximum of 4 tags
                  </p>
                )}
                <div className="flex flex-col">
                  {Array.isArray(tags) &&
                    tags.map((tag, index) => (
                      <div
                        className="flex my-2 items-center justify-end"
                        key={index}
                      >
                        <span className="rounded-lg bg-[#F95C3D] text-white ml-2 p-1">
                          #{tag}
                        </span>
                        <Tooltip title="Remove Tag">
                          <button
                            className="rounded-lg ml-2 hover:bg-[#F5F5F5]"
                            onClick={() => removeTag(index)}
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="scale-75"
                            >
                              <path
                                d="M9.69965 13.9199H20.2996C20.5808 13.9199 20.8504 14.0337 21.0492 14.2362C21.248 14.4388 21.3596 14.7135 21.3596 14.9999C21.3596 15.2864 21.248 15.5611 21.0492 15.7636C20.8504 15.9661 20.5808 16.0799 20.2996 16.0799H9.69965C9.41852 16.0799 9.1489 15.9661 8.95012 15.7636C8.75133 15.5611 8.63965 15.2864 8.63965 14.9999C8.63965 14.7135 8.75133 14.4388 8.95012 14.2362C9.1489 14.0337 9.41852 13.9199 9.69965 13.9199Z"
                                fill="black"
                              />
                              <path
                                d="M15 27.8571C16.6884 27.8571 18.3603 27.5246 19.9202 26.8784C21.4801 26.2323 22.8975 25.2853 24.0914 24.0914C25.2853 22.8975 26.2323 21.4801 26.8785 19.9202C27.5246 18.3603 27.8571 16.6884 27.8571 15C27.8571 13.3116 27.5246 11.6397 26.8785 10.0798C26.2323 8.51988 25.2853 7.10252 24.0914 5.90863C22.8975 4.71473 21.4801 3.76768 19.9202 3.12155C18.3603 2.47542 16.6884 2.14286 15 2.14286C11.5901 2.14286 8.31981 3.49744 5.90863 5.90863C3.49744 8.31981 2.14286 11.5901 2.14286 15C2.14286 18.4099 3.49744 21.6802 5.90863 24.0914C8.31981 26.5026 11.5901 27.8571 15 27.8571ZM15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30Z"
                                fill="black"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                </div>
              </div>

              {/* Choose Multiple Image */}
              <div style={{ marginTop: "5%" }}>
                <Tooltip
                  title="The image for the post (optional)"
                  className="cursor-pointer"
                >
                  <label className="flex font-bold" style={{ width: "50%" }}>
                    Images:
                    <div className="flex text-gray-400 text-sm items-center ml-2">
                      {/* (Optional) */}
                    </div>
                  </label>
                </Tooltip>
              </div>
              <div className="flex">
                {selectedFiles.length > 0 ? (
                  <div
                    className="w-full mt-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                    id="file-upload-2"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    {selectedFiles.length < 5 && (
                      <div className="text-center">
                        <PhotoIcon
                          aria-hidden="true"
                          className="mx-auto h-12 w-12 text-gray-300"
                        />
                        <div className="mt-4 flex justify-center text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-lg bg-white font-semibold text-[#F95C3D]"
                          >
                            <span>Upload your images</span>
                            <input
                              id="file-upload"
                              accept="image/*"
                              type="file"
                              className="sr-only"
                              onChange={imageChange}
                              style={{ marginBottom: "5%" }}
                              required={
                                sdType === 1 && selectedFiles.length === 0
                              }
                              multiple
                            />
                          </label>
                          <p className="pl-1">or drag images here</p>
                        </div>
                        <p className="text-xs text-gray-600">
                          PNG, JPG up to 10MB (per image)
                        </p>
                      </div>
                    )}
                    {selectedFiles.map((file, index) => (
                      <div className="flex justify-center" key={index}>
                        {file ? (
                          <img
                            className="w-80 h-40 object-contain"
                            src={
                              file instanceof Blob
                                ? window.URL.createObjectURL(file)
                                : file
                            }
                            alt={`postImage-${index}`}
                          />
                        ) : (
                          ""
                        )}

                        <div className="flex justify-center">
                          <div className="flex items-center">
                            <Tooltip
                              className="cursor-pointer rounded-lg hover:bg-[#F5F5F5]"
                              onClick={() => removeSelectedImage(index)}
                              title="Remove Image"
                            >
                              <CloseIcon className="w-3" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                    {errorMessage && (
                      <div className="flex justify-center text-red-500 text-sm">
                        *{errorMessage}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="w-full mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                    id="file-upload-2"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <div className="text-center">
                      <PhotoIcon
                        aria-hidden="true"
                        className="mx-auto h-12 w-12 text-gray-300"
                      />
                      <div className="mt-4 flex justify-center text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-lg bg-white font-semibold text-[#F95C3D]"
                        >
                          <span>Upload your images</span>
                          <input
                            id="file-upload"
                            accept="image/*"
                            type="file"
                            className="sr-only"
                            onChange={imageChange}
                            style={{ marginBottom: "5%" }}
                            required={
                              sdType === 1 && selectedFiles.length === 0
                            }
                            multiple
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-600">
                        PNG, JPG up to 10MB (per image)
                      </p>
                      {errorMessage && (
                        <div className="flex justify-center text-red-500 text-sm">
                          *{errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Submit */}
              <div className="flex justify-center mt-5">
                {loadingUpdate ? (
                  <button
                    type="submit"
                    className="
                    flex
                    justify-center
                    w-full
                    py-2 px-4
                    rounded-lg
                    focus:outline-none
                    hover:cursor-progress
                  "
                    disabled
                    style={{
                      background: "#F95C3D",
                      color: "white",
                    }}
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Posting...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="
                    w-full
                    py-2 px-4
                    rounded-lg
                    focus:outline-none
                    bg-[#F95C3D]
                    text-white
                    hover:bg-orange-600
                  "
                    disabled={loadingUpdate}
                  >
                    Post
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default UpdatePostScreen;
