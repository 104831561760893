import axios from "axios";
import  { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useContext, useEffect } from "react";
import { Store } from "./Store";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
  messagingSenderId:`${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
  appId:`${process.env.REACT_APP_APP_ID}`,
  measurementId:`${process.env.REACT_APP_MEASUREMENT_ID}`,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

export const getTokenFCM = async (userToken) => {
  try {
    const token = await getToken(messaging, {vapidKey: `${process.env.REACT_APP_VAPIDKEY}`})
    return sendTokenToBackend(token, userToken);
  } catch (error) {
    console.error("Error getting FCM token:", error);
    return null;
  }
};

// Custom Hook to get the userId from the context
const useUserInfo = () => {
  const { state } = useContext(Store);
  return state.userInfo.data.token;
};

const handleTokenRefresh = async () => {
  try {
    const refreshedToken = await getToken(messaging, {vapidKey: `${process.env.REACT_APP_VAPIDKEY}`})
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userToken = useUserInfo(); // Use the custom Hook to get the userId
    if (!userToken) {
      console.error("User ID not found.");
      return;
    }
    // Send the refreshed token and userId to your backend
    await sendTokenToBackend(refreshedToken, userToken);
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
};

// Handle token refresh
// messaging.onTokenRefresh(handleTokenRefresh);

const sendTokenToBackend = async (token, userToken) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/fcmUpdate`,
      { token },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    
    return data;
    
  } catch (error) {

    return error;
  }
};





export default app;

