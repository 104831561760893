/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useReducer, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Store } from "../Store";
import LoadingBox from "../LoadingError/LoadingBox";
import MessageBox from "../LoadingError/MessageBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const UpdatePasswordScreen = () => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowErrorPassword, setIsShowErrorPassword] = useState(false);

  const [, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
  });
  const [{ loading, error, dataUser }, dispatch2] = useReducer(reducer2, {
    dataUser: [],
    loading: true,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch2({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch2({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch2({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };

    fetchData();
  }, [dispatch2, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
        setIsShowErrorPassword(true);
        return;
      }

    dispatch({
      type: "UPDATE_REQUEST",
    });
    
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/updateProfile`,
        {
            password: oldPassword,
            newPassword: newPassword,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      toast.success("User updated successfully");
    } catch (err) {
      dispatch({
        type: "UPDATE_FAILED",
      });
      toast.error(getError(err));
    }
  };

  console.log("DATA USER", dataUser);
  
  const handleNewPassword = async (e) => {
    if (e !== confirmPassword && confirmPassword !== "") {
      setIsShowErrorPassword(true);
      setNewPassword(e);
    } else {
      setIsShowErrorPassword(false);
      setNewPassword(e);
    }
  };

  const handleConfirmPassword = async (e) => {
    if (e !== newPassword && newPassword !== "") {
      setIsShowErrorPassword(true);
      setConfirmPassword(e);
    } else {
      setIsShowErrorPassword(false);
      setConfirmPassword(e);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center pt-6 ">
          <LoadingBox />
        </div>
      ) : error ? (
        <MessageBox severity="error">{error}</MessageBox>
      ) : (
        <div className="w-full flex items-center justify-center">
          <Helmet>
            <title>Change Password | SiapHub</title>
          </Helmet>
          <div className="cardFlex xs:w-screen-90">
            <div className="flex justify-center">
              <h1 className="text-center mb-8" style={{ fontSize: "2em" }}>
                Update Password
              </h1>
            </div>
            <form className="w-full rounded-lg" onSubmit={submitHandler}>
              
              <div className="pb-10">
                <div className="w-full mb-2">
                  <div className="flex items-center ">
                    <label style={{ width: "45%" }}>
                      E-mail
                    </label>
                    <div
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-700
                        focus:outline-none
                      "
                    >
                      {dataUser.email}
                    </div>
                  </div>
                </div>
                
                <div className="w-full mb-2">
                  <div className="flex items-center">
                    <label style={{ width: "45%" }}>
                     Old Password
                    </label>
                    <input
                      type="password"
                      placeholder="Old Password"
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="w-full mb-2">
                  <div className="flex items-center">
                    <label style={{ width: "45%" }}>
                      New Password
                    </label>
                    <input
                      type="password"
                      placeholder="New Password"
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                      onChange={(e) => handleNewPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="w-full mb-2">
                  <div className="flex items-center">
                    <label style={{ width: "45%" }}>
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                      onChange={(e) => handleConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  {isShowErrorPassword ? (
                    <div
                      className="
                        text-gray-700
                        focus:outline-none
                      "
                      style={{ fontSize: "0.8rem", color: "red" }}
                    >
                      *Password doesn't match with new password!
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="
                    w-full
                    py-2 px-4
                    rounded-lg
                    bg-[#F95C3D]
                    text-white
                    hover:bg-orange-600
                  "
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdatePasswordScreen;
