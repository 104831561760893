/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useContext, useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const statusString = location.state.string;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const [{ dataUser }, dispatch] = useReducer(reducer, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo !== null) {
        dispatch({
          type: "FETCHING",
        });

        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
            {
              headers: { Authorization: `Bearer ${userInfo.data.token}` },
            }
          );

          dispatch({
            type: "FETCHED",
            payload: data.data,
          });
        } catch (error) {
          dispatch({
            type: "FETCH_FAILED",
            payload: error.message,
          });

          if (error.response && error.response.status === 401) {
            // Assuming you have a signoutHandler function
            signoutHandler();
            navigate("/signin");
          } else {
            toast.error(getError(error));
          }
        }
      }
    };

    fetchData();
  }, [userInfo]);

  const handleClickNext = () => {
    navigate("/");
  };


  return (
    <>
      {statusString === "signup" ? (
        <div>
          <div className="min-h-screen bg-[#FFE4CC] text-gray-900 flex justify-center items-center">
            <div>
              <img
                src="../../images/siaphub_logo.png"
                alt="Logo"
                className="w-56 h-56 mx-auto"
              />
              <div className="text-[#923B2A] font-bold text-3xl text-center">
                Hi, {dataUser.name}!
              </div>
              <div className="text-[#923B2A] text-2xl text-center">
                Welcome to SiapHub.
              </div>
              <div className="flex justify-center mt-8">
                <button
                  type="submit"
                  className="
                    py-2 px-4
                    rounded-lg
                    focus:outline-none
                  "
                  onClick={handleClickNext}
                  style={{
                    background: "#F95C3D",
                    color: "white",
                  }}
                >
                  Go to Home Page
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="min-h-screen bg-[#FFE4CC] text-gray-900 flex justify-center items-center">
            <div>
              <img
                src="../../images/siaphub_logo.png"
                alt="Logo"
                className="w-56 h-56 mx-auto"
              />
              <div className="text-[#923B2A] font-bold text-3xl text-center">
                Hi, {dataUser.name}!
              </div>
              <div className="text-[#923B2A] text-2xl text-center">
                Welcome back to SiapHub.
              </div>
              <div className="flex justify-center mt-8">
                <button
                  type="submit"
                  className="
                    py-2 px-4
                    rounded-lg
                    focus:outline-none
                  "
                  onClick={handleClickNext}
                  style={{
                    background: "#F95C3D",
                    color: "white",
                  }}
                >
                  Go to Home Page
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeScreen;
