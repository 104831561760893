/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, {
  useContext,
  useReducer,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Store } from "../Store";
import Select from "react-select";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import LoadingBox from "../LoadingError/LoadingBox";
import MessageBox from "../LoadingError/MessageBox";
import { useNavigate } from "react-router-dom";
import { debounce } from "debounce";

const customStylesSelect = {
  option: (provided) => ({
    ...provided,
    ":active": {
      ...provided[":active"],
      backgroundColor: "#FFE4CC",
    },
  }),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingListSm: true };
    case "FETCHED":
      return { ...state, listContactSm: action.payload, loadingListSm: false };
    case "FETCH_FAILED":
      return { ...state, loadingListSm: false, errorListSm: action.payload };
    default:
      return state;
  }
};

const UpdateProfileScreen = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");

  const [prevContact, setPrevContact] = useState("");
  const [contact, setContact] = useState("");
  const [fileSizeWarning, setFileSizeWarning] = useState("");
  const navigate = useNavigate();

  //username
  const [username, setUsername] = useState("");
  const [isCheckedUsername, setIsCheckedUsername] = useState(false);
  const [isShowErrorUsername, setIsShowErrorUsername] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const [smSelected, setSmSelected] = useState([]);
  const [, setNewProfilePicture] = useState(null);

  const [, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
  });

  const [{ loading, error, dataUser }, dispatch2] = useReducer(reducer2, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const [profilePicture, setProfilePicture] = useState(dataUser.picture);

  useEffect(() => {
    setProfilePicture(dataUser.picture);
  }, [dataUser.picture]);

  const [{ listContactSm }, dispatch3] = useReducer(reducer3, {
    listContactSm: [],
    loadingListSm: true,
    errorListSm: "",
  });

  const socialMediaOptions = listContactSm.map((contact) => ({
    value: contact.id,
    label: contact.name,
    // Include image if available, or set a default image URL
    // Replace 'defaultImageURL' with your actual default image URL
    url: contact.url,
    icon: contact.image ? contact.image : "defaultImageURL",
  }));

  useEffect(() => {
    const fetchData = async () => {
      dispatch2({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch2({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch2({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getAllContactList`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch3({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch3({
          type: "FETCH_FAILED",
          payload: error.message,
        });
        toast.error(getError(error));
      }
    };
    fetchData();
  }, [dispatch2, userInfo]);

  useEffect(() => {
    setName(dataUser.name);
    setEmail(dataUser.email);
    setUsername(dataUser.username);
    setBio(dataUser.bio);
  }, [dataUser]);

  const submitHandler = async (e) => {
    e.preventDefault();

    dispatch({
      type: "UPDATE_REQUEST",
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/updateProfile`,
        {
          name: name,
          bio: bio,
          username: username,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      dispatch({
        type: "UPDATE_SUCCESS",
      });
      // Conditionally navigate and toast
      navigate(`/`);
      toast.success("Updated successfully");
    } catch (err) {
      // Handle error
      dispatch({
        type: "UPDATE_FAILED",
      });
      toast.error(getError(err));
    }
  };

  const updateContact = async (existingContact) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/updateUserContact`,
        {
          contact_id: existingContact.contact_id,
          listcontact_id: existingContact.listcontact_id,
          description: existingContact.description,
          link: existingContact.value,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      // Show success message to the user
      toast.success("Contact Updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Handle error
      toast.error(getError(error));
    }
  };

  const deleteContact = async (contactId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/delUserContact?contact_id=${contactId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      // Show success message to the user
      toast.success("Contact deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Handle error
      toast.error(getError(error));
    }
  };

  const saveNewContact = async (contact) => {
    console.log(contact);
    if (Object.values(contact).some((c) => !c.link)) {
      toast.error("Please enter a link for all selected social media options.");
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/addUserContact`,
        {
          contact,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      console.log();
      // Show success message to the user
      toast.success("Contact Saved Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Handle error
      toast.error(getError(error));
    }
  };

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="w-[6em] h-[6em] lg:w-[8em] lg:h-[8em] mb-3 mt-3 rounded-full"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const maxSizeMB = 3;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      if (file.size > maxSizeBytes) {
        setFileSizeWarning(
          `File size exceeds ${maxSizeMB} MB. Please upload a smaller file.`
        );
        return;
      }
      setFileSizeWarning(""); // Clear warning if file size is acceptable
      setNewProfilePicture(file);
      setIsUploading(true);

      try {
        dispatch({
          type: "UPDATE_REQUEST",
        });

        const formData = new FormData();
        formData.append("file", file);
        formData.append("postId", dataUser.user_id);

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/updateProfileImage`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userInfo.data.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const newProfilePicture = response.data.pictureUrl;
        setProfilePicture(newProfilePicture);

        // Reset file input after successful upload
        e.target.value = null; // This clears the selected file

        dispatch({
          type: "UPDATE_SUCCESS",
        });
        navigate(`/${dataUser.username}`);
        toast.success("Profile Image updated successfully!");
      } catch (err) {
        dispatch({
          type: "UPDATE_FAIL",
        });
        toast.error(getError(err));
      }
    }
  };

  const handleEditImage = () => {
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setNewProfilePicture(null);
  };

  const handleUsernameChange = (value) => {
    setUsername(value);
    debounceCheckUsername(value);
  };

  const checkUsername = async (value) => {
    setIsChecking(true);
    let dataUsername = "";
    if (value) {
      try {
        const getResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/usernameCheck?username=${value}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );
        dataUsername = getResponse.data.data.status;
        if (dataUsername === 1) {
          setIsCheckedUsername(false);
          setIsShowErrorUsername(true);
        } else {
          setIsCheckedUsername(true);
          setIsShowErrorUsername(false);
        }
      } catch (error) {
        toast.error(getError(error));
      }
    }
    setIsChecking(false);
  };

  const debounceCheckUsername = useCallback(debounce(checkUsername, 500), []);

  const handleSocialMediaChange = (selectedOptions, actionMeta) => {
    // Remove the value from the state when it's deselected
    if (actionMeta.action === "remove-value") {
      const newValue = smSelected.filter(
        (option) => option.value !== actionMeta.removedValue.value
      );
      setSmSelected(newValue);
      setContact((prevContact) => {
        const newContact = { ...prevContact };
        delete newContact[actionMeta.removedValue.value];
        return { ...newContact };
      });
    } else {
      setSmSelected(selectedOptions);
      selectedOptions.forEach((selectedOption) => {
        if (!contact[selectedOption.value]) {
          setContact((prevContact) => ({
            ...prevContact,
            [selectedOption.value]: {
              listcontact_id: selectedOption.value,
              description: selectedOption.label,
              link: "",
            },
          }));
        }
      });
    }
  };

  function ProfilePicture() {
    return (
      <img
        className="w-[6em] h-[6em] lg:w-[8em] lg:h-[8em] mb-3 mt-3 rounded-full"
        src={profilePicture}
        alt="profilepicture"
      />
    );
  }

  return (
    <>
      {loading ? (
        <div className="flex justify-center pt-6 ">
          <LoadingBox />
        </div>
      ) : error ? (
        <MessageBox severity="error">{error}</MessageBox>
      ) : (
        <div className="w-full flex items-center justify-center">
          <Helmet>
            <title>Update Profile | SiapHub</title>
          </Helmet>
          <div className="cardFlex mb-20 ">
            <div className="flex justify-center">
              <h1 className="text-center" style={{ fontSize: "2em" }}>
                Update Profile
              </h1>
            </div>
            <form className="w-full rounded-lg" onSubmit={submitHandler}>
              <div className="flex flex-col items-center relative mb-3">
                <SetProfilePicture />

                {isEditMode && (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                      id="imageInput"
                      disabled={isUploading}
                    />
                    {fileSizeWarning && (
                      <div
                        className="text-red-500 mt-1 mb-2"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {fileSizeWarning}
                      </div>
                    )}
                    <label
                      htmlFor="imageInput"
                      className="cursor-pointer bg-blue-500 text-white px-2 py-1 rounded mb-2"
                    >
                      Upload Image
                    </label>
                    <button
                      onClick={handleCancelEdit}
                      className="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Cancel
                    </button>
                  </>
                )}
                {!isEditMode && (
                  <button
                    onClick={handleEditImage}
                    className="
                    py-2 px-4
                    rounded-lg
                    bg-[#F95C3D]
                    text-white
                    hover:bg-orange-600
                  "
                  >
                    Edit Image
                  </button>
                )}
              </div>

              <div className="">
                <div className="w-full mb-2">
                  <div className="flex items-center ">
                    <label style={{ width: "45%" }}>E-mail</label>
                    <div className="w-full px-3 py-2 focus:outline-none">
                      {dataUser.email}
                    </div>
                  </div>
                </div>
                <div className="w-full mb-2">
                  <div className="flex items-center ">
                    <label style={{ width: "45%" }}>Name</label>
                    <input
                      type="text"
                      placeholder={name || "Enter your name"}
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                      value={name || dataUser.name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="w-full mb-2">
                  <div className="flex items-center justify-between relative">
                    <label style={{ width: "45%" }}>Username</label>
                    <input
                      type="text"
                      placeholder={username || "Enter your username"}
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                      value={username}
                      onChange={(e) => handleUsernameChange(e.target.value)}
                      required
                    />

                    <div className="absolute inset-y-0 end-0 flex items-center z-[1] px-3">
                      {isChecking ? (
                        <span>Checking...</span>
                      ) : isCheckedUsername && username !== "" ? (
                        <CheckCircleSharpIcon style={{ fill: "green" }} />
                      ) : (
                        <CheckCircleSharpIcon style={{ fill: "grey" }} />
                      )}
                    </div>
                  </div>

                  {isShowErrorUsername ? (
                    <div
                      className="text-gray-700 focus:outline-none"
                      style={{ fontSize: "0.8rem", color: "red" }}
                    >
                      *Username is already taken!
                    </div>
                  ) : null}
                </div>

                <div className="w-full mb-2">
                  <div className="flex items-center">
                    <label style={{ width: "45%" }}>Bio</label>
                    <textarea
                      placeholder={"Enter your bio"}
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                      value={bio !== undefined ? bio : dataUser.bio || ""}
                      onChange={(e) => setBio(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="w-full mb-2 mt-10 pb-10">
                <label style={{ width: "45%" }}>Contact</label>
                <div className="flex items-center">
                  <div className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none">
                    {dataUser.contact !== null ? (
                      <>
                        {dataUser.contact.map((existingContact, index) => (
                          <div key={existingContact.contact_id}>
                            <div className="flex lg:flex-row flex-col items-center mb-2">
                              <div className="lg:mr-3 w-24 lg:w-20">
                                {existingContact.contact_name}
                              </div>

                              <input
                                type="text"
                                value={
                                  prevContact[existingContact.contact_id]
                                    ?.value || existingContact.link
                                }
                                onChange={(e) => {
                                  setPrevContact((prevContact) => ({
                                    ...prevContact,
                                    [existingContact.contact_id]: {
                                      ...existingContact,
                                      value: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder="Enter link"
                                className="border rounded px-2 py-1 text-gray-700 focus:outline-none"
                              />
                              <div className="">
                                <button
                                  onClick={() =>
                                    updateContact(
                                      prevContact[existingContact.contact_id]
                                    )
                                  }
                                  className={`text-white px-2 py-1 rounded-lg ml-2 bg-[#F95C3D] hover:bg-orange-600  ${
                                    !prevContact[existingContact.contact_id] ||
                                    prevContact[existingContact.contact_id]
                                      .value === existingContact.link
                                      ? "cursor-not-allowed"
                                      : ""
                                  }`}
                                  style={{
                                    opacity:
                                      prevContact[existingContact.contact_id] &&
                                      prevContact[existingContact.contact_id]
                                        .value !== existingContact.link
                                        ? 1
                                        : 0.5,
                                  }}
                                  disabled={
                                    !prevContact[existingContact.contact_id] ||
                                    prevContact[existingContact.contact_id]
                                      .value === existingContact.link
                                  }
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() =>
                                    deleteContact(existingContact.contact_id)
                                  }
                                  className="bg-[#F95C3D] text-white hover:bg-orange-600 px-2 py-1 rounded-lg ml-2"
                                >
                                  Delete
                                </button>
                              </div>

                              {/* Add update button here */}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      ""
                    )}

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#FFE4CC",
                          primary: "#F95C3D",
                        },
                      })}
                      styles={customStylesSelect}
                      name="smSelected"
                      options={socialMediaOptions}
                      value={smSelected}
                      onChange={handleSocialMediaChange}
                      isMulti={true}
                      menuPlacement="top"
                    />
                    <div className="w-full mb-2">
                      <div className="flex flex-col items-center">
                        {smSelected.length > 0 &&
                          smSelected.map((selectedOption) => (
                            <div
                              className="flex flex-row mt-2"
                              key={selectedOption.value}
                            >
                              <div className="mr-3 w-20">
                                {selectedOption.label}
                              </div>
                              <input
                                type="text"
                                required
                                value={
                                  contact[selectedOption.value]?.link || ""
                                }
                                onChange={(e) => {
                                  setContact((prevContact) => ({
                                    ...prevContact,
                                    [selectedOption.value]: {
                                      listcontact_id: selectedOption.value,
                                      description: selectedOption.label,
                                      link: e.target.value,
                                    },
                                  }));
                                }}
                                placeholder={selectedOption.url}
                                className="border rounded px-2 py-1 text-gray-700 focus:outline-none"
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                    {/* Add save button here */}

                    {smSelected.length === 0 ? (
                      ""
                    ) : (
                      <div className="flex justify-center">
                        <button
                          onClick={() => saveNewContact(contact)}
                          className="p-3 m-3"
                          style={{
                            background: "#F95C3D",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="
                    w-full
                    py-2 px-4
                    rounded-lg
                    bg-[#F95C3D]
                    text-white
                    hover:bg-orange-600
                  "
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateProfileScreen;
