import React, { useContext, useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getError } from "../LoadingError/Utils";
import { Store } from "../Store";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  Menu,
  MenuItem,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, followingList: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingFollower: true };
    case "FETCHED":
      return { ...state, followerList: action.payload, loadingFollower: false };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingFollower: false,
        errorFollower: action.payload,
      };
    default:
      return state;
  }
};

const FollowersFollowing = ({ chooseProfile, tab }) => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [activeTab, setActiveTab] = useState(tab);
  const [confirmationOpen, setConfirmationOpen] = useState(false); // State for confirmation modal
  const [userIdToAction, setUserIdToAction] = useState(null); // State to store user ID to unfollow
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [actionType, setActionType] = useState("");

  let finalDataUser = chooseProfile || console.log("Data User not found");

  // Inside your component
  const navigate = useNavigate();

  const handleLinkClick = (path) => {
    window.location.href = path;
  };

  const [{ followingList }, dispatch] = useReducer(reducer, {
    followingList: [],
    loading: true,
    error: "",
  });

  const [{ followerList }, dispatch2] = useReducer(reducer2, {
    followerList: [],
    loadingFollower: true,
    errorFollower: "",
  });

  useEffect(() => {
    fetchFollowing();
    fetchFollowers();
  }, [finalDataUser]);

  const fetchFollowing = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/checkFollowing?findUserId=${finalDataUser.user_id}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      dispatch({
        type: "FETCHED",
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: "FETCH_FAILED",
        payload: error.message,
      });
      toast.error(getError(error));
    }
  };

  const fetchFollowers = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/checkFollower?findUserId=${finalDataUser.user_id}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      dispatch2({
        type: "FETCHED",
        payload: data.data,
      });
    } catch (error) {
      dispatch2({
        type: "FETCH_FAILED",
        payload: error.message,
      });
      toast.error(getError(error));
    }
  };

  // Dropdown state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const handleOption1Click = (user, fStatus) => {

    let targetUser = {
      user_id: fStatus===1?user.source_userid:user.target_userid,
      username: user.username,
      picture: user.picture
    }

    handleClose();
    navigate("/all_chat", { state: { targetUser } });
   
  };

  const handleOption2Click = (userId) => {
    console.log("USER IDDDDD: ", userId);
    setUserIdToAction(userId); // Store user ID to unfollow
    setActionType("unfollow"); // Set action type to unfollow
    setConfirmationOpen(true); // Open confirmation modal
    handleClose();
  };

  const handleOption3Click = (userId) => {
    console.log("USER IDDDDD: ", userId);
    setUserIdToAction(userId); // Store user ID to unfollow
    setActionType("remove"); // Set action type to remove
    setConfirmationOpen(true); // Open confirmation modal
    handleClose();
  };

  const confirmAction = () => {
    if (actionType === "unfollow") {
      unfollowHandler(userIdToAction); // Initiate unfollow action
    } else if (actionType === "remove") {
      removefollowHandler(userIdToAction); // Initiate remove follower action
    }
    setConfirmationOpen(false); // Close confirmation modal
  };

  const unfollowHandler = (userId) => {
    console.log("USER ID : ", userId);
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/unfollow`,
          {
            targetUserId: userId,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        )
        .then((getResponse) => {
          toast.success("User unfollowed!");
          window.location.reload();
        });
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const removefollowHandler = (userId) => {
    console.log("USER ID : ", userId);
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/removeFollower`,
          {
            targetUserId: userId,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        )
        .then((getResponse) => {
          toast.success("Follower Removed!");
          window.location.reload();
        });
    } catch (error) {
      toast.error(getError(error));
    }

  };

  return (
    <div style={{ width: "300px", margin: "0 auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "10px",
        }}
      >
        <button
          style={{
            padding: "10px",
            cursor: "pointer",
            background: "none",
            border: "none",
            fontSize: "16px",
            color: activeTab === "followers" ? "#F97316" : "",
            borderBottom:
              activeTab === "followers" ? "2px solid #F97316" : "none",
          }}
          onClick={() => setActiveTab("followers")}
        >
          Followers ({followerList.length})
        </button>
        <button
          style={{
            padding: "10px",
            cursor: "pointer",
            background: "none",
            border: "none",
            fontSize: "16px",
            color: activeTab === "following" ? "#F97316" : "",
            borderBottom:
              activeTab === "following" ? "2px solid #F97316" : "none",
          }}
          onClick={() => setActiveTab("following")}
        >
          Following ({followingList.length})
        </button>
      </div>
      <div
        style={{
          border: "1px solid #ddd",
          padding: "10px",
          height: "300px",
          overflowY: "auto",
        }}
      >
        {activeTab === "followers" ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {followerList.map((user) => (
              <div
                key={user.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Link to={`/${user.username}`} onClick={() => handleLinkClick(`/${user.username}`)}>
                  <img
                    src={user.picture}
                    alt={user.username}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                </Link>
                <Link to={`/${user.username}`} onClick={() => handleLinkClick(`/${user.username}`)}>
                  <span>{user.username}</span>
                </Link>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  // onClick={handleClick}
                  onClick={(event) => handleClick(event, user.source_userid)}
                  style={{ marginLeft: "auto" }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={selectedUserId === user.source_userid}
                  onClose={handleClose}
                >
                  <MenuItem onClick={()=>handleOption1Click(user,1)}>Message User</MenuItem>
                  <MenuItem
                    onClick={() => handleOption3Click(user.source_userid)}
                  >
                    Remove Follower
                  </MenuItem>
                </Menu>
              </div>
            ))}
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {followingList.map((user) => (
              <div
                key={user.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Link to={`/${user.username}`} onClick={() => handleLinkClick(`/${user.username}`)}>
                  <img
                    src={user.picture}
                    alt={user.username}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                </Link>
                <Link to={`/${user.username}`} onClick={() => handleLinkClick(`/${user.username}`)}>
                  <span>{user.username}</span>
                </Link>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  // onClick={handleClick}
                  onClick={(event) => handleClick(event, user.target_userid)}
                  style={{ marginLeft: "auto" }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={selectedUserId === user.target_userid}
                  onClose={handleClose}
                >
                  <MenuItem onClick={()=>handleOption1Click(user,2)}>Message User</MenuItem>
                  <MenuItem
                    onClick={() => handleOption2Click(user.target_userid)}
                  >
                    Unfollow User
                  </MenuItem>
                </Menu>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Confirmation Modal */}
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
      >
        <DialogTitle>
          {actionType === "unfollow"
            ? "Confirm Unfollow"
            : "Confirm Remove Follower"}
        </DialogTitle>
        <DialogContent>
          {actionType === "unfollow"
            ? "Are you sure you want to unfollow this user?"
            : "Are you sure you want to remove this follower?"}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={confirmAction} variant="contained" color="secondary">
            {actionType === "unfollow" ? "Unfollow" : "Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FollowersFollowing;
