/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Helmet } from "react-helmet-async";

function ForgetPassword() {
    const [inputEmail, setInputEmail] = useState('');
    const [, setError] = useState('');

    const navigate = useNavigate();
    const { search } = useLocation();
    const redirectInUrl = new URLSearchParams(search).get("redirect");
    const redirect = redirectInUrl ? redirectInUrl : `/forgot_password?email=${inputEmail}`;

    const [email, setEmail] = useState("");

    const [, setOpenVerifModal] = useState(false);
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { userInfo } = state;


    const handleOpenVerifModal = () => {
        setOpenVerifModal(true);
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/generateForgotPass?email=${email}`,
                {
                    auth: {
                        username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                        password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
                    },
                }
            );
            ctxDispatch({ type: "USER_SIGNIN", payload: data });
            localStorage.setItem("userInfo", JSON.stringify(data));
            navigate(redirect || `/`);
        } catch (err) {
            if (err.response.data.message === "Verify your account" || err.response === 411) {
                handleOpenVerifModal();
            } else {

            }
            toast.error(getError(err));
        }
    };

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [navigate, userInfo, redirect]);

    return (
        <div className="min-h-screen bg-[rgb(255,228,204)] text-gray-900 flex justify-center">
            <Helmet>
                <title>Forget Password | SiapHub</title>
            </Helmet>
            
            <div className="max-w-screen-lg m-auto lg:m-auto xl:m-auto bg-white shadow sm:rounded-lg flex justify-center flex-1">
                <div className="lg:w-1/2 xl:w-1/2 p-8 sm:p-8 mx-auto"> {/* Tambahkan mx-auto di sini */}
                    <div>
                        <div className="flex font-bold justify-center">
                            <img
                                className="h-24 w-24 mb-3"
                                src="../../images/siaphub_logo.png"
                                alt="siaphub logo"
                            />
                        </div>
                        <p className="text-xl text-gray-600 text-center">Reset Password</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="w-full flex-1">
                            <div className="mx-auto max-w-xs">
                                <form onSubmit={submitHandler}>
                                    <div className="mt-4">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                                            style={{ fontSize: "0.8rem" }}
                                            value={inputEmail}
                                            onChange={(e) => {
                                                setInputEmail(e.target.value);
                                                setError('');
                                                setEmail(e.target.value);
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <button className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded-lg hover:bg-gray-600">Next</button>
                                    </div>
                                </form>
                                <div className="mt-4 flex items-center justify-center">
                                    <a className="text-xs text-gray-500 text-center">Back ? {" "}
                                        <Link to={`/signin`} className="text-[#F95C3D]">Login</Link>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgetPassword;
