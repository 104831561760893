import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import "../../node_modules/leaflet-geosearch/dist/geosearch.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import axios from "axios";
import { MyLocation } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.1px solid gainsboro",
  borderRadius: "1rem",
  boxShadow: "1px 2px 1px gainsboro",
  p: 4,
};

const countries = [
  { code: "AU", name: "Australia", language: "en", countrycodes: "au" },
  { code: "ID", name: "Indonesia", language: "id", countrycodes: "id" },
  { code: "CN", name: "China", language: "zh", countrycodes: "cn" },
  { code: "TW", name: "Taiwan", language: "zh", countrycodes: "tw" },
  { code: "JP", name: "Japan", language: "ja", countrycodes: "jp" },
  { code: "MY", name: "Malaysia", language: "ms", countrycodes: "my" },
  { code: "SG", name: "Singapore", language: "en", countrycodes: "sg" },
  { code: "IN", name: "India", language: "en", countrycodes: "in" },
  { code: "TH", name: "Thailand", language: "th", countrycodes: "th" },
  { code: "PH", name: "Philippines", language: "en", countrycodes: "ph" },
  { code: "VN", name: "Vietnam", language: "vi", countrycodes: "vn" },
  { code: "US", name: "United States" },
  { code: "CA", name: "Canada" },
  { code: "GB", name: "United Kingdom" },
  // Add more countries as needed
];

const bounds = [
  [-90, -180],
  [90, 180],
];

function Maps(props) {
  const [mapCenter] = useState([-25.0, 133.0]);
  const [mapZoom] = useState(4);
  const [isLocatingEnabled, setIsLocatingEnabled] = useState(true);
  const [isSearching, setIsSearching] = useState(true);
  const [watchId, setWatchId] = useState(null);
  const [draggable] = useState(false);
  const [position, setPosition] = useState(null);
  const [province, setProvince] = useState(null);

  const [lastPosition, setLastPosition] = useState(props.position);

  const [showModalPosition, setShowModalPosition] = useState(false);
  const [showModalConfirmPosition, setShowModalConfirmPosition] =
    useState(false);

  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  var x = null;
  var y = null;

  const markerIcon = new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [41, 41],
  });

  L.Marker.prototype.options.icon = markerIcon;

  const markerRef = useRef(null);
  const searchMarkerRef = useRef(null);

  // locationmarker ref
  const locationMarkerRef = useRef(null);

  // searchcontrol ref
  const initialMarkerRef = useRef(null);
  const searchControlRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  const LocationMarker = () => {
    const map = useMap();
    let id = null;

    useEffect(() => {
      if (isLocatingEnabled) {
        if (lastPosition === undefined) {
          id = map
            .locate({
              watch: false,
              enableHighAccuracy: true,
              timeout: 10000, // Increase the timeout (e.g., 10 seconds)
              maximumAge: 60000, // Increase the maximum age (e.g., 60 seconds)
            })
            .on("locationfound", function (e) {
              setPosition(e.latlng);
              map.flyTo(e.latlng, map.getZoom());
            });
          setWatchId(id);
          setIsLocatingEnabled(false);
        } else {
          id = map
            .locate({
              watch: false,
              enableHighAccuracy: true,
              timeout: 10000, // Increase the timeout (e.g., 10 seconds)
              maximumAge: 60000, // Increase the maximum age (e.g., 60 seconds)
            })
            .on("locationfound", function (e) {})
            .on("locationerror", function (e) {
              // setPosition(lastPosition);
              if (lastPosition) {
                setPosition(lastPosition);
                map.flyTo(lastPosition, map.getZoom());
              }
            });
          setWatchId(id);
          setIsLocatingEnabled(false);
        }
      }
    }, []);

    useMapEvent("click", (e) => {
      if (!isSearching) {
        setPosition(e.latlng);
        if (!draggable && position) {
          const locationMarker = L.marker(position, {
            icon: markerIcon,
            draggable: false, // Set to false when not draggable
          }).addTo(map);

          // Store the marker reference
          locationMarkerRef.current = locationMarker;
        }
      }
    });

    return isSearching ? null : position !== null ? (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup minWidth={90}>
          Coordinates: <br />
          {position.lat}, {position.lng}
        </Popup>
      </Marker>
    ) : null;
  };

  const formatPopupContent = (result) => {
    const { label, x, y } = result.result;

    return `
      <strong>${label}</strong><br/>
      Coordinates: ${x}, ${y}
    `;
  };

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    const country = countries.find((c) => c.code === countryCode);
    setSelectedCountry(country);
  };

  // const indonesiaProvider = new OpenStreetMapProvider({
  //   params: {
  //     "accept-language": "id",
  //     countrycodes: "id",
  //     addressdetails: 1,
  //   },
  // });

  let map = null;
  let searchControl = null;

  const SearchView = ({ selectedCountry }) => {
    map = useMap();

    // const provider = indonesiaProvider;

    useEffect(() => {
      // if (initialMarkerRef.current === null && position) {
      //   const initialMarker = L.marker([position.lat, position.lng], {
      //     icon: markerIcon,
      //     draggable: false,
      //   });

      //   // Save the initial marker to the ref
      //   initialMarkerRef.current = initialMarker;

      //   // Add the initial marker to the map
      //   initialMarker.addTo(map);
      // }

      const provider = new OpenStreetMapProvider({
        params: {
          "accept-language": selectedCountry.language,
          countrycodes: selectedCountry.countrycodes,
          addressdetails: 1,
        },
      });

      const searchControl = new GeoSearchControl({
        notFoundMessage: "Sorry, that address could not be found.",
        provider: provider,
        autoComplete: true,
        autoCompleteDelay: 250,
        style: "bar",
        marker: {
          icon: markerIcon,
          draggable: true,
        },
        showMarker: true,
        popupFormat: formatPopupContent,
        searchLabel: "Enter address",
        updateMap: true,
        animateZoom: true,
        retainZoomLevel: true,
      });

      if (searchControlRef.current === null) {
        map.addControl(searchControl);
        searchControlRef.current = searchControl;
      } else {
        map.removeControl(searchControlRef.current);
        map.addControl(searchControl);
        searchControlRef.current = searchControl;
      }

      const handleShowLocation = (event) => {
        x = event.location.x;
        y = event.location.y;

        if (searchMarkerRef.current) {
          map.removeLayer(searchMarkerRef.current);
        }

        setPosition({ lat: y, lng: x });

        const marker = event.marker;
        searchMarkerRef.current = marker;

        marker.on("dragstart", () => {
          marker.unbindPopup(); // Disable popup by unbinding it
        });

        marker.on("dragend", () => {
          const { lat, lng } = marker.getLatLng();
          setPosition({ lat, lng });

          marker.closePopup();
        });

        map.on("click", (event) => {
          if (isSearching && searchControlRef.current !== null) {
            map.removeLayer(searchMarkerRef.current);
            // console.log(event.latlng.lat)
            // const { lat, lng } =  event.latlng;

            setPosition(event.latlng);

            const locationMarker = L.marker(event.latlng, {
              icon: markerIcon,
              draggable: false, // Set to false when not draggable
            }).addTo(map);

            searchMarkerRef.current = locationMarker;
          }
        });
      };

      map.on("geosearch/showlocation", handleShowLocation);

      return () => {
        map.off("geosearch/showlocation", handleShowLocation);

        // if (searchControl && watchId) {

        //   map.stopLocate(watchId);
        // }

        if (searchControlRef.current) {
          map.removeControl(searchControlRef.current);
          searchControlRef.current = searchControl;
        }

        // // Remove the initial marker when switching to LocationMarker
        if (initialMarkerRef.current) {
          map.removeLayer(initialMarkerRef.current);
          initialMarkerRef.current = null;
        }
      };
    }, [selectedCountry, map]);

    return null;
  };

  function handleSearchMyLocation() {
    // setPosition(null);
    setIsSearching(true);
  }

  function handleFindMyLocation() {
    const searchControl = searchControlRef.current;
    const resetButton = searchControl.resetButton;
    if (searchMarkerRef.current) {
      map.removeLayer(searchMarkerRef.current);
    }

    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    resetButton.dispatchEvent(clickEvent);

    map.removeControl(searchControl);
    searchControlRef.current = null;
    setIsLocatingEnabled(true);
    setIsSearching(false);
  }

  function handleSetMyLocation() {
    if (x !== null && y !== null) {
      setPosition({ lat: y, lng: x });
    }
    setShowModalConfirmPosition(true);
  }

  function handleConfirmButtonModalPosition() {
    if (x !== null && y !== null) {
      setPosition({ lat: y, lng: x });
    }

    if (position === null) {
      setShowModalPosition(true);
    } else {
      props.sendData(position, province);
      props.closeModal();
    }
  }
  function handleBackButtonModalPosition() {
    setShowModalConfirmPosition(false);
  }

  useEffect(() => {
    // Reverse geocoding function
    const reverseGeocode = (lat, lng) => {
      const nominatimEndpoint = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;

      axios
        .get(nominatimEndpoint)
        .then((response) => {
          const data = response.data;
          // Check if the response contains province information
          if (data.address && data.address.state) {
            setProvince(data.address.state);
          } else if (
            data.address &&
            data.address.city === "Special capital Region of Jakarta"
          ) {
            setProvince("Jakarta");
          } else if (data.address && data.address.territory) {
            setProvince(data.address.territory);
          } else {
            setProvince("Province/ State not found");
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error);
        });
    };

    // Create the marker when location is found
    if (position) {
      // Update the address when a new location is found
      reverseGeocode(position.lat, position.lng);
      // Only add the marker if it's not draggable (clicked)
      setLastPosition(position);
    }

    return () => {
      // Remove the location marker when unmounting or switching to SearchControl
      if (locationMarkerRef.current) {
        locationMarkerRef.current.remove();
      }
    };
  }, [position]);

  return (
    <>
      {isSearching && (
        <div className="flex justify-center m-[-10px]">
          <select
            onChange={handleCountryChange}
            className="py-2 px-4 rounded-full"
          >
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <MapContainer
        id="map"
        center={mapCenter}
        zoom={mapZoom}
        minZoom={2}
        maxBounds={bounds}
        maxBoundsViscosity={1.0}
        style={{ height: "80%", marginTop: "10px" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          noWrap={true}
        />
        {isSearching ? (
          <SearchView selectedCountry={selectedCountry} />
        ) : (
          <LocationMarker />
        )}
      </MapContainer>

      {/* Is this searching Page ⬇️*/}

      {isSearching === true ? (
        <div className="flex mt-1 justify-center">
          <div className="flex" style={{ alignItems: "center" }}>
            {" "}
             &ensp;
          </div>
          <button
            className="
                py-2
                rounded-md
                focus:outline-none
                font-bold
              "
            style={{
              fontSize: "0.8rem",
              background: "white",
              color: "#f95c3d",
              borderWidth: "2px",
              borderColor: "#f95c3d",
              width: "50%",
              margin: "0.5rem"
            }}
            onClick={handleFindMyLocation}
          >
            Find My Location
          </button>
        </div>
      ) : (
        <div className="flex mt-1 justify-center">
          <div className="flex" style={{ alignItems: "center" }}>
            {" "}
            Incorrect location? Try to search the location with this button
            &ensp;
          </div>
          <button
            className="
                py-2
                rounded-full
                focus:outline-none
                font-bold
              "
            style={{
              fontSize: "0.8rem",
              background: "white",
              color: "#f95c3d",
              borderWidth: "2px",
              borderColor: "#f95c3d",
              width: "20%",
            }}
            onClick={handleSearchMyLocation}
          >
            Search My Location
          </button>
        </div>
      )}

      {/* Set my Location Button ⬇️ */}

      <div
        className="flex justify-center"
        style={{
          position: "relative",
          bottom: "1%",
          width: "100%",
          marginTop: "1%",
        }}
      >
        <button
          className="
              py-2
              rounded-full
              focus:outline-none
              font-bold
            "
          style={{
            fontSize: "0.8rem",
            background:
              province === null ? "rgba(249, 92, 61, 0.5)" : "#f95c3d",
            color: "white",
            width: "80%",
          }}
          disabled={province === null}
          onClick={handleSetMyLocation}
        >
          Set My Location
        </button>
      </div>

      {/* Modal First Confirmation ⬇️*/}

      <Modal
        open={showModalConfirmPosition}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-notification-style">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10%",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            Are you sure with this location?
          </Typography>
          <div className="flex justify-center">
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleConfirmButtonModalPosition}
            >
              Yes
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleBackButtonModalPosition}
            >
              Back
            </button>
          </div>
        </Box>
      </Modal>

      {/* You haven't choose coordinate pop up modal ⬇️*/}

      {showModalConfirmPosition && (
        <>
          <Modal
            open={showModalPosition}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-notification-style">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  marginBottom: "10%",
                  marginTop: "5%",
                  textAlign: "center",
                }}
              >
                You haven't chosen your coordinate location
              </Typography>
              <div className="flex justify-center">
                <button
                  type="button"
                  className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
                  onClick={handleBackButtonModalPosition}
                >
                  Back
                </button>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default Maps;
