import { BrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { useContext, useEffect, useState } from "react";

import FiturAll from "./FiturAll";

import { Snackbar, SnackbarContent } from "@mui/material";

import { getMessaging, onMessage } from "firebase/messaging";
import { ToastContainer } from "react-toastify";
import { Store } from "./Store";
function App() {
  const [message, setMessage] = useState(null);
  const { dispatch } = useContext(Store);
  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          // navigator.serviceWorker.ready
          //   .then((registration) => {
          //     if (!registration.active) {
          //       return navigator.serviceWorker.register(
          //         "/firebase-messaging-sw.js"
          //       );
          //     }
          //   })
          .then((registration) => {
            console.log("Service Worker registered: ", registration.scope);
            return Notification.requestPermission();
          })
          .then((permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
            } else {
              console.log("Notification permission denied.");
            }
          })
          .catch((err) =>
            console.error("Service Worker registration failed: ", err)
          );
      });
    }
  }, []);

  useEffect(() => {
    const messaging = getMessaging();

    const unsubscribe = onMessage(messaging, (payload) => {
      if (!message) {
        if (payload.notification) {
          setMessage(payload.notification.body);
        } else if (payload.data) {
          setMessage(payload.data.body);
        }
        dispatch({ type: "TRIGGER_FETCH_NOTIFICATIONS" });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, message]);

  return (
    <BrowserRouter>
      <div>
        <Snackbar
          open={!!message}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className="opacity-70"
        >
          <SnackbarContent message={message} />
        </Snackbar>
        <ToastContainer position="bottom-center" limit={1} />
        <FiturAll />
        {/* <div className="flex justify-center">
          <img
            className="w-auto h-screen "
            src="../images/maintenance_siaphub.jpg"
          />
        </div> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
