/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import HomeScreen from "./Screens/HomeScreen";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignInScreen from "./Screens/SignInScreen";
import "react-toastify/dist/ReactToastify.css";
import RegisterScreen from "./Screens/RegisterScreen";
import ProfileScreen from "./Screens/ProfileScreen";
import SearchScreen from "./Screens/SearchScreenSd";
import AddPostScreen from "./Screens/AddPostScreen";
import UpdatePostScreen from "./Screens/UpdatePostScreen";
import { useContext, useEffect, useState, useReducer } from "react";
import UpdateProfileScreen from "./Screens/UpdateProfileScreen";
import AllChatScreen from "./Screens/AllChatScreen";
import AdminVerifiedScreen from "./Screens/AdminVerifiedScreen";
import VerifiedUserScreen from "./Screens/VerifiedUserScreen";
import ForgetPassword from "./Screens/ForgetPassword";
import { Store } from "./Store";
import ResetPassword from "./Screens/ResetPassword";
import ForgotPassword from "./Screens/ForgotPassword";
import OTPScreen from "./Screens/OTPScreen";
import Headers from "./Headers";
import { ToastContainer } from "react-toastify";
import { Box, Modal, Typography } from "@mui/material";
import MobileFooter from "./HeaderAndFooter/MobileFooter";
import DetailPost from "./Screens/DetailPost";
import { toast } from "react-toastify";
import { getError } from "./LoadingError/Utils";
import WelcomeScreen from "./Screens/WelcomeScreen";
import CreateProfileScreen from "./Screens/CreateProfileScreen";
import UpdatePasswordScreen from "./Screens/UpdatePasswordScreen";
import UpgradeAccountScreen from "./Screens/UpgradeAccountScreen";
import ApplyJobScreen from "./Screens/ApplyJobScreen";
import RegisterProfessionalAccountScreen from "./Screens/RegisterProfessionalAccountScreen";
import ContactUsScreen from "./Screens/ContactUsScreen";
import AboutUsScreen from "./Screens/AboutUsScreen";
import Select from "react-select";
import EditProfessionalAccountScreen from "./Screens/EditProfessionalAccountScreen";
import PrivacyPolicyScreen from "./Screens/PrivacyPolicyScreen";
import TermsOfServiceScreen from "./Screens/TermsOfServiceScreen";
import FollowersFollowingScreen from "./Screens/FollowersFollowingScreen";
import { data } from "autoprefixer";

const customStylesSelect = {
  option: (provided) => ({
    ...provided,
    ":active": {
      ...provided[":active"],
      backgroundColor: "#FFE4CC",
    },
  }),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCategoryList: true };
    case "FETCHED":
      return {
        ...state,
        categoryList: action.payload,
        loadingCategoryList: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCategoryList: false,
        errorCategoryList: action.payload,
      };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingCountryList: true };
    case "FETCHED":
      return {
        ...state,
        countryList: action.payload,
        loadingCountryList: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingCountryList: false,
        errorCountryList: action.payload,
      };
    default:
      return state;
  }
};

const reducer4 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingLocationlist: true };
    case "FETCHED":
      return {
        ...state,
        locationList: action.payload,
        loadingLocationlist: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingLocationlist: false,
        errorLocationList: action.payload,
      };
    default:
      return state;
  }
};

const reducer5 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingActivityLog: true };
    case "FETCHED":
      return {
        ...state,
        loadingActivityLog: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingActivityLog: false,
        errorActivityLog: action.payload,
      };
    default:
      return state;
  }
};

export default function Sidebar() {
  const [currentSwitch, setCurrentSwitch] = useState(1);
  const [pu, setPu] = useState("");
  const [typePost, setTypePost] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);

  const locationMenu = useLocation();
  const [showSignInModalChat, setShowSignInModalChat] = useState(false);
  const [, setAnchorElUser] = useState(null);
  const [isClickedSearch, setIsClickedSearch] = useState(false);
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [isLoadingImg, setIsLoadingImg] = useState(true);

  //showAll filter
  const [showAll, setShowAll] = useState(false);
  const [showAllProduct, setShowAllProduct] = useState(false);
  const [showAllService, setShowAllService] = useState(false);
  const [showAllEvent, setShowAllEvent] = useState(false);
  const [showAllJob, setShowAllJob] = useState(false);
  const [showAllInsight, setShowAllInsight] = useState(false);

  const [category, setCategory] = useState([]);

  //country list filter
  const [location, setLocation] = useState([]);
  // const [countryName, setCountryName] = useState("Indonesia");
  const [countryId, setCountryId] = useState(1);
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);

  const [verified, setVerified] = useState("");
  const [date, setDate] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const refreshChild = () => {
    setSelectedTypes();
  };

  const postTypeList = [
    { type: 1, subcategories: [1, 2], typeName: "Product" },
    { type: 2, subcategories: [1, 2], typeName: "Service" },
    { type: 3, subcategories: [1, 2], typeName: "Job" },
    { type: 4, subcategories: [1, 2], typeName: "Event" },
    { type: 5, subcategories: [1, 2], typeName: "Insight" },
  ];

  const verifiedList = ["Verified", "Non Verified"];
  const dateList = ["Last 3 days", "Last 7 days", "Last 30 days", "> 30 days"];

  const [{ dataUser }, dispatch] = useReducer(reducer, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const [{ categoryList, loadingCategoryList }, dispatch2] = useReducer(
    reducer2,
    {
      categoryList: [],
      loadingCategoryList: true,
      errorCategoryList: "",
    }
  );

  const [{ countryList }, dispatch3] = useReducer(reducer3, {
    countryList: [],
    loadingCountryList: true,
    errorCountryList: "",
  });

  const [{ locationList }, dispatch4] = useReducer(reducer4, {
    locationList: [],
    loadingLocationList: true,
    errorLocationList: "",
  });

  const [{ loadingActivityLog }, dispatch5] = useReducer(reducer5, {
    loadingActivityLog: true,
    errorActivityLog: "",
  });

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const toggleShowAll2 = () => {
    setShowAllProduct(!showAllProduct);
  };

  const toggleShowAll3 = () => {
    setShowAllService(!showAllService);
  };

  const toggleShowAll4 = () => {
    setShowAllEvent(!showAllEvent);
  };

  const toggleShowAll5 = () => {
    setShowAllJob(!showAllJob);
  };

  const toggleShowAll6 = () => {
    setShowAllInsight(!showAllInsight);
  };

  const handleCurrentSwitch = (childState) => {
    setCurrentSwitch(childState);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handlePu = (childState) => {
    setPu(childState);
  };

  const handleSearchInput = (childState) => {
    setSearchInput(childState);
  };

  const handleSearchFlag = (childState) => {
    setSearchFlag(childState);
  };

  function handleCloseSignInModalChat() {
    setShowSignInModalChat(false);
  }

  const handleCancelButtonModalChat = () => {
    setShowSignInModalChat(false);
  };

  function handleSignInButtonModalChat() {
    setShowSignInModalChat(false);
    navigate("/signin");
  }

  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
  };

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className={`w-[30px] h-[30px] mr-2 rounded-full ${
          locationMenu.pathname === `/${dataUser.username}`
            ? "border-2 border-text-orange"
            : ""
        }`}
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }

  useEffect(() => {
    const img = new Image();
    img.src = dataUser.picture;
    img.onload = () => setIsLoadingImg(false);
  }, [dataUser.picture]);

  useEffect(() => {
    if ( !userInfo ) {
      return;
    }

    
    const fetchData = async () => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/activityLog`,
          {},
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        );

        dispatch5({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {

        dispatch5({
          type: "FETCH_FAILED",
          payload: error.message,
        });
      }
    };

    fetchData();
  }, [dataUser]);

  function ProfilePicture() {
    return (
      <>
        {isLoadingImg ? (
          <div
            className={`w-[30px] h-[30px] mr-2 rounded-full ${
              isLoadingImg ? "animate-pulse bg-slate-400" : ""
            }`}
          ></div>
        ) : (
          <img
            className={`w-[30px] h-[30px] mr-2 rounded-full ${
              locationMenu.pathname === `/${dataUser.username}`
                ? "border-2 border-text-orange"
                : ""
            }`}
            src={dataUser.picture}
            alt="profilepicture"
          />
        )}
      </>
    );
  }

  const handleSearchIconClick = (bool) => {
    if (bool !== true && bool !== false) {
      setIsClickedSearch(!isClickedSearch);
    } else {
      setIsClickedSearch(bool);
    }
  };

  useEffect(() => {
    setIsClickedSearch(false);
  }, [window.location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo !== null) {
        dispatch({
          type: "FETCHING",
        });

        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
            {
              headers: { Authorization: `Bearer ${userInfo.data.token}` },
            }
          );

          dispatch({
            type: "FETCHED",
            payload: data.data,
          });
        } catch (error) {
          dispatch({
            type: "FETCH_FAILED",
            payload: error.message,
          });

          if (error.response && error.response.status === 401) {
            // Assuming you have a signoutHandler function
            signoutHandler();
            toast.error(error.response);
            navigate("/signin");
          } else {
            toast.error(getError(error));
          }
        }
      }
    };

    fetchData();
  }, [userInfo]);

  useEffect(() => {
    // first initialization
    const initialSelectedTypes = postTypeList.map((type) => ({
      ...type,
      selectedSubcategories: [],
    }));

    setSelectedTypes(initialSelectedTypes);
  }, []);

  // after dataUser exist find category

  useEffect(() => {
    // first category choices

    const fetchData = async () => {
      dispatch2({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getCategoryOptions`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch2({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch2({
          type: "FETCH_FAILED",
          payload: error.message,
        });

        toast.error(getError(error));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // first country choices
    const fetchData = async () => {
      dispatch3({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getCountryOptions`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch3({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch3({
          type: "FETCH_FAILED",
          payload: error.message,
        });

        toast.error(getError(error));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // first country choices
    const fetchData = async () => {
      dispatch4({
        type: "FETCHING",
      });

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/getLocationOptions?countryId=${countryId}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        dispatch4({
          type: "FETCHED",
          payload: data.data,
        });
      } catch (error) {
        dispatch4({
          type: "FETCH_FAILED",
          payload: error.message,
        });

        toast.error(getError(error));
      }
    };

    fetchData();
  }, [countryId]);

  const onChangeLocation = (e, loc) => {
    if (e.target.checked) {
      setLocation((location) => [...location, loc]);
    } else {
      setLocation(location.filter((item) => item !== loc));
    }
  };

  const onChangeCountryId = (e) => {
    if (e && e.value) {
      setCountryId(e.value);
    } else {
      setCountryId(1);
    }
  };

  const onChangePostType = (e, type) => {
    const isChecked = e.target.checked;
    const updatedTypes = selectedTypes.map((selected) => {
      if (selected.type === type.type) {
        if (isChecked) {
          return {
            ...selected,
            selectedSubcategories: type.subcategories, // Selecting all subcategories by default
          };
        } else {
          return {
            ...selected,
            selectedSubcategories: [], // Reset subcategories when type is unchecked
          };
        }
      }
      return selected;
    });

    setSelectedTypes(updatedTypes);
  };

  const shouldShowProduct =
    typePost && typePost.some((item) => item.post_type === 1);

  const shouldShowService =
    typePost && typePost.some((item) => item.post_type === 2);

  const shouldShowEvents =
    typePost && typePost.some((item) => item.post_type === 3);

  const shouldShowJob =
    typePost && typePost.some((item) => item.post_type === 4);

  const shouldShowInsight =
    typePost && typePost.some((item) => item.post_type === 5);

  const handleSubcategoryChange = (e, type, subCategory) => {
    const isChecked = e.target.checked;
    const updatedTypes = selectedTypes.map((selected) => {
      if (selected.type === type.type) {
        if (isChecked || selected.selectedSubcategories.length > 1) {
          const updatedSubcategories = isChecked
            ? [...selected.selectedSubcategories, subCategory]
            : selected.selectedSubcategories.filter(
                (selectedSub) => selectedSub !== subCategory
              );
          return {
            ...selected,
            selectedSubcategories: updatedSubcategories,
          };
        }
      }
      return selected;
    });
    setSelectedTypes(updatedTypes);
  };

  useEffect(() => {
    const result = [];

    for (const key in selectedTypes) {
      const entry = selectedTypes[key];
      const selectedSubcategoriesLength = entry.selectedSubcategories.length;

      if (selectedSubcategoriesLength > 0) {
        for (let i = 0; i < selectedSubcategoriesLength; i++) {
          const newObj = {
            post_type: entry.type,
            sd_post_type: entry.selectedSubcategories[i],
          };
          result.push(newObj);
        }
      }
    }

    setTypePost(result);
  }, [selectedTypes]);

  // seperate category

  const productCategories = categoryList.filter((item) => item.ps_id === 1);
  const serviceCategories = categoryList.filter((item) => item.ps_id === 2);
  const eventCategories = categoryList.filter((item) => item.ps_id === 3);
  const jobCategories = categoryList.filter((item) => item.ps_id === 4);
  const insightCategories = categoryList.filter((item) => item.ps_id === 5);

  const onChangeCategory = (e, loc) => {
    if (e.target.checked) {
      setCategory((category) => [...category, loc.value]);
    } else {
      setCategory(category.filter((item) => item !== loc.value));
    }
  };

  const onChangeVerified = (e, loc) => {
    const newVerified = loc.replace(/\s/g, "");
    if (e.target.checked) {
      if (newVerified === "Verified") {
        setVerified((verified) => ["true"]);
      } else {
        setVerified((verified) => ["false"]);
      }
    } else {
      setVerified([]);
    }
  };

  const onChangeDate = (e, loc) => {
    const newDate = loc + "d";
    if (e.target.checked) {
      if (loc === "Last 3 days") {
        setDate((date) => ["3d"]);
      } else if (loc === "Last 7 days") {
        setDate((date) => ["7d"]);
      } else if (loc === "Last 30 days") {
        setDate((date) => ["30d"]);
      } else {
        setDate((date) => ["31d"]);
      }
    } else {
      setDate(date.filter((item) => item !== newDate));
    }
  };

  return (
    <>
      {!(
        (
          locationMenu.pathname === "/signin" ||
          locationMenu.pathname === "/register" ||
          locationMenu.pathname === "/forget_password" ||
          locationMenu.pathname === "/reset_password" ||
          locationMenu.pathname === "/forgot_password" ||
          locationMenu.pathname === "/verified_user" ||
          locationMenu.pathname === "/otp_user" ||
          locationMenu.pathname === "/welcome" ||
          locationMenu.pathname === "/create_profile"
        )
        // locationMenu.pathname === '/apply_job'
      ) ? (
        <div className="flex flex-col lg:flex-row h-screen">
          <Headers
            onChildStateChange={handleCurrentSwitch}
            onChildStateChangePu={handlePu}
            onChildStateChangeSearch={handleSearchInput}
            onChildStateChangeSearchFlag={handleSearchFlag}
            onSearchIconClick={handleSearchIconClick}
          />
          {/* Desktop Layout (Filter) */}
          <div className="lg:w-52 shadow-md hidden lg:flex ">
            <ul className="lg:w-52 space-y-4 mt-16 overflow-auto max-h-screen lg:max-h-96">
              <div className="flex-1">
                <ul className={`pt-2 space-y-1 text-sm text-gray-500 `}>
                  <div
                    className={`fixed top-12 bg-white z-10 p-5 overflow-auto w-52 space-6 h-full transition-transform transform ${
                      isClickedSearch ? "translate-x-0" : "-translate-x-56"
                    }`}
                  >
                    <div className="font-bold mt-6">Filter</div>
                    <ul className="pb-16">
                      <li className="font-bold flex-col mt-1">Post Type</li>
                      {postTypeList.map((loc) => (
                        <li key={loc.type}>
                          <input
                            type="checkbox"
                            onChange={(e) => onChangePostType(e, loc)}
                            className="mr-3 accent-[#F95C3D]"
                          />
                          <label> {loc.typeName}</label>
                          {selectedTypes.find(
                            (selected) =>
                              selected.type === loc.type &&
                              selected.selectedSubcategories.length > 0
                          ) && (
                            <ul style={{ marginLeft: "20px" }}>
                              {loc.subcategories.map((subCategory) => {
                                let labelForSubCategory = "";

                                switch (loc.type) {
                                  case 1:
                                    switch (subCategory) {
                                      case 1:
                                        labelForSubCategory = "Supplier";
                                        break;
                                      case 2:
                                        labelForSubCategory = "Buyer";
                                        break;
                                    }
                                    break;
                                  case 2:
                                    switch (subCategory) {
                                      case 1:
                                        labelForSubCategory = "Provider";
                                        break;
                                      case 2:
                                        labelForSubCategory = "Seeker";
                                        break;
                                    }
                                    break;
                                  case 3:
                                    switch (subCategory) {
                                      case 1:
                                        labelForSubCategory = "Employer";
                                        break;
                                      case 2:
                                        labelForSubCategory = "Applicant";
                                        break;
                                    }
                                    break;
                                  case 4:
                                    switch (subCategory) {
                                      case 1:
                                        labelForSubCategory = "Organizer";
                                        break;
                                      case 2:
                                        labelForSubCategory = "Attendee";
                                        break;
                                    }
                                    break;
                                  case 5:
                                    labelForSubCategory = "Client";
                                    break;
                                }
                                return (
                                  <li key={subCategory}>
                                    <input
                                      type="checkbox"
                                      checked={selectedTypes
                                        .find(
                                          (selected) =>
                                            selected.type === loc.type
                                        )
                                        .selectedSubcategories.includes(
                                          subCategory
                                        )}
                                      onChange={(e) =>
                                        handleSubcategoryChange(
                                          e,
                                          loc,
                                          subCategory
                                        )
                                      }
                                      className="mr-3  accent-[#F95C3D]"
                                    />
                                    <label>{labelForSubCategory}</label>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      ))}

                      {/* Country */}
                      <li className="font-bold flex-col mt-1">Location</li>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={countryList.find(
                          (option) => option.value === countryId
                        )}
                        onChange={onChangeCountryId}
                        options={countryList}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#FFE4CC",
                            primary: "#F95C3D",
                          },
                        })}
                        styles={customStylesSelect}
                      />

                      {locationList.map((loc, index) => {
                        if (!showAll && index > 2) {
                          return null;
                        }
                        return (
                          <li key={loc.value}>
                            <input
                              type="checkbox"
                              onChange={(e) => onChangeLocation(e, loc.value)}
                              className="mr-3 accent-[#F95C3D]"
                            />
                            <label>{loc.label}</label>
                          </li>
                        );
                      })}

                      {locationList.length > 3 && (
                        <button
                          onClick={toggleShowAll}
                          className="text-[#F95C3D]"
                        >
                          {showAll ? "See Less" : "See More"}
                        </button>
                      )}

                      <li className="font-bold flex-col mt-1">Category</li>
                      {!shouldShowEvents &&
                      !shouldShowProduct &&
                      !shouldShowJob &&
                      !shouldShowInsight &&
                      !shouldShowService ? (
                        <li className="font-semibold flex-col mt-1 ml-3">
                          Select a Post Type
                        </li>
                      ) : (
                        ""
                      )}
                      <div>
                        {shouldShowProduct ? (
                          <div>
                            <li className="font-semibold flex-col mt-1 ml-3">
                              Product
                            </li>

                            {productCategories.map((loc, index) => {
                              if (!showAllProduct && index > 2) {
                                return null;
                              }
                              return (
                                <li className="ml-3">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => onChangeCategory(e, loc)}
                                    className="mr-3 accent-[#F95C3D]"
                                  ></input>
                                  <label>{loc.label}</label>
                                </li>
                              );
                            })}

                            {productCategories.length > 3 && (
                              <button
                                onClick={toggleShowAll2}
                                className="text-[#F95C3D] ml-3"
                              >
                                {showAllProduct ? "See Less" : "See More"}
                              </button>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {shouldShowService ? (
                          <div>
                            <li className="font-semibold flex-col mt-1 ml-3">
                              Service
                            </li>
                            {serviceCategories.map((loc, index) => {
                              if (!showAllService && index > 2) {
                                return null;
                              }
                              return (
                                <li className="ml-3">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => onChangeCategory(e, loc)}
                                    className="mr-3 accent-[#F95C3D]"
                                  ></input>
                                  <label>{loc.label}</label>
                                </li>
                              );
                            })}

                            {serviceCategories.length > 3 && (
                              <button
                                onClick={toggleShowAll3}
                                className="text-[#F95C3D] ml-3"
                              >
                                {showAllService ? "See Less" : "See More"}
                              </button>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {shouldShowEvents ? (
                          <div>
                            <li className="font-semibold flex-col mt-1 ml-3">
                              Events
                            </li>
                            {eventCategories.map((loc, index) => {
                              if (!showAllEvent && index > 2) {
                                return null;
                              }
                              return (
                                <li className="ml-3">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => onChangeCategory(e, loc)}
                                    className="mr-3 accent-[#F95C3D]"
                                  ></input>
                                  <label>{loc.label}</label>
                                </li>
                              );
                            })}

                            {eventCategories.length > 3 && (
                              <button
                                onClick={toggleShowAll4}
                                className="text-[#F95C3D] ml-3"
                              >
                                {showAllEvent ? "See Less" : "See More"}
                              </button>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {shouldShowJob ? (
                          <div>
                            <li className="font-semibold flex-col mt-1 ml-3">
                              Job
                            </li>
                            {jobCategories.map((loc, index) => {
                              if (!showAllJob && index > 2) {
                                return null;
                              }
                              return (
                                <li className="ml-3">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => onChangeCategory(e, loc)}
                                    className="mr-3 accent-[#F95C3D]"
                                  ></input>
                                  <label>{loc.label}</label>
                                </li>
                              );
                            })}

                            {jobCategories.length > 3 && (
                              <button
                                onClick={toggleShowAll5}
                                className="text-[#F95C3D] ml-3"
                              >
                                {showAllJob ? "See Less" : "See More"}
                              </button>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {shouldShowInsight ? (
                          <div>
                            <li className="font-semibold flex-col mt-1 ml-3">
                              Insight
                            </li>
                            {insightCategories.map((loc, index) => {
                              if (!showAllInsight && index > 2) {
                                return null;
                              }
                              return (
                                <li className="ml-3">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => onChangeCategory(e, loc)}
                                    className="mr-3 accent-[#F95C3D]"
                                  ></input>
                                  <label>{loc.label}</label>
                                </li>
                              );
                            })}

                            {insightCategories.length > 3 && (
                              <button
                                onClick={toggleShowAll6}
                                className="text-[#F95C3D] ml-3"
                              >
                                {showAllInsight ? "See Less" : "See More"}
                              </button>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <li className="font-bold flex-col mt-1">Verified</li>
                      {verifiedList.map((loc) => (
                        <li>
                          <input
                            type="radio"
                            name="verified"
                            onChange={(e) => onChangeVerified(e, loc)}
                            className="mr-3 accent-[#F95C3D]"
                          ></input>
                          <label>{loc}</label>
                        </li>
                      ))}

                      <li className="font-bold flex-col mr-3 mt-1">Date</li>
                      {dateList.map((loc) => (
                        <li>
                          <input
                            type="radio"
                            name="verified"
                            onChange={(e) => onChangeDate(e, loc)}
                            className="mr-3 accent-[#F95C3D]"
                          ></input>
                          <label>{loc}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* ) : ( */}
                  <>
                    <li className="flex items-center rounded-sm ">
                      {locationMenu.pathname === "/" ? (
                        <div
                          className="h-10 w-0.5"
                          style={{ backgroundColor: "#F95C3D" }}
                        ></div>
                      ) : (
                        ""
                      )}
                      <Link
                        to="/"
                        className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-lg hover:bg-[#FFE4CC] focus:opacity-70 ${
                          locationMenu.pathname === "/"
                            ? "bg-[#FFE4CC] text-[#F95C3D]"
                            : ""
                        }`}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2"
                        >
                          <path
                            d="M10.3333 29V15H19.6667V29M1 10.8L15 1L29 10.8V26.2C29 26.9426 28.6722 27.6548 28.0888 28.1799C27.5053 28.705 26.714 29 25.8889 29H4.11111C3.28599 29 2.49467 28.705 1.91122 28.1799C1.32778 27.6548 1 26.9426 1 26.2V10.8Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Home</span>
                      </Link>
                    </li>

                    <li className="lg:w-52 flex items-center rounded-sm">
                      {locationMenu.pathname === "/add_post" ? (
                        <div
                          className="h-10 w-0.5"
                          style={{ backgroundColor: "#F95C3D" }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {!userInfo ? (
                        <div
                          onClick={() => setShowSignInModalChat(true)}
                          className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-r-md hover:bg-[#FFE4CC] focus:opacity-70 cursor-pointer`}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                              fill="currentColor"
                            />
                            <path
                              d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                              fill="currentColor"
                            />
                            <path
                              d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Upload</span>
                        </div>
                      ) : (
                        <Link
                          to="/add_post"
                          className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-r-md hover:bg-[#FFE4CC] focus:opacity-70 ${
                            locationMenu.pathname === "/add_post"
                              ? "bg-[#FFE4CC] text-[#F95C3D]"
                              : ""
                          }`}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                              fill="currentColor"
                            />
                            <path
                              d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                              fill="currentColor"
                            />
                            <path
                              d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Upload</span>
                        </Link>
                      )}
                    </li>

                    <li className="flex items-center rounded-sm">
                      {locationMenu.pathname === "/search" ? (
                        <div
                          className="h-10 w-0.5"
                          style={{ backgroundColor: "#F95C3D" }}
                        ></div>
                      ) : (
                        ""
                      )}

                      <Link
                        to="/search"
                        className={`lg:w-52 flex items-center p-2 pl-10  space-x-3 rounded-lg hover:bg-[#FFE4CC] focus:opacity-70 ${
                          locationMenu.pathname === "/search"
                            ? "bg-[#FFE4CC] text-[#F95C3D]"
                            : ""
                        }`}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2"
                        >
                          <path
                            d="M29 29L22.2333 22.2333M25.8889 13.4444C25.8889 20.3173 20.3173 25.8889 13.4444 25.8889C6.57157 25.8889 1 20.3173 1 13.4444C1 6.57157 6.57157 1 13.4444 1C20.3173 1 25.8889 6.57157 25.8889 13.4444Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Search</span>
                      </Link>
                    </li>

                    <li className="flex items-center rounded-sm">
                      {locationMenu.pathname === "/all_chat" ? (
                        <div
                          className="h-10 w-0.5"
                          style={{ backgroundColor: "#F95C3D" }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {!userInfo ? (
                        <div
                          onClick={() => setShowSignInModalChat(true)}
                          className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-lg cursor-pointer`}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              d="M4.49993 24.284V30L6.77239 28.6203L13.9153 24.284H20.9997C22.6541 24.284 23.9996 22.9226 23.9996 21.2485V9.10634C23.9996 7.43224 22.6541 6.0708 20.9997 6.0708H2.99995C1.34548 6.0708 0 7.43224 0 9.10634V21.2485C0 22.9226 1.34548 24.284 2.99995 24.284H4.49993ZM2.99995 9.10634H20.9997V21.2485H13.0843L7.49988 24.6392V21.2485H2.99995V9.10634Z"
                              fill="currentColor"
                            />
                            <path
                              d="M27.0006 0H9.00093C7.34646 0 6.00098 1.36144 6.00098 3.03554H24.0007C25.6552 3.03554 27.0006 4.39698 27.0006 6.07108V18.2132C28.6551 18.2132 30.0006 16.8518 30.0006 15.1777V3.03554C30.0006 1.36144 28.6551 0 27.0006 0Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Chat</span>
                        </div>
                      ) : (
                        <Link
                          to="/all_chat"
                          className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-lg hover:bg-[#FFE4CC] focus:opacity-70 ${
                            locationMenu.pathname === "/all_chat"
                              ? "bg-[#FFE4CC] text-[#F95C3D]"
                              : ""
                          }`}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              d="M4.49993 24.284V30L6.77239 28.6203L13.9153 24.284H20.9997C22.6541 24.284 23.9996 22.9226 23.9996 21.2485V9.10634C23.9996 7.43224 22.6541 6.0708 20.9997 6.0708H2.99995C1.34548 6.0708 0 7.43224 0 9.10634V21.2485C0 22.9226 1.34548 24.284 2.99995 24.284H4.49993ZM2.99995 9.10634H20.9997V21.2485H13.0843L7.49988 24.6392V21.2485H2.99995V9.10634Z"
                              fill="currentColor"
                            />
                            <path
                              d="M27.0006 0H9.00093C7.34646 0 6.00098 1.36144 6.00098 3.03554H24.0007C25.6552 3.03554 27.0006 4.39698 27.0006 6.07108V18.2132C28.6551 18.2132 30.0006 16.8518 30.0006 15.1777V3.03554C30.0006 1.36144 28.6551 0 27.0006 0Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Chat</span>
                        </Link>
                      )}
                    </li>

                    <li className="flex items-center rounded-sm ">
                      {locationMenu.pathname === "/about_us" ? (
                        <div
                          className="h-10 w-0.5"
                          style={{ backgroundColor: "#F95C3D" }}
                        ></div>
                      ) : (
                        ""
                      )}
                      <a
                        href="/about_us"
                        className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-lg hover:bg-[#FFE4CC] focus:opacity-70 ${
                          locationMenu.pathname === "/about_us" ||
                          locationMenu.pathname === "/terms_of_service" ||
                          locationMenu.pathname === "/privacy_policy"
                            ? "bg-[#FFE4CC] text-[#F95C3D]"
                            : ""
                        }`}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>About Us</span>
                      </a>
                    </li>

                    <li className="flex items-center rounded-sm ">
                      {locationMenu.pathname === "/contact_us" ? (
                        <div
                          className="h-10 w-0.5"
                          style={{ backgroundColor: "#F95C3D" }}
                        ></div>
                      ) : (
                        ""
                      )}
                      <Link
                        to="/contact_us"
                        className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-lg hover:bg-[#FFE4CC] focus:opacity-70 ${
                          locationMenu.pathname === "/contact_us"
                            ? "bg-[#FFE4CC] text-[#F95C3D]"
                            : ""
                        }`}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>Contact Us</span>
                      </Link>
                    </li>

                    <li className="flex items-center rounded-sm">
                      {locationMenu.pathname === `/${dataUser.username}` ? (
                        <div
                          className="h-10 w-0.5"
                          style={{ backgroundColor: "#F95C3D" }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {!userInfo ? (
                        <Link
                          to="/signin"
                          className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-lg hover:bg-[#FFE4CC] focus:opacity-70 ${
                            locationMenu.pathname === "/signin"
                              ? "bg-[#FFE4CC] text-[#F95C3D]"
                              : ""
                          }`}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15 3C12.9011 2.99961 10.8389 3.54973 9.01908 4.59544C7.19927 5.64115 5.68556 7.14587 4.62904 8.95942C3.57251 10.773 3.01013 12.8319 2.99801 14.9307C2.9859 17.0296 3.52449 19.0949 4.56001 20.9205C5.25993 20.0108 6.15966 19.2744 7.18964 18.768C8.21962 18.2615 9.35226 17.9988 10.5 18H19.5C20.6477 17.9988 21.7804 18.2615 22.8104 18.768C23.8403 19.2744 24.7401 20.0108 25.44 20.9205C26.4755 19.0949 27.0141 17.0296 27.002 14.9307C26.9899 12.8319 26.4275 10.773 25.371 8.95942C24.3144 7.14587 22.8007 5.64115 20.9809 4.59544C19.1611 3.54973 17.0989 2.99961 15 3ZM26.9145 24.114C28.92 21.4994 30.0048 18.2951 30 15C30 6.71549 23.2845 0 15 0C6.71551 0 1.68798e-05 6.71549 1.68798e-05 15C-0.00493527 18.2952 1.07987 21.4995 3.08551 24.114L3.07801 24.141L3.61051 24.7605C5.01734 26.4052 6.76409 27.7254 8.73036 28.6299C10.6966 29.5345 12.8357 30.0019 15 30C18.041 30.0056 21.0111 29.0818 23.5125 27.3525C24.5789 26.6157 25.5458 25.7445 26.3895 24.7605L26.922 24.141L26.9145 24.114ZM15 5.99999C13.8065 5.99999 12.6619 6.4741 11.818 7.31801C10.9741 8.16193 10.5 9.30652 10.5 10.5C10.5 11.6935 10.9741 12.8381 11.818 13.682C12.6619 14.5259 13.8065 15 15 15C16.1935 15 17.3381 14.5259 18.182 13.682C19.0259 12.8381 19.5 11.6935 19.5 10.5C19.5 9.30652 19.0259 8.16193 18.182 7.31801C17.3381 6.4741 16.1935 5.99999 15 5.99999Z"
                              fill="black"
                            />
                          </svg>

                          <span>Sign In</span>
                        </Link>
                      ) : (
                        <a
                          href={`/${dataUser.username}`}
                          className={`lg:w-52 flex items-center p-2 pl-10 space-x-3 rounded-lg hover:bg-[#FFE4CC] focus:opacity-70 ${
                            locationMenu.pathname === `/${dataUser.username}` ||
                            locationMenu.pathname === "/following_follower" ||
                            locationMenu.pathname === "/update_password" ||
                            locationMenu.pathname === "/update_profile" ||
                            locationMenu.pathname === "/professional_account" ||
                            locationMenu.pathname === "/verified_user"
                              ? "bg-[#FFE4CC] text-[#F95C3D]"
                              : ""
                          }`}
                        >
                          <div onClick={handleOpenUserMenu}>
                            <SetProfilePicture />
                          </div>

                          <span>Profile</span>
                        </a>
                      )}
                    </li>
                  </>
                  {/* )} */}
                </ul>
              </div>
            </ul>
          </div>

          {/* Mobile Layout (Filter) */}
          <div className="lg:hidden">
            <div
              className={`fixed top-12 bg-white z-[1001] p-5 overflow-auto w-52 space-6 h-full transition-transform transform ${
                isClickedSearch ? "translate-x-0" : "-translate-x-56"
              }`}
            >
              <div className="font-bold mt-6 ">Filter</div>
              <ul className="pb-16 mb-12">
                <li className="font-bold flex-col mt-1">Post Type</li>
                {postTypeList.map((loc) => (
                  <li key={loc.type}>
                    <input
                      type="checkbox"
                      onChange={(e) => onChangePostType(e, loc)}
                      className="mr-3 accent-[#F95C3D]"
                    />
                    <label>
                      {" "}
                      {loc.type === 1
                        ? "Product"
                        : loc.type === 2
                        ? "Service"
                        : loc.type === 3
                        ? "Event"
                        : loc.type === 4
                        ? "Job"
                        : "Insight"}
                    </label>
                    {selectedTypes.find(
                      (selected) =>
                        selected.type === loc.type &&
                        selected.selectedSubcategories.length > 0
                    ) && (
                      <ul style={{ marginLeft: "20px" }}>
                        {loc.subcategories.map((subCategory) => {
                          let labelForSubCategory = "";

                          switch (loc.type) {
                            case 1:
                              switch (subCategory) {
                                case 1:
                                  labelForSubCategory = "Supplier";
                                  break;
                                case 2:
                                  labelForSubCategory = "Buyer";
                                  break;
                              }
                              break;
                            case 2:
                              switch (subCategory) {
                                case 1:
                                  labelForSubCategory = "Provider";
                                  break;
                                case 2:
                                  labelForSubCategory = "Seeker";
                                  break;
                              }
                              break;
                            case 3:
                              switch (subCategory) {
                                case 1:
                                  labelForSubCategory = "Employer";
                                  break;
                                case 2:
                                  labelForSubCategory = "Applicant";
                                  break;
                              }
                              break;
                            case 4:
                              switch (subCategory) {
                                case 1:
                                  labelForSubCategory = "Organizer";
                                  break;
                                case 2:
                                  labelForSubCategory = "Attendee";
                                  break;
                              }
                              break;
                            case 5:
                              labelForSubCategory = "Client";
                              break;
                          }
                          return (
                            <li key={subCategory}>
                              <input
                                type="checkbox"
                                checked={selectedTypes
                                  .find(
                                    (selected) => selected.type === loc.type
                                  )
                                  .selectedSubcategories.includes(subCategory)}
                                onChange={(e) =>
                                  handleSubcategoryChange(e, loc, subCategory)
                                }
                                className="mr-3 accent-[#F95C3D]"
                              />
                              <label>{labelForSubCategory}</label>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                ))}

                {/* Country */}
                <li className="font-bold flex-col mt-1">Location</li>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={countryList.find(
                    (option) => option.value === countryId
                  )}
                  onChange={onChangeCountryId}
                  options={countryList}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFE4CC",
                      primary: "#F95C3D",
                    },
                  })}
                  styles={customStylesSelect}
                />

                {locationList.map((loc, index) => {
                  if (!showAll && index > 2) {
                    return null;
                  }
                  return (
                    <li key={loc.value}>
                      <input
                        type="checkbox"
                        onChange={(e) => onChangeLocation(e, loc.value)}
                        className="mr-3 accent-[#F95C3D]"
                      />
                      <label>{loc.label}</label>
                    </li>
                  );
                })}

                {locationList.length > 3 && (
                  <button onClick={toggleShowAll} className="text-[#F95C3D]">
                    {showAll ? "See Less" : "See More"}
                  </button>
                )}

                <li className="font-bold flex-col mt-1">Category</li>

                {!shouldShowEvents &&
                !shouldShowProduct &&
                !shouldShowJob &&
                !shouldShowInsight &&
                !shouldShowService ? (
                  <li className="font-semibold flex-col mt-1 ml-3">
                    Select a Post Type
                  </li>
                ) : (
                  ""
                )}

                <div>
                  {shouldShowProduct ? (
                    <div>
                      <li className="font-semibold flex-col mt-1 ml-3">
                        Product
                      </li>

                      {productCategories.map((loc, index) => {
                        if (!showAllProduct && index > 2) {
                          return null;
                        }
                        return (
                          <li className="ml-3">
                            <input
                              type="checkbox"
                              onChange={(e) => onChangeCategory(e, loc)}
                              className="mr-3 accent-[#F95C3D]"
                            ></input>
                            <label>{loc.label}</label>
                          </li>
                        );
                      })}

                      {productCategories.length > 3 && (
                        <button
                          onClick={toggleShowAll2}
                          className="text-[#F95C3D] ml-3"
                        >
                          {showAllProduct ? "See Less" : "See More"}
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {shouldShowService ? (
                    <div>
                      <li className="font-semibold flex-col mt-1 ml-3">
                        Service
                      </li>
                      {serviceCategories.map((loc, index) => {
                        if (!showAllService && index > 2) {
                          return null;
                        }
                        return (
                          <li className="ml-3">
                            <input
                              type="checkbox"
                              onChange={(e) => onChangeCategory(e, loc)}
                              className="mr-3 accent-[#F95C3D]"
                            ></input>
                            <label>{loc.label}</label>
                          </li>
                        );
                      })}

                      {serviceCategories.length > 3 && (
                        <button
                          onClick={toggleShowAll3}
                          className="text-[#F95C3D] ml-3"
                        >
                          {showAllService ? "See Less" : "See More"}
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {shouldShowEvents ? (
                    <div>
                      <li className="font-semibold flex-col mt-1 ml-3">
                        Events
                      </li>
                      {eventCategories.map((loc, index) => {
                        if (!showAllEvent && index > 2) {
                          return null;
                        }
                        return (
                          <li className="ml-3">
                            <input
                              type="checkbox"
                              onChange={(e) => onChangeCategory(e, loc)}
                              className="mr-3 accent-[#F95C3D]"
                            ></input>
                            <label>{loc.label}</label>
                          </li>
                        );
                      })}

                      {eventCategories.length > 3 && (
                        <button
                          onClick={toggleShowAll4}
                          className="text-[#F95C3D] ml-3"
                        >
                          {showAllEvent ? "See Less" : "See More"}
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {shouldShowJob ? (
                    <div>
                      <li className="font-semibold flex-col mt-1 ml-3">Job</li>
                      {jobCategories.map((loc, index) => {
                        if (!showAllJob && index > 2) {
                          return null;
                        }
                        return (
                          <li className="ml-3">
                            <input
                              type="checkbox"
                              onChange={(e) => onChangeCategory(e, loc)}
                              className="mr-3 accent-[#F95C3D]"
                            ></input>
                            <label>{loc.label}</label>
                          </li>
                        );
                      })}

                      {jobCategories.length > 3 && (
                        <button
                          onClick={toggleShowAll5}
                          className="text-[#F95C3D] ml-3"
                        >
                          {showAllJob ? "See Less" : "See More"}
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {shouldShowInsight ? (
                    <div>
                      <li className="font-semibold flex-col mt-1 ml-3">
                        Insight
                      </li>
                      {insightCategories.map((loc, index) => {
                        if (!showAllInsight && index > 2) {
                          return null;
                        }
                        return (
                          <li className="ml-3">
                            <input
                              type="checkbox"
                              onChange={(e) => onChangeCategory(e, loc)}
                              className="mr-3 accent-[#F95C3D]"
                            ></input>
                            <label>{loc.label}</label>
                          </li>
                        );
                      })}

                      {insightCategories.length > 3 && (
                        <button
                          onClick={toggleShowAll6}
                          className="text-[#F95C3D] ml-3"
                        >
                          {showAllInsight ? "See Less" : "See More"}
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <li className="font-bold flex-col mt-1">Verified</li>
                {verifiedList.map((loc) => (
                  <li>
                    <input
                      type="radio"
                      name="verified"
                      onChange={(e) => onChangeVerified(e, loc)}
                      className="mr-3 accent-[#F95C3D]"
                    ></input>
                    <label>{loc}</label>
                  </li>
                ))}

                <li className="font-bold flex-col mr-3 mt-1">Date</li>
                {dateList.map((loc) => (
                  <li>
                    <input
                      type="radio"
                      name="date"
                      onChange={(e) => onChangeDate(e, loc)}
                      className="mr-3 accent-[#F95C3D]"
                    ></input>
                    <label>{loc}</label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div
            className={`container mx-auto ${
              locationMenu.pathname !== "/detail_post" ? "mt-4 md:mt-6" : ""
            } mb-16 lg:mt-16 flex-1 ${
              locationMenu.pathname !== "/" &&
              locationMenu.pathname !== "/signin" &&
              locationMenu.pathname !== "/register" &&
              locationMenu.pathname !== "/forget_password" &&
              locationMenu.pathname !== "/reset_password" &&
              locationMenu.pathname !== "/forgot_password" &&
              locationMenu.pathname !== "/update_password" &&
              locationMenu.pathname !== "/verified_user" &&
              locationMenu.pathname !== "/otp_user" &&
              locationMenu.pathname !== "/welcome" &&
              locationMenu.pathname !== "/create_profile" &&
              locationMenu.pathname !== "/add_post" &&
              locationMenu.pathname !== "/search" &&
              locationMenu.pathname !== "/about_us" &&
              locationMenu.pathname !== "/contact_us" &&
              locationMenu.pathname !== "/detail_post" &&
              locationMenu.pathname !== "/update_profile" &&
              locationMenu.pathname !== "/update_post" &&
              locationMenu.pathname !== "/privacy_policy" &&
              locationMenu.pathname !== "/following_follower" &&
              locationMenu.pathname !== "/terms_of_service"
                ? ""
                : ""
            } overflow-x-hidden ${
              locationMenu.pathname === "/all_chat" || locationMenu.pathname === "/"
                ? "overflow-y-hidden"
                : "mb-16 lg:mb-0"
            }
            
            
            lg:mb-0 max-w-none bg-[#F5F5F5]`}
          >
            <main className="h-full">
              <Routes>
                <Route
                  path="/"
                  element={
                    <HomeScreen
                      statePropSd={currentSwitch}
                      statePropSearch={searchInput}
                    />
                  }
                ></Route>
                <Route path="/contact_us" element={<ContactUsScreen />}></Route>
                <Route path="/about_us" element={<AboutUsScreen />}></Route>
                <Route
                  path="/privacy_policy"
                  element={<PrivacyPolicyScreen />}
                ></Route>
                <Route
                  path="/terms_of_service"
                  element={<TermsOfServiceScreen />}
                ></Route>
                <Route path="/:username" element={<ProfileScreen />}></Route>
                <Route
                  path="/search"
                  element={
                    <SearchScreen
                      typePost={typePost}
                      statePropSearch={searchInput}
                      statePropSearchFlag={searchFlag}
                      statePropPu={pu}
                      onChildStateChangeSearchFlag={handleSearchFlag}
                      category={category}
                      location={location}
                      verified={verified}
                      date={date}
                      switchFilter={handleSearchIconClick}
                      refreshFilter={refreshChild}
                    />
                  }
                ></Route>
                <Route path="/add_post" element={<AddPostScreen />}></Route>
                <Route
                  path="/update_post"
                  element={<UpdatePostScreen />}
                ></Route>
                <Route
                  path="/update_profile"
                  element={<UpdateProfileScreen />}
                ></Route>
                <Route
                  path="/update_password"
                  element={<UpdatePasswordScreen />}
                ></Route>
                {/* <Route path="/chat" element={<ChatScreen />}></Route> */}
                <Route path="/all_chat" element={<AllChatScreen />}></Route>
                <Route path="/detail_post" element={<DetailPost />}></Route>
                <Route
                  path="/admin_verified"
                  element={<AdminVerifiedScreen />}
                ></Route>
                <Route
                  path="/professional_account"
                  element={<UpgradeAccountScreen />}
                ></Route>
                <Route
                  path="/register_professional_account"
                  element={<RegisterProfessionalAccountScreen />}
                ></Route>
                <Route
                  path="/edit_professional_account"
                  element={<EditProfessionalAccountScreen />}
                ></Route>
                {/* <Route path="/apply_job" element={<ApplyJobScreen />}></Route> */}
                <Route
                  path="/following_follower"
                  element={<FollowersFollowingScreen />}
                ></Route>
              </Routes>
            </main>
            <MobileFooter
              userInfo={userInfo}
              showSignInModalChat={() => setShowSignInModalChat(true)}
            />
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/signin" element={<SignInScreen />}></Route>
          <Route path="/register" element={<RegisterScreen />}></Route>
          <Route path="/forget_password" element={<ForgetPassword />}></Route>
          <Route path="/reset_password" element={<ResetPassword />}></Route>
          <Route path="/forgot_password" element={<ForgotPassword />}></Route>
          <Route path="/verified_user" element={<VerifiedUserScreen />}></Route>
          <Route path="/otp_user" element={<OTPScreen />}></Route>
          <Route path="/welcome" element={<WelcomeScreen />}></Route>
          <Route
            path="/create_profile"
            element={<CreateProfileScreen />}
          ></Route>
          {/* <Route path="/apply_job" element={<ApplyJobScreen />}></Route> */}
          <Route
            path="/following_follower"
            element={<FollowersFollowingScreen />}
          ></Route>
        </Routes>
      )}

      <Modal
        open={showSignInModalChat}
        onClose={handleCloseSignInModalChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-notification-style">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10%",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            Sign in to use this feature
          </Typography>
          <div className="flex justify-center">
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleSignInButtonModalChat}
            >
              Sign in
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleCancelButtonModalChat}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
