import axios from "axios";
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { Store } from "../Store";

function OTPScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email; // Access the email from the location state

    const [otpValue, setOtpValue] = useState("");

    const { state, dispatch: ctxDispatch } = useContext(Store);

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        const otpSend = otpValue[0] + otpValue[1] + otpValue[2] + otpValue[3];
        if (otpSend.length === 4) {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/verifyotp`,
                    {
                        email: email,
                        otp: otpSend,
                    },
                    {
                        auth: {
                            username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                            password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
                        },
                    }
                );
                ctxDispatch({ type: 'USER_SIGNIN', payload: data });
                localStorage.setItem('userInfo', JSON.stringify(data));
                toast.success("E-mail Verified, Welcome to SiapHub");
                // navigate(redirect || "/");
                navigate("/create_profile");
            } catch (err) {
                toast.error(getError(err));
            }
        } else {
            alert("Please enter a 4-digit OTP");
        }
    };

    const resendOtp = async (e) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/generateOtp`,
                {
                    email: email,
                },
                {
                    auth: {
                        username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                        password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
                    },
                }
            );
            toast.success("OTP Sent to your Email")
        } catch (err) {
            toast.error(getError(err));
        }
    }

    return (
        <div>
            {/* Mobile Layout */}
            <div className="flex justify-center items-center h-screen lg:hidden bg-FFECDA">
                <div className="card">

                    <div className='text-center'>
                        <img
                            src="../../images/siaphub_logo.png"
                            alt="Logo"
                            className="w-32 h-32 mx-auto"
                        />
                        <p className="text-2xl">{email}</p>
                        <p className="text-xs">Enter the 4-digit OTP code that has been sent to your email for verification of your email and to complete registration</p>
                        <form onSubmit={handleOtpSubmit} className="flex justify-center items-center flex-col">
                            <div className="otp-input-container flex justify-center items-center mt-5">
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 border-black rounded	 w-12 h-14 mx-2 bg-FFECDA"
                                    maxLength="1"
                                    value={otpValue[0] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            event.target.value,
                                            otpValue[1],
                                            otpValue[2],
                                            otpValue[3],
                                        ]);
                                        if (event.target.value) {
                                            document.getElementById("otp-field-2").focus();
                                        }
                                    }}
                                    id="otp-field-1"
                                />
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 border-black rounded	 w-12 h-14 mx-2 bg-FFECDA"
                                    maxLength="1"
                                    value={otpValue[1] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            otpValue[0],
                                            event.target.value,
                                            otpValue[2],
                                            otpValue[3],
                                        ]);
                                        if (event.target.value) {
                                            document.getElementById("otp-field-3").focus();
                                        } else {
                                            document.getElementById("otp-field-1").focus();
                                        }
                                    }}
                                    id="otp-field-2"
                                />
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 border-black rounded	 w-12 h-14 mx-2 bg-FFECDA"
                                    maxLength="1"
                                    value={otpValue[2] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            otpValue[0],
                                            otpValue[1],
                                            event.target.value,
                                            otpValue[3],
                                        ]);
                                        if (event.target.value) {
                                            document.getElementById("otp-field-4").focus();
                                        } else {
                                            document.getElementById("otp-field-2").focus();
                                        }
                                    }}
                                    id="otp-field-3"
                                />
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 border-black rounded w-12 h-14 mx-2 bg-FFECDA"
                                    maxLength="1"
                                    value={otpValue[3] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            otpValue[0],
                                            otpValue[1],
                                            otpValue[2],
                                            event.target.value,
                                        ]);
                                        if (!event.target.value) {
                                            document.getElementById("otp-field-3").focus();
                                        }
                                    }}
                                    id="otp-field-4"
                                />
                            </div>
                            <button
                                type="submit"
                                onClick={handleOtpSubmit}
                                className="otp-submit-btn w-1/2 mt-6 bg-ff8d52 text-black px-4 py-2 rounded-md hover-bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                            >
                                Submit OTP
                            </button>
                            <p className="text-sm mt-5">Haven't got the confirmation code yet?</p> <a className="text-sm  cursor-pointer " onClick={resendOtp}>Resend</a>
                        </form>
                    </div>
                </div>
            </div>

            {/* Desktop Layout */}
            <div className="flex justify-center items-center h-screen hidden lg:flex bg-FFECDA">
                <div className="card">
                    <div className='text-center'>
                        <img
                            src="../../images/siaphub_logo.png"
                            alt="Logo"
                            className="w-32 h-32 mx-auto"
                        />
                        <p className="text-3xl">{email}</p>
                        <p className="text-sm">Enter the 4-digit OTP code that has been sent to your email for verification of your email and to complete registration</p>
                        <form onSubmit={handleOtpSubmit} className="flex justify-center items-center flex-col">
                            <div className="otp-input-container flex justify-center items-center">
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 w-12 h-12 mx-5"
                                    maxLength="1"
                                    value={otpValue[0] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            event.target.value,
                                            otpValue[1],
                                            otpValue[2],
                                            otpValue[3],
                                        ]);
                                        if (event.target.value) {
                                            document.getElementById("otp-field-2-desktop").focus();
                                        }
                                    }}
                                    id="otp-field-1-desktop"
                                />
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 w-12 h-12 mx-5"
                                    maxLength="1"
                                    value={otpValue[1] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            otpValue[0],
                                            event.target.value,
                                            otpValue[2],
                                            otpValue[3],
                                        ]);
                                        if (event.target.value) {
                                            document.getElementById("otp-field-3-desktop").focus();
                                        } else {
                                            document.getElementById("otp-field-1-desktop").focus();
                                        }
                                    }}
                                    id="otp-field-2-desktop"
                                />
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 w-12 h-12 mx-5"
                                    maxLength="1"
                                    value={otpValue[2] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            otpValue[0],
                                            otpValue[1],
                                            event.target.value,
                                            otpValue[3],
                                        ]);
                                        if (event.target.value) {
                                            document.getElementById("otp-field-4-desktop").focus();
                                        } else {
                                            document.getElementById("otp-field-2-desktop").focus();
                                        }
                                    }}
                                    id="otp-field-3-desktop"
                                />
                                <input
                                    type="text"
                                    className="text-center otp-input border-2 w-12 h-12 mx-5"
                                    maxLength="1"
                                    value={otpValue[3] || ""}
                                    onChange={(event) => {
                                        setOtpValue([
                                            otpValue[0],
                                            otpValue[1],
                                            otpValue[2],
                                            event.target.value,
                                        ]);
                                        if (!event.target.value) {
                                            document.getElementById("otp-field-3-desktop").focus();
                                        }
                                    }}
                                    id="otp-field-4-desktop"
                                />
                            </div>
                            <button
                                type="submit"
                                onClick={handleOtpSubmit}
                                className="otp-submit-btn w-1/2 mt-6 bg-ff8d52 text-black px-4 py-2 rounded-md hover-bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                            >
                                Submit OTP
                            </button>
                            <p className="text-sm mt-5">Haven't got the confirmation code yet?</p> <p className="text-sm" onClick={resendOtp}>Resend</p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OTPScreen;
