/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useContext, useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { debounce } from "debounce";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCheckedUsername, setIsCheckedUsername] = useState(false);
  const [isShowErrorUsername, setIsShowErrorUsername] = useState(false);

  const { dispatch: ctxDispatch } = useContext(Store);

  const [activeSlide, setActiveSlide] = useState(1);
  const slide = [
    {
      slides: 1,
      photo: "../../images/groupinformation.png",
      title: "Best Prices & Deals",
      detail:
        "Find your favorite Things at ease and best prices with exclusive deals only on SiapHub.",
    },
    {
      slides: 2,
      photo: "../../images/siaphub_logo_clean.png",
      title: "Best Prices & Deals",
      detail:
        "Find your favorite Things at ease and best prices with exclusive deals only on SiapHub.",
    },
  ];

  async function handleCallbackResponse(response) {
    let googleToken = response.credential;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/signingoogle`,
        {
          googleToken,
        },
        {
          auth: {
            username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
            password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
          },
        }
      );
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      navigate(redirect || "/");
    } catch (err) {
      toast.error(getError(err));
    }
  }

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
      callback: handleCallbackResponse,
    });
  }, []);

  useEffect(() => {
    window.google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    );
  }, []);

  useEffect(() => {
    window.google.accounts.id.renderButton(
      document.getElementById("signInDiv2"),
      { theme: "outline", size: "large" }
    );
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!name || !email || !password) {
      toast.error("Please input all the required field");
    } else if (password !== confirmPassword) {
      toast.error("Password do not match with confirm passsword!");
    } else {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/register`,
          {
            name: name,
            email: email,
            username: username,
            password: password,
          },
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );
        ctxDispatch({ type: "USER_SIGNIN", payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
        try {
          await axios.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/generateOtp`,
            {
              email: email,
            },
            {
              auth: {
                username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
              },
            }
          );
          navigate("/otp_user", { state: { email: email } });
        } catch (err) {
          toast.error(getError(err));
        }
      } catch (err) {
        toast.error(getError(err));
      }
    }
  };

  const handleUsernameChange = (value) => {
    setUsername(value);
    debounceCheckUsername(value);
  };

  const checkUsername = async (value) => {
    setIsChecking(true);
    let dataUsername = "";
    if (value) {
      try {
        const getResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/usernameCheck?username=${value}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );
        dataUsername = getResponse.data.data.status;
        if (dataUsername === 1) {
          setIsCheckedUsername(false);
          setIsShowErrorUsername(true);
        } else {
          setIsCheckedUsername(true);
          setIsShowErrorUsername(false);
        }
      } catch (error) {
        toast.error(getError(error));
      }
    }
    setIsChecking(false);
  };

  const debounceCheckUsername = useCallback(debounce(checkUsername, 500), []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev === slide.length ? 1 : prev + 1));
    }, 4000);

    return () => clearInterval(interval);
  }, [slide.length, activeSlide]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <Helmet>
        <title>Sign Up | SiapHub</title>
      </Helmet>
      {/* Mobile Layout */}
      <div className="h-full my-auto flex flex-col justify-center items-center bg-gray lg:hidden">
        <div className="w-full p-4 bg-[#F95C3D] text-center">
          <img
            src="../../images/siaphub_logo.png"
            alt="Logo"
            className="w-16 h-16 mx-auto"
          />
        </div>

        {slide.map((slide) => (
          <div
            key={slide.slides}
            className={`w-full p-4 bg-[#F95C3D] text-white ${
              activeSlide === slide.slides ? "" : "hidden"
            }`}
          >
            <div className="mb-4">
              <div className="w-32 h-32 mx-auto overflow-hidden">
                <img
                  src={slide.photo}
                  alt="siaphub logo"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <h1 className="text-center text-xl text-white">{slide.title}</h1>
            <p className="text-center">{slide.detail}</p>
          </div>
        ))}

        <div className="py-4 bg-[#F95C3D] w-full flex items-center justify-center">
          {slide.map((slide) => (
            <button
              key={slide}
              className={`flex-1 w-4 h-2 mt-4 mx-2 mb-0 rounded-full overflow-hidden transition-colors duration-200 ease-out ${
                activeSlide === slide.slides
                  ? "bg-orange-600 hover:bg-orange-600 hover:shadow-lg"
                  : "bg-zinc-600"
              }`}
              onClick={() => setActiveSlide(slide.slides)}
            ></button>
          ))}
        </div>

        <div className="w-full px-10 py-10">
          <form onSubmit={submitHandler}>
            <div className="mt-4">
              <input
                type="text"
                placeholder="Full Name"
                className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                style={{ fontSize: "0.8rem" }}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mt-4">
              <input
                type="email"
                placeholder="Email Address"
                className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                style={{ fontSize: "0.8rem" }}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="w-full mt-4">
              <div className="flex items-center relative">
                <input
                  type="text"
                  placeholder={username}
                  className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                  style={{ fontSize: "0.8rem" }}
                  value={username}
                  onChange={(e) => handleUsernameChange(e.target.value)}
                  required
                />
                <div className="absolute inset-y-0 end-0 flex items-center z-20 px-3">
                  {isChecking ? (
                    <span>Checking...</span>
                  ) : isCheckedUsername && username !== "" ? (
                    <CheckCircleSharpIcon style={{ fill: "green" }} />
                  ) : (
                    <CheckCircleSharpIcon style={{ fill: "grey" }} />
                  )}
                </div>
              </div>
              {isShowErrorUsername ? (
                <div
                  className="text-gray-700 focus:outline-none"
                  style={{ fontSize: "0.8rem", color: "red" }}
                >
                  *Username is already taken!
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mt-4">
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                  style={{ fontSize: "0.8rem" }}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#F95C3D]"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? (
                    <svg
                      className="shrink-0 size-3.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                  ) : (
                    <svg
                      className="shrink-0 size-3.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                      <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                      <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                      <line x1="2" x2="22" y1="2" y2="22"></line>
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div className="mt-4">
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                  style={{ fontSize: "0.8rem" }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#F95C3D]"
                  onClick={handleToggleConfirmPassword}
                >
                  {showConfirmPassword ? (
                    <svg
                      className="shrink-0 size-3.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                  ) : (
                    <svg
                      className="shrink-0 size-3.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                      <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                      <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                      <line x1="2" x2="22" y1="2" y2="22"></line>
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded-lg hover:bg-gray-600"
              >
                Sign Up
              </button>
            </div>
            <div className="mt-4 flex items-center justify-center">
              <a className="text-xs text-gray-500 text-center">
                Already have an account?{" "}
                <Link
                  to={`/signin?redirect=${redirect}`}
                  className="text-[#F95C3D]"
                >
                  {" "}
                  Login
                </Link>
              </a>
            </div>
          </form>
        </div>
      </div>

      {/* Desktop Layout */}
      <div className="min-h-screen bg-[rgb(255,228,204)] text-gray-900 flex justify-center hidden lg:flex">
        <div className="max-w-screen-xl m-auto lg:m-auto xl:m-auto bg-white shadow sm:rounded-lg flex justify-center flex-1">
          <div className="lg:w-1/2 xl:w-1/2 p-6 sm:p-12">
            <div>
              <div className="flex font-bold justify-center">
                <img
                  className="h-24 w-24 mb-3"
                  src="../../images/siaphub_logo.png"
                  alt="siaphub logo"
                />
              </div>
              <p className="text-xl text-gray-600 text-center">
                Create Your Account
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div
                id="signInDiv2"
                className="flex items-center justify-center mt-4"
              ></div>
              <div className="w-full flex-1">
                <div className="my-5 border-b text-center">
                  <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                    Or sign up with e-mail
                  </div>
                </div>

                <div className="mx-auto max-w-xs">
                  <form onSubmit={submitHandler}>
                    <div className="mt-4">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                        style={{ fontSize: "0.8rem" }}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mt-4">
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                        style={{ fontSize: "0.8rem" }}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="w-full mt-4">
                      <div className="flex items-center relative">
                        <input
                          type="text"
                          placeholder={username}
                          className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                          style={{ fontSize: "0.8rem" }}
                          value={username}
                          onChange={(e) => handleUsernameChange(e.target.value)}
                          required
                        />
                        
                        <div className="absolute inset-y-0 end-0 flex items-center z-20 px-3">
                          {isChecking ? (
                            <span>Checking...</span>
                          ) : isCheckedUsername && username !== "" ? (
                            <CheckCircleSharpIcon style={{ fill: "green" }} />
                          ) : (
                            <CheckCircleSharpIcon style={{ fill: "grey" }} />
                          )}
                        </div>
                      </div>
                      {isShowErrorUsername ? (
                        <div
                          className="text-gray-700 focus:outline-none"
                          style={{ fontSize: "0.8rem", color: "red" }}
                        >
                          *Username is already taken!
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mt-4">
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                          style={{ fontSize: "0.8rem" }}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#F95C3D]"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? (
                            <svg
                              className="shrink-0 size-3.5"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                              <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                          ) : (
                            <svg
                              className="shrink-0 size-3.5"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                              <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                              <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                              <line x1="2" x2="22" y1="2" y2="22"></line>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="relative">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          className="w-full border rounded px-3 py-2 text-gray-700 focus:outline-none"
                          style={{ fontSize: "0.8rem" }}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#F95C3D]"
                          onClick={handleToggleConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <svg
                              className="shrink-0 size-3.5"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                              <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                          ) : (
                            <svg
                              className="shrink-0 size-3.5"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                              <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                              <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                              <line x1="2" x2="22" y1="2" y2="22"></line>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mt-8">
                      <button
                        type="submit"
                        className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded-lg hover:bg-gray-600"
                      >
                        Sign Up
                      </button>
                    </div>
                    <div className="mt-4 flex items-center justify-center">
                      <a className="text-xs text-gray-500 text-center">
                        Already have an account?{" "}
                        <Link
                          to={`/signin?redirect=${redirect}`}
                          className="text-[#F95C3D]"
                        >
                          {" "}
                          Login
                        </Link>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 xl:w-1/2  flex-1 bg-[#F95C3D] text-center hidden sm:rounded-r-lg lg:flex">
            <div className="m-12 xl:m-16 w-full  bg-center">
              <div className="mt-20">
                <div className="max-w-4xl mx-auto relative">
                  {slide.map((slide) => (
                    <div
                      key={slide.slides}
                      className={`p-2 h-64 flex flex-col justify-center items-center text-white rounded-lg ${
                        activeSlide === slide.slides ? "" : "hidden"
                      }`}
                    >
                      <div className="flex font-bold justify-center">
                        <img
                          className="h-64 w-64 mb-3"
                          src={slide.photo}
                          alt="siaphub logo"
                        />
                      </div>
                      <br />
                      <div className="font-bold">
                        <h1>{slide.title}</h1>
                      </div>
                      <br />
                      <div className="capitalize">{slide.detail}</div>
                    </div>
                  ))}

                  <div className="absolute w-full flex items-center justify-center px-4 mt-24">
                    {slide.map((slide) => (
                      <button
                        key={slide}
                        className={`flex-1 w-4 h-2 mt-4 mx-2 mb-0 rounded-full overflow-hidden transition-colors duration-200 ease-out ${
                          activeSlide === slide.slides
                            ? "bg-orange-600 hover:bg-orange-600  hover:shadow-lg"
                            : "bg-zinc-600"
                        }`}
                        onClick={() => setActiveSlide(slide.slides)}
                      ></button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        open={open}
        onClose={handleClose}
        contentLabel="OTP Modal"
        className="otp-modal"
        overlayClassName="otp-modal-overlay bg-gray-400 bg-opacity-75 fixed inset-0"
      >
        <form onSubmit={handleSubmit}>
          <label
            className="modal-title text-white text-center"
            htmlFor="otp=input"
          >
            Enter OTP:
          </label>
          <br />
          <input
            type="text"
            className="text-center"
            maxLength="1"
            value={otpValue[0] || ""}
            onChange={(event) => {
              setOtpValue([
                event.target.value,
                otpValue[1],
                otpValue[2],
                otpValue[3],
              ]);
              if (event.target.value) {
                document.getElementById("otp-field-2").focus();
              }
            }}
            id="otp-field-1"
          />
          <input
            type="text"
            className="text-center"
            maxLength="1"
            value={otpValue[1] || ""}
            onChange={(event) => {
              setOtpValue([
                otpValue[0],
                event.target.value,
                otpValue[2],
                otpValue[3],
              ]);
              if (event.target.value) {
                document.getElementById("otp-field-3").focus();
              } else {
                document.getElementById("otp-field-1").focus();
              }
            }}
            id="otp-field-2"
          />
          <input
            type="text"
            className="text-center"
            maxLength="1"
            value={otpValue[2] || ""}
            onChange={(event) => {
              setOtpValue([
                otpValue[0],
                otpValue[1],
                event.target.value,
                otpValue[3],
              ]);
              if (event.target.value) {
                document.getElementById("otp-field-4").focus();
              } else {
                document.getElementById("otp-field-2").focus();
              }
            }}
            id="otp-field-3"
          />
          <input
            type="text"
            className="text-center"
            maxLength="1"
            value={otpValue[3] || ""}
            onChange={(event) => {
              setOtpValue([
                otpValue[0],
                otpValue[1],
                otpValue[2],
                event.target.value,
              ]);
              if (!event.target.value) {
                document.getElementById("otp-field-3").focus();
              }
            }}
            id="otp-field-4"
          />
          <br />
          <button
            type="submit"
            className="otp-submit-btn mt-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Submit OTP
          </button>
        </form>
      </Modal> */}
    </div>
  );
};

export default RegisterScreen;
