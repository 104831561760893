import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import EmailIcon from "@mui/icons-material/Email";

const ContactUsScreen = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_61pjt0p", "template_yj4tb7s", form.current, {
        publicKey: "J6wQ92bOzxvxnETHH",
      })
      .then(
        () => {
          toast.success("Success!");
          setTimeout(() => {
            window.location.reload();
          }, 8000);
        },
        (error) => {
          toast.error(error.text);
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | SiapHub</title>
      </Helmet>
      <div className="mx-auto max-w-7xl px-4 py-10 sm:px-4 lg:px-5 lg:py-10">
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <p className="text-base font-semibold tracking-wide">
              Contact
            </p>
            <h2 className="font-heading mb-4 font-bold tracking-tight text-3xl sm:text-5xl">
              Get in Touch
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-xl flex items-center justify-center">
              <span>in</span>
              <div className="flex items-center ml-2">
                <img
                  className="h-10 w-10 md:h-11 md:w-11"
                  alt="siaphub logo"
                  src="../../images/siaphub_logo_clean.png"
                />
                <div className="text-gray-500 text-base md:text-lg font-bold ml-2">
                  SiapHub
                </div>
              </div>
            </p>
          </div>
        </div>

        <div className="flex items-stretch justify-center">
          <div className="grid md:grid-cols-2">
            <div className="h-full pr-6 md:mt-5 ml-5">
              <p className="mt-3 mb-12 text-lg">
                Please fill out the form below to submit any questions,
                suggestions or other feedback.
              </p>
              <ul className="mb-6 md:mb-0">
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-[#F95C3D] text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6">
                      Our Address
                    </h3>
                    <p>
                      Greenlake City Boulevard,
                      <br /> Rukan GreatWall Blok A No. 38 <br />
                      Cipondoh Kota Tangerang,
                    </p>
                    <p>Banten 15146, Indonesia</p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-[#F95C3D] text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                      <path d="M15 7a2 2 0 0 1 2 2"></path>
                      <path d="M15 3a6 6 0 0 1 6 6"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6">
                      Contact
                    </h3>
                    {/* <p>Mobile: </p> */}
                    <p className="flex">
                      {/* Email:{" "} */}
                      <a
                        href="mailto:siaphubofficial@gmail.com"
                        className="mr-2"
                      >
                        <EmailIcon />
                      </a>
                      <a href="mailto:siaphubofficial@gmail.com">
                        siaphubofficial@gmail.com
                      </a>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-[#F95C3D] text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6">
                      Social Media
                    </h3>
                    <div className="flex items-center">
                      <a href="https://www.instagram.com/siaphub/" className="mr-2">
                        <img
                          src="../images/social_logo/ig.png"
                          className="w-7 h-7"
                          alt="instagram"
                        />
                      </a>
                      <a href="https://www.instagram.com/siaphub/">@siaphub</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
              <h2 className="mb-4 text-2xl font-bold">Still need help?</h2>
              <form id="contactForm" ref={form} onSubmit={sendEmail}>
                <div className="mb-6">
                  <div className="mx-0 mb-1 sm:mb-4">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label
                        htmlFor="name"
                        className="pb-1 text-xs tracking-wider"
                      ></label>
                      <input
                        type="text"
                        id="name"
                        autoComplete="given-name"
                        placeholder="Your name"
                        className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                        name="name"
                      />
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label
                        htmlFor="email"
                        className="pb-1 text-xs tracking-wider"
                      ></label>
                      <input
                        type="email"
                        id="email"
                        autoComplete="email"
                        placeholder="Your email address"
                        className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                        name="email"
                      />
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label
                        htmlFor="subject"
                        className="pb-1 text-xs tracking-wider"
                      ></label>
                      <input
                        type="text"
                        id="subject"
                        autoComplete="subject"
                        placeholder="Your subject"
                        className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                        name="subject"
                      />
                    </div>
                  </div>
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="message"
                      className="pb-1 text-xs tracking-wider"
                    ></label>
                    <textarea
                      id="message"
                      name="message"
                      cols="30"
                      rows="5"
                      placeholder="Write your message..."
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none"
                    ></textarea>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="w-full bg-[#F95C3D] text-white py-2 px-4 rounded-lg sm:mb-0 hover:bg-orange-600"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsScreen;
