import { createContext, useReducer } from "react";

export const Store = createContext();

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
    shouldFetchNotifications: false,
};

function reducer(state, action) {

  switch (action.type) {
    case "USER_SIGNIN": {
      return { ...state, userInfo: action.payload };
    }
    case "USER_SIGNOUT": {
      return { ...state, userInfo: null };
    }
    case "TRIGGER_FETCH_NOTIFICATIONS":
      return { ...state, shouldFetchNotifications: true };
    case "RESET_FETCH_NOTIFICATIONS":
      return { ...state, shouldFetchNotifications: false };
    default:
      return state;
  }
}


export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
